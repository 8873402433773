import React, {useEffect, useState} from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from '@fullcalendar/react';
import googleCalendarPlugin from '@fullcalendar/google-calendar';


export default function Calender(props){

    const {event} = props



    const apiKey = process.env.REACT_APP_GOOGLE_CALENDER
    const apiId = process.env.REACT_APP_GOOGLE_ID




    return(
        <>
            <div style={{width:"95%"}}>
                <FullCalendar
                    locale="kr"
                    plugins={[googleCalendarPlugin,dayGridPlugin,timeGridPlugin,interactionPlugin]}
                    initialView={'dayGridMonth'}
                    googleCalendarApiKey={apiKey}
                    headerToolbar={
                        {
                            start: 'today',
                            center: 'title',
                            end: 'prevYear,prev,next,nextYear'
                        }
                    }
                    height={"100vh"}
                    events={event}
                    eventOrder={'index'}
                />
            </div>

        </>
    )
}