import Common from "../Crud/common";
import React from "react";
import {DataGrid} from "@mui/x-data-grid";
import Create from "../Crud/admin/create";
import DepartmentName from "../Crud/admin/update/departmentName";
import DepartmentUpdate from "../Crud/admin/update/department"
import IndividualUpdate from "../Crud/admin/update/individual";
import SelectDelete from "../Crud/admin/delete/select";
import DepartmentDelete from "../Crud/admin/delete/department";
import ExcelUpload from "../Crud/admin/Excel/ExcelUpload";
import ExcelDownload from "../Crud/admin/Excel/ExcelDownload";


export default function Admin(props){

    const {isFindData,isCheck,setIsFindData,setFindData,search,setSearch,companyData,isAdmin,findData,
        adminColumns,onCompanyRowsSelectionHandler,companyDBData,selectedData} = props

    //신규부서 생성, 부서명 변경, 부서별 수정, 부서 삭제, 선택 수정, 선택 삭제, 대량업로드,다운로드
    return(
        <>
            <div style={{paddingBottom: 20}}>

                <Common isFindData={isFindData} isCheck={isCheck} setIsFindData={setIsFindData}
                       setData={setFindData} search={search} setSearch={setSearch}
                       company={companyData.company} isAdmin={isAdmin}/><br/>
                <div style={{alignItems:'center',display:'flex',flexDirection:'column',margin:5,paddingBottom:1}}>
                    <div>
                        <Create />
                        <DepartmentName />
                        <DepartmentUpdate />
                        <DepartmentDelete />
                    </div>
                    <div style={{marginTop:20}}>
                        <IndividualUpdate selectedData={selectedData}/>
                        <SelectDelete selectedData={selectedData}/>
                        <ExcelUpload />
                        <ExcelDownload selectedData={selectedData}/>
                    </div>

                </div>


            </div>

            <div style={{ height: 500, width: '90%' }}>

                    <>
                        <DataGrid
                            rows={isFindData === true ? findData : companyDBData}
                            columns={adminColumns}
                            pageSize={20}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            onRowSelectionModelChange={(ids) => onCompanyRowsSelectionHandler(ids)}
                        />
                    </>

            </div>
        </>
    )
}