import Store from "../../../../../../context/store";
import React, {useEffect, useState} from "react";
import moment from "moment-timezone";
import {
    Box, Button,
    Divider,
    FormControl, FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import styled from "styled-components";
import {register} from "../../../../../../api";
import Modal from "../index";

const FormHelperTextsRED = styled(FormHelperText)`
  width: 100%;
  padding-left: 30%;
  font-weight: 700 !important;
  color: #d32f2f !important;
`;


export default function Create(){

    const companyData = Store().getCompanyData()
    const companyList = [companyData.company]

    const [departmentList,setDepartmentList] =useState([])
    const [positionList,setPositionList] = useState([])

    const [department,setDepartment]=useState("")
    const [position,setPosition] = useState("")
    const [name, setName] = useState("");
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");

    const [NameMessage, setNameMessage] = useState("")
    const [EmailMessage, setEmailMessage] = useState("")
    const [PwMessage, setPwMessage] = useState("")
    const [PwConfirmMessage, setPwConfirmMessage] =useState("")
    const [PhoneMessage, setPhoneMessage] = useState("")

    const [isName, setIsName] = useState(false)
    const [isEmail, setIsEmail] = useState(false)
    const [isPw, setIsPw] = useState(false)
    const [isPwConfirm, setIsPwConfirm] = useState(false)
    const [isPhone,setIsPhone] = useState(false)
    const [isAuthNum, setIsAuthNum]= useState(false)
    const [isSendAuth, setIsSendAuth]= useState(false)
    const [isDepartment,setIsDepartment] = useState(false)
    const [isPosition,setIsPosition] = useState(false)
    const [isBirth,setIsBirth] = useState(false)
    const [complete, setComplete] = useState(false)

    const now = new Date();
    const open = moment().tz('Asia/Seoul')
    const formYear = open.format(`YYYY`)

    const today = open.format('YYYY-MM-DD').split('-').join('')


    const [form, setForm] = useState({
        year: formYear,
        month: "01",
        day: "01",
    });
    let birth = `${form.year}-${form.month}-${form.day}`


    let years = [];
    for (let y = now.getFullYear(); y >= 1900; y -= 1) {
        years.push(y);
    }

    let month = [];
    for (let m = 1; m <= 12; m += 1) {
        if (m < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 월에 0을 붙혀준다
            month.push("0" + m.toString());
        } else {
            month.push(m.toString());
        }
    }
    let days = [];
    let date = new Date(form.year, form.month, 0).getDate();
    for (let d = 1; d <= date; d += 1) {
        if (d < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 일에 0을 붙혀준다
            days.push("0" + d.toString());
        } else {
            days.push(d.toString());
        }
    }


    const onChangeDepartment = (e) =>{
        const current = e.target.value
        setDepartment(current)

        let filter =[]
        companyData.organizations.map(e=>{
            if(current === e.department){
                e.position.map(item=>{
                    filter.push(item.name)
                })

            }
        })
        setPositionList(filter)
        if(current.length >= 1){
            setIsDepartment(true)
        }else{
            setIsDepartment(false)
        }
    }

    const onChangePosition = (e) =>{
        const current = e.target.value
        setPosition(current)
        if(current.length >= 1){
            setIsPosition(true)
        }else{
            setIsPosition(false)
        }
    }


    const onChangeName = (e) => {
        const currentName = e.currentTarget.value;
        setName(currentName)

        if(Number(today) <= Number(birth.split('-').join(''))){
            alert('생년월일이 현재 날짜와 동일하거나 높을 수 없습니다. 생년월일을 다시 한번 확인해 주세요.')
            setIsBirth(false)
            setName('')
        }else{
            setIsBirth(true)
        }

        const nameRegExp = /^[가-힣a-zA-Z]+$/;

        if (!nameRegExp.test(currentName) || name.length<1) {
            setNameMessage('이름을 두 글자 이상 입력하세요!');
            setIsName(false);
        } else {
            setNameMessage('');
            setIsName(true);
        }

    }

    const onChangeEmail = (e) => {
        const currentEmail = e.currentTarget.value;
        setUserId(currentEmail)
        if(Number(today) <= Number(birth.split('-').join(''))){
            alert('생년월일이 현재 날짜와 동일하거나 높을 수 없습니다. 생년월일을 다시 한번 확인해 주세요.')
            setIsBirth(false)
            setUserId('')
        }else{
            setIsBirth(true)
        }

        const emailRegExp =
            /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;

        if (!emailRegExp.test(currentEmail)) {
            setEmailMessage("이메일의 형식이 올바르지 않습니다!");
            setIsEmail(false);
        } else {
            setEmailMessage("");
            setIsEmail(true);
        }
    };

    const onChangePassword = (e) => {
        const currentPw = e.currentTarget.value;
        setPassword(currentPw);

        if(Number(today) <= Number(birth.split('-').join(''))){
            alert('생년월일이 현재 날짜와 동일하거나 높을 수 없습니다. 생년월일을 다시 한번 확인해 주세요.')
            setIsBirth(false)
            setPassword('')
        }else{
            setIsBirth(true)
        }
        const passwordRegExp =
            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
        if (!passwordRegExp.test(currentPw)) {
            setPwMessage(
                "숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"
            );
            setIsPw(false);
        } else {
            setPwMessage("");
            setIsPw(true);
        }
    };

    const onChangePhone = (getNumber) => {
        const currentPhone = getNumber;
        setPhone(currentPhone);

        if(Number(today) <= Number(birth.split('-').join(''))){
            alert('생년월일이 현재 날짜와 동일하거나 높을 수 없습니다. 생년월일을 다시 한번 확인해 주세요.')
            setIsBirth(false)
            setPhone('')
        }else{
            setIsBirth(true)
        }
        const phoneRegExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

        if (!phoneRegExp.test(currentPhone)) {
            setPhoneMessage("핸드폰 번호를 입력해 주세요!");
            setIsPhone(false);
        } else {
            setPhoneMessage("");
            setIsPhone(true);
        }
    };
    const addHyphen = (e) => {
        const currentNumber = e.currentTarget.value;
        setPhone(currentNumber);
        if (currentNumber.length === 3 || currentNumber.length === 8) {
            setPhone(currentNumber + "-");
            onChangePhone(currentNumber + "-");
        } else {
            onChangePhone(currentNumber);
        }
    };


    const [registerData,setRegisterData] = useState({})

    const onSubmitHandler = (e)=>{
        e.preventDefault()
        let data = {
            company:companyData.company,
            department:department,
            position:position,
            name:name,
            birth:`${form.year}-${form.month}-${form.day}`,
            userId:userId,
            password:password,
            phone:phone,
            workTime:null,
            joinDate:null
        }
        setRegisterData(data)
        openModal()
    }


    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }


    useEffect(()=>{
        let listFilter =[]
        companyData.organizations.map(e=>{
            listFilter.push(e.department)
        })
        setDepartmentList(listFilter)
    },[])

    return(
        <>
            <div style={{height: 620, width: '100%',}}>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        height: '520px',
                        width: '530px',
                        borderRadius: '1rem',
                    }}
                >
                    <br/>
                    <FormControl sx={{width:'70%', margin:1}}>
                        <InputLabel>부서</InputLabel>
                        <Select
                            value={department}
                            disabled={isSendAuth === true}
                            autoFocus
                            name="department"
                            onChange={onChangeDepartment}
                            input={<OutlinedInput label="name" />}
                        >
                            {departmentList.map((item) => (
                                <MenuItem
                                    key={item}
                                    value={item}
                                >
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{width:'70%', margin:1}}>
                        <InputLabel>직급</InputLabel>
                        <Select
                            disabled={isDepartment === false || isSendAuth === true}
                            value={position}
                            name="position"
                            onChange={onChangePosition}
                            input={<OutlinedInput label="name" />}
                        >
                            {positionList.map((item) => (
                                <MenuItem
                                    key={item}
                                    value={item}
                                >
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div style={{margin:5,paddingLeft:30,paddingBottom:10,width:'70%'}}>
                        <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:3}} variant="standard">
                            <InputLabel id="demo-simple-select-label">생년</InputLabel>
                            <Select
                                value={form.year}
                                onChange={(e)=>
                                    setForm({ ...form, year:e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                disabled={isSendAuth === true || isDepartment === false || isPosition === false}
                                sx={{height: '31px', width: '90%'}}
                            >
                                <MenuItem value="">
                                    <em>생년</em>
                                </MenuItem>
                                {years.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:3}} variant="standard">
                            <InputLabel id="demo-simple-select-label">월</InputLabel>
                            <Select
                                value={form.month}
                                onChange={(e)=>
                                    setForm({ ...form, month:e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                disabled={isSendAuth === true || isDepartment === false || isPosition === false}
                                sx={{height: '31px', width: '90%'}}
                            >
                                <MenuItem value="">
                                    <em>월</em>
                                </MenuItem>
                                {month.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{marginTop: 0.1, minWidth: 100}} variant="standard">
                            <InputLabel id="demo-simple-select-label">일</InputLabel>
                            <Select
                                value={form.day}
                                onChange={(e)=>{
                                    setForm({ ...form, day:e.target.value})
                                }}
                                labelId="demo-simple-select-label"
                                autoWidth
                                disabled={isSendAuth === true || isDepartment === false || isPosition === false}
                                sx={{height: '31px', width: '90%'}}
                            >
                                <MenuItem value="">
                                    <em>일</em>
                                </MenuItem>
                                {days.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>





                    <TextField
                        onChange={onChangeName}
                        disabled={isSendAuth === true}
                        required
                        value={name}
                        sx={{width:'70%',margin:1}}
                        id="name"
                        name="name"
                        label="이름(성함)"
                        error={NameMessage === '이름을 두 글자 이상 입력하세요!'}
                    />

                    <FormHelperTextsRED>{NameMessage}</FormHelperTextsRED>


                    <TextField
                        onChange={onChangeEmail}
                        required
                        value={userId}
                        disabled={isSendAuth === true || isName === false}
                        sx={{width:'70%',margin:1}}
                        id="userId"
                        name="userId"
                        label="아이디(E-Mail)"
                        error={EmailMessage==='이메일의 형식이 올바르지 않습니다!'}
                    />

                    <FormHelperTextsRED>{EmailMessage}</FormHelperTextsRED>

                    <TextField
                        onChange={onChangePassword}
                        required
                        sx={{width:'70%', margin:1}}
                        type="password"
                        disabled={isSendAuth === true || isEmail === false}
                        value={password}
                        name="password"
                        label="비밀번호 (숫자+영문자+특수문자 8자리 이상)"
                        error={PwMessage === '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!'}
                    />

                    <FormHelperTextsRED>{PwMessage}</FormHelperTextsRED>

                    <TextField
                        onChange={addHyphen}
                        required
                        value={phone}
                        disabled={isSendAuth === true || isPw === false}
                        sx={{width:'70%',margin:1}}
                        id="phone"
                        name="phone"
                        label="전화번호( - 자동 기입)"
                        error={PhoneMessage === '핸드폰 번호를 입력해 주세요!'}
                    />

                    <FormHelperTextsRED>{PhoneMessage}</FormHelperTextsRED>

                    <Button
                        onClick={onSubmitHandler}
                        type="submit"
                        variant="contained"
                        sx={{ mt: 2, mb: 1 ,
                            width:"70%",
                            justifyContent: 'center',
                            alignItems: 'center' }}
                        size="large"
                        disabled={ isPhone === false}
                    >
                        다음 (Next)
                    </Button>

                    {modalOpen && <>
                        <Modal open={modalOpen} close={closeModal} data={registerData} header="신규 직원 상세"  />
                    </>}

                </Box>
            </div>
        </>
    )
}