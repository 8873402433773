import React, {useEffect, useState} from "react";
import Logo from "../../../picture/Logo.png";
import {Button, FormHelperText, TextField, Typography} from "@mui/material";
import styled from "styled-components";
import {authNumCheck, findService, SMSService} from "../../../api";
import Store from "../../../context/store";

const FormHelperTextsRED = styled(FormHelperText)`
  width: 100%;
  padding-left: 30%;
  font-weight: 700 !important;
  color: #d32f2f !important;
`;
const FormHelperTextsBlue = styled(FormHelperText)`
  width: 100%;
  padding-left: 30%;
  font-weight: 700 !important;
  color: #008DDC !important;
`;

export default function ChangePassword(){

    const [userId, setUserId] = useState("");
    const [isEmail, setIsEmail] = useState(false)
    const [phone, setPhone] = useState("");
    const [authNum, setAuthNum] = useState('')
    const [password, setPassword] = useState("");
    const [PhoneMessage, setPhoneMessage] = useState("")
    const [PwConfirm, setPwConfirm] = useState("");
    const [PwMessage, setPwMessage] = useState("")
    const [PwConfirmMessage, setPwConfirmMessage] =useState("")
    const [isPw, setIsPw] = useState(false)
    const [isPwConfirm, setIsPwConfirm] = useState(false)

    const [isPhone,setIsPhone] = useState(false)
    const [isAuthNum, setIsAuthNum]= useState(false)
    const [isSendAuth, setIsSendAuth]= useState(false)
    const [complete, setComplete] = useState(false)

    const [authTime,setAuthTime] = useState(180)
    const [timerStart,setTimerStart] = useState(false)
    const [timeLimit,setTimeLimit] = useState('')

    const onChangeEmail = (e) => {
        const currentEmail = e.currentTarget.value;
        setUserId(currentEmail)
        setIsEmail(true);

    };
    const onChangePhone = (getNumber) => {
        const currentPhone = getNumber;
        setPhone(currentPhone);

        const phoneRegExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

        if (!phoneRegExp.test(currentPhone)) {
            setPhoneMessage("핸드폰 번호를 입력해 주세요!");
            setIsPhone(false);
        } else {
            setPhoneMessage("");
            setIsPhone(true);
        }
    };
    const addHyphen = (e) => {
        const currentNumber = e.currentTarget.value;
        setPhone(currentNumber);
        if (currentNumber.length === 3 || currentNumber.length === 8) {
            setPhone(currentNumber + "-");
            onChangePhone(currentNumber + "-");
        } else {
            onChangePhone(currentNumber);
        }
    };

    const sendAuthSMS =(e)=>{
        e.preventDefault()

        let data = {
            phone:phone,
            phoneSubject:'비밀번호 변경'
        }

        setIsSendAuth(true)

        SMSService(data)
            .then((res)=>{
                alert(res.data)
                setIsAuthNum(true)
                setTimerStart(true)
                setAuthTime(180)
            })
            .catch(function (err){
                alert(err.response.data)
                setIsAuthNum(false)
                setTimerStart(false)
            })



    }
    const onChangePassword = (e) => {
        const currentPw = e.currentTarget.value;
        setPassword(currentPw);

        const passwordRegExp =
            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
        if (!passwordRegExp.test(currentPw)) {
            setPwMessage(
                "숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"
            );
            setIsPw(false);
        } else {
            setPwMessage("");
            setIsPw(true);
        }
    };
    const onChangePasswordConfirm = (e) => {
        const currentPasswordConfirm = e.currentTarget.value;
        setPwConfirm(currentPasswordConfirm);

        if (password !== currentPasswordConfirm) {
            setPwConfirmMessage("비밀번호가 일치하지 않습니다.");
            setIsPwConfirm(false);
        } else {
            setPwConfirmMessage("");
            setIsPwConfirm(true);
        }
    };

    const onChangeAuthNum = (e) =>{
        setAuthNum(e.target.value)
        setIsAuthNum(true)
    }
    const onClickAuthNumCheck = (e)=>{
        let data ={
            authNum:authNum
        }
        authNumCheck(data)
            .then(res=>{
                alert(res.data)
                setComplete(true)
                setTimeLimit('인증완료')
            })
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
                setComplete(false)
            })

    }

    const onSubmitPwHandler = (e)=>{
        let sendData = {
            userId:userId,
            phone:phone,
            pw:password
        }
        let params = {
            select:'changePassword'
        }

        findService(sendData,params)
            .then(res=>{
                alert(`[${res.data.company}] ${res.data.name} 님의 비밀번호가 변경되었습니다. 자동으로 로그인 됩니다.`)
                Store().setFindData(res.data)
                window.location.replace('/')
            })
            .catch(err=>{
                console.log(err)
            })

    }

    useEffect(()=>{
        const id = setInterval(() => {
            // 타이머 숫자가 하나씩 줄어들도록
            setAuthTime((count) => count - 1);
            const Min = Math.floor(authTime / 60);
            let Sec = Math.floor(authTime % 60);
            if(Sec < 10){
                Sec= '0'+Sec
            }
            if(Min < 1 && Sec < 1 ){
                Sec = '00'
            }
            setTimeLimit(`인증 남은시간 : ${Min}:${Sec}`)

        }, 1000);

        if(isSendAuth === false){
            setAuthTime(180)
        }

        if(complete === true){
            clearInterval(id)
            setTimerStart(false)
            setTimeLimit('인증완료')
        }


        // 0이 되면 카운트가 멈춤
        if(authTime === 0) {
            clearInterval(id);
            setTimerStart(false)
            setTimeLimit('인증시간 초과')
        }
        if(authTime < 1){
            clearInterval(id);
            setTimerStart(false)
            setTimeLimit('인증시간 초과')
        }

        return () => clearInterval(id);
        // 카운트 변수가 바뀔때마다 useEffecct 실행


    },[authTime])

    return(
        <>
            <div style={{display: "flex", alignItems: 'center', flexDirection: 'column'}}>
                <img alt="No Images" src={Logo} style={{width: 220}}/><br/>
                <Typography component="h2" variant="h6" sx={{paddingBottom: 2}}>
                    비밀번호 변경
                </Typography>
                <TextField
                    onChange={onChangeEmail}
                    autoFocus
                    disabled={isSendAuth === true}
                    required
                    value={userId}
                    sx={{width: '70%', margin: 1}}
                    id="name"
                    name="name"
                    label="ID"
                />
                <div style={{width: '73%'}}>
                    <TextField
                        required
                        value={phone}
                        disabled={isSendAuth === true}
                        sx={{width: "64%", margin: 1}}
                        type="text"
                        onChange={addHyphen}
                        name="phone"
                        label="담당자 전화번호"
                        error={PhoneMessage === '핸드폰 번호를 입력해 주세요!'}
                    />


                    {isAuthNum || timeLimit === '인증시간 초과' ? (
                        <>
                            <Button
                                onClick={sendAuthSMS}
                                variant="contained"
                                type="submit"
                                size="medium"
                                sx={{
                                    marginTop: 1,
                                    width: "30%",
                                    height: '56px',
                                    border: 3,
                                    "&.MuiButton-root:hover": {
                                        color: '#008DDC',
                                        backgroundColor: '#c7ebff',
                                        borderColor: '#008DDC'
                                    }
                                }}
                                disabled={isEmail === false || isPhone === false || complete === true}
                            >

                                <Typography component="h3" variant="h7">
                                    {timeLimit === '인증시간 초과' ? (
                                        <>
                                            재인증
                                        </>
                                    ) : (
                                        <>
                                            재전송
                                        </>
                                    )}
                                </Typography>
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={sendAuthSMS}
                                variant="contained"
                                type="submit"
                                size="medium"
                                sx={{
                                    marginTop: 1,
                                    width: "30%",
                                    height: '56px',
                                    border: 3,
                                    "&.MuiButton-root:hover": {
                                        color: '#008DDC',
                                        backgroundColor: '#c7ebff',
                                        borderColor: '#008DDC'
                                    }
                                }}
                                disabled={isEmail === false || isPhone === false}
                            >
                                <Typography component="h3" variant="h7">
                                    인증받기
                                </Typography>
                            </Button>
                        </>

                    )}
                </div>
                <FormHelperTextsRED>{PhoneMessage}</FormHelperTextsRED>

                <div style={{width: '73%'}}>
                    <TextField
                        disabled={isSendAuth === false || complete === true}
                        value={authNum}
                        required
                        sx={{width: "64%", margin: 1}}
                        id="sign"
                        name="sign"
                        label="인증번호"
                        onChange={onChangeAuthNum}
                    />
                    <Button
                        onClick={onClickAuthNumCheck}
                        variant="contained"
                        type="submit"
                        size="medium"
                        sx={{
                            marginTop: 1,
                            width: "30%",
                            height: '56px',
                            border: 3,
                            "&.MuiButton-root:hover": {
                                color: '#008DDC',
                                backgroundColor: '#c7ebff',
                                borderColor: '#008DDC'
                            }
                        }}
                        disabled={isEmail === false || isPhone === false ||
                            timeLimit === '인증시간 초과' || complete === true || timerStart === false}
                    >
                        <Typography component="h3" variant="h7">
                            인증확인
                        </Typography>
                    </Button>
                </div>
                {isSendAuth ? (
                    <>
                        {complete ? (
                            <>
                                <FormHelperTextsBlue>{timeLimit}</FormHelperTextsBlue>
                            </>
                        ) : (
                            <>
                                <FormHelperTextsRED>{timeLimit}</FormHelperTextsRED>
                            </>
                        )}
                    </>
                ) : (
                    <>
                    </>
                )}

                <TextField
                    disabled={complete === false}
                    onChange={onChangePassword}
                    required
                    sx={{width:'70%', margin:1}}
                    type="password"
                    value={password}
                    name="password"
                    label="변경하실 비밀번호 (숫자+영문자+특수문자 8자리 이상)"
                    error={PwMessage === '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!'}
                />

                <FormHelperTextsRED>{PwMessage}</FormHelperTextsRED>

                <TextField
                    required
                    sx={{width:'70%', margin:1}}
                    value={PwConfirm}
                    disabled={isPw === false}
                    onChange={onChangePasswordConfirm}
                    type="password"
                    name="PwConfirm"
                    label="비밀번호 재입력"
                    error={PwConfirmMessage === '비밀번호가 일치하지 않습니다.'}
                />

                <FormHelperTextsRED>{PwConfirmMessage}</FormHelperTextsRED>

                <Button
                    onClick={onSubmitPwHandler}
                    type="submit"
                    variant="contained"
                    sx={{
                        mt: 2, mb: 1,
                        width: "70%",
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    size="large"
                    disabled={isPwConfirm === false}
                >
                    비밀번호 변경하기
                </Button>
            </div>
        </>
    )
}