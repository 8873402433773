import Create from "./create";
import Update from "./update";
import React from "react";
import DetailCreate from "./detailCreate";
import '../../../../../styles/modalStyle.css'


export default function Modal(props){

    const {open, close, header,sizeType,data } = props;

    return(
        <>
            <div className={open ? 'openModal modal' : 'modal'}>
                {open ? (
                    <>
                        {sizeType === 'mobile' ? (
                            <>
                                {header === '단일 정보수정' ? (
                                    <>
                                        <adminUpdateSection style={{marginTop: 50}}>
                                            <header>
                                                {header}
                                                <button className="close" onClick={close}>
                                                    &times;
                                                </button>
                                            </header>
                                            <main style={{alignItems: "center"}}>
                                                <Update style={{alignItems: "center"}} data={data}
                                                        sizeType="mobile"/>
                                            </main>

                                            <footer>
                                                <button className="close" onClick={close}>
                                                    닫기
                                                </button>
                                            </footer>
                                        </adminUpdateSection>
                                    </>
                                ) : (
                                    <>
                                        <sectionRegisterMobile style={{marginTop: 50}}>
                                            <header>
                                                {header}
                                                <button className="close" onClick={close}>
                                                    &times;
                                                </button>
                                            </header>
                                            <main style={{alignItems: "center"}}>
                                                {header === '신규 생성' ? (
                                                    <>
                                                        <Create
                                                            style={{alignItems: "center"}} sizeType="mobile"/>
                                                    </>
                                                ) : (
                                                    <>
                                                        <DetailCreate style={{alignItems: "center"}} data={data}
                                                                      sizeType="mobile"/>

                                                    </>
                                                )}
                                            </main>

                                            <footer>
                                                <button className="close" onClick={close}>
                                                    닫기
                                                </button>
                                            </footer>
                                        </sectionRegisterMobile>
                                    </>
                                )}


                            </>
                        ) : (
                            <>
                                {header === '단일 정보수정' ? (
                                    <>
                                        <adminUpdateSection style={{marginTop: 50}}>
                                            <header>
                                                {header}
                                                <button className="close" onClick={close}>
                                                    &times;
                                                </button>
                                            </header>
                                            <main style={{alignItems: "center"}}>
                                                <Update style={{alignItems: "center"}} data={data}/>
                                            </main>
                                            <footer>
                                                <button className="close" onClick={close}>
                                                    닫기
                                                </button>
                                            </footer>
                                        </adminUpdateSection>
                                    </>
                                ) : (
                                    <>
                                        <section style={{marginTop: 50}}>
                                            <header>
                                                {header}
                                                <button className="close" onClick={close}>
                                                    &times;
                                                </button>
                                            </header>
                                            <main style={{alignItems: "center"}}>
                                                {header === '신규 생성' ? (
                                                    <>
                                                        <Create
                                                            style={{alignItems: "center"}}/>
                                                    </>
                                                ) : (
                                                    <>
                                                        <DetailCreate style={{alignItems: "center"}}
                                                                      data={data}/>
                                                    </>
                                                )}

                                            </main>
                                            <footer>
                                                <button className="close" onClick={close}>
                                                    닫기
                                                </button>
                                            </footer>
                                        </section>
                                    </>
                                )}

                            </>
                        )}

                    </>
                ) : null}
            </div>
        </>
    )
}