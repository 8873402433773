import React, {useEffect, useState} from "react";
import Store from "../../../../../../../context/store";
import {Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import {adminService} from "../../../../../../../api";
import Modal from "../../index";


export default function Department(){

    const companyData = Store().getCompanyData()

    const [departmentList,setDepartmentList] = useState([])
    const [department,setDepartment] = useState("")

    const [changeUserData,setChangeUserData] = useState([])
    const [changeCompanyData,setChangeCompanyData] = useState({})

    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        console.log(changeUserData)
        console.log(changeCompanyData)
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }

    const onChangeDepartment = (e)=>{
        setDepartment(e.target.value)
    }


    const onSubmitHandler = (e)=>{
        e.preventDefault()

        let data = {
            companyData:companyData,
            delDepartment:department,
            userData:Store().getLoginData()
        }
        let params = {
            contents:'organizations',
            type:'departmentDelete'
        }

        adminService(data,params)
            .then(res=>{
                alert(res.data.msg)
                if(res.data.userData === 'None'){
                    Store().setCompanyData(res.data.companyData)
                    window.location.replace('/adminPage')
                }else{
                    console.log(res.data)
                    setChangeUserData(res.data.userData)
                    setChangeCompanyData(res.data.companyData)
                    openModal()
                }

            })
            .catch(err=>{
                alert(err.response.data)
            })


    }

    useEffect(()=>{
        let pushData = []
        companyData.organizations.map(item=>{
            pushData.push(item.department)
        })
        setDepartmentList(pushData)
    })

    return(
        <>
            <div style={{alignItems: 'center', flexDirection: 'column', display: 'flex', marginLeft: 40}}>
                <div style={{width: "100%"}}>
                    <br/>
                    <FormControl sx={{width: '90%'}}>
                        <InputLabel>삭제하실 부서 명</InputLabel>
                        <Select
                            name="department"
                            value={department}
                            onChange={onChangeDepartment}
                            input={<OutlinedInput label="name"/>}
                        >
                            {departmentList.map((item) => (
                                <MenuItem
                                    key={item}
                                    value={item}
                                >
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br/><br/>

                    <Button
                        disabled={department.length <= 0}
                        onClick={onSubmitHandler}
                        variant="contained"
                        type="submit"
                        size="medium"
                        sx={{
                            marginTop: 2,
                            width: "90%",
                            height: '45px',
                            border: 3,
                            "&.MuiButton-root:hover": {
                                color: '#008DDC',
                                backgroundColor: '#c7ebff',
                                borderColor: '#008DDC'
                            }
                        }}

                    >
                        부서 삭제
                    </Button>

                    {modalOpen && <>
                        <Modal open={modalOpen} close={closeModal} header="부서 삭제 전 유저 소속변경"
                               userData={changeUserData} companyData={changeCompanyData} department={department} />
                    </>}

                </div>
            </div>
        </>
    )
}