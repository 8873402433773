import React, {useContext, useEffect} from 'react'
import styled from 'styled-components'
import {companyLogout, logout} from "../../api";
import Store from "../../context/store";
import {MainContext} from "../../context";
import moment from "moment-timezone";
import CountNav from "../countNav";



const Common = styled.div`
  border-right: 1px solid #e0e0e0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 25%;
`
const Home = styled.a`
  display: flex;
  align-items:center;
  flex-direction: column;
  margin: 2px;
  text-decoration: none;
  color: black;
  font-weight: bold;
`
const MobileHome = styled.a`
  display: flex;
  align-items:center;
  margin: 1px;
  text-decoration: none;
  color: black;
  font-weight: bold;
  padding-left: 10px;
  padding-bottom: 3px;
`

const LogoImg = styled.img`
  width:220px;
  margin-top: 70px;
  margin-bottom: 30%;
`
const MobileLogoImg = styled.img`
  width:170px;
  height: 55px;
  margin-top: 3px;
`

const ContlorBox = styled.div`
  display: flex;
  align-items:center;
  flex-direction: column;
  padding-right: 10px;
  margin-left: 1px;
`

const Contlor = styled.a`
    margin:5%;
    text-decoration: none;
    color: black;
    padding-bottom: 30px;
  font-weight: bold;
  font-size: 120%;
`
const CountContlor = styled.a`
    margin:15%;
    text-decoration: none;
    color: black;
    padding-bottom: 30px;
    font-weight: bold;
    font-size: 100%;
`


const NavTop = styled.div`
  display: flex;
  align-items: unset;
  justify-content: flex-end;
  padding-right: 30px;
  padding-top: -30px;
  button {
    background: #004097;
    border: none;
  }
`;

const styles = {
    width: '100%',
    maxWidth: '180px',
    bgcolor: '#f0f4fa',
    float: 'right',
    position:'sticky',
    zIndex:1
};




export default function SideBar(){

    const { toggleClose,storeLogin } = useContext(MainContext)

    let loginData

    if(storeLogin === true){
        loginData = Store().getLoginData()
    }



    useEffect(()=>{
        if(storeLogin === true){
            const timer = setInterval(()=>{
                let expiresDate = moment(loginData.expiresDate)

                let now = new Date()
                let time = moment().tz('Asia/Seoul')

                const diff = expiresDate - now

                const diffHour = Math.floor(diff / (1000 * 60 * 60));
                const diffMin = Math.floor(diff / (1000 * 60));
                const diffSec = Math.floor(diff / 1000);


                const m = String(diffMin - diffHour * 60).padStart(2, "0");
                const s = String(diffSec - diffMin * 60 + 1).padStart(2, "0");


                let nowTime = moment(time.format('YYYY-MM-DD kk:mm:ss'))
                let durationTime = moment.duration(expiresDate.diff((nowTime))).asSeconds()
                if (durationTime <= 1) {
                    if (m === '00' && s === '01') {
                        logout()
                            .then(res => {
                                Store().setCompanyLogin(false)
                                Store().delItems('All')
                                window.location.replace('/')
                            })
                            .catch(err => {

                            })
                    }else{
                        logout()
                            .then(res => {
                                Store().setCompanyLogin(false)
                                Store().delItems('All')
                                window.location.replace('/')
                            })
                            .catch(err => {

                            })
                    }
                }

            },1000)
            return ()=>{
                clearInterval(timer)
            }
        }
    },[])




    return(
        <>
            {storeLogin ? (
                <>
                    <Common>
                        <Home href='/'>
                            <LogoImg href='/' src="images/Logo.jpg"/>
                        </Home>
                        <ContlorBox>
                            {loginData.form.admin === true ? (
                                <>
                                    {loginData.form.manager === true ? (
                                        <>
                                            <Contlor href='/'>근태 관리</Contlor>
                                            <Contlor href='/vacation'>휴가 관리</Contlor>
                                            {/*<Contlor href='/meetingRoom'>회의실 정보</Contlor>*/}
                                            <Contlor href='/adminPage'>관리자 정보페이지</Contlor>
                                            <Contlor href='/webAdminPage'>웹 매니저페이지</Contlor>
                                        </>
                                    ):(
                                        <>
                                            <Contlor href='/'>근태 관리</Contlor>
                                            <Contlor href='/vacation'>휴가 관리</Contlor>
                                            {/*<Contlor href='/meetingRoom'>회의실 정보</Contlor>*/}
                                            <Contlor href='/adminPage'>관리자 정보페이지</Contlor>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Contlor href='/'>근태 관리</Contlor>
                                    <Contlor href='/vacation'>휴가 관리</Contlor>
                                    {/*<Contlor href='/meetingRoom'>회의실 정보</Contlor>*/}
                                </>
                            )}

                        </ContlorBox>
                    </Common>
                </>
            ):(
                <>
                </>
            )}

        </>
    )
}