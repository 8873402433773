import React, {useEffect, useState} from "react";
import Store from "../../../../../../context/store";
import moment from "moment-timezone";
import {Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import {updateUser} from "../../../../../../api";


export default function Update(props){

    const {data,sizeType} = props

    const companyData = Store().getCompanyData()

    const [department,setDepartment] = useState([])
    const [departmentList,setDepartmentList] =useState([])
    const [position,setPosition] =useState([])
    const [positionList,setPositionList] = useState([])
    const [name, setName] = useState('')
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");



    const [NameMessage, setNameMessage] = useState("")
    const [EmailMessage, setEmailMessage] = useState("")
    const [PwMessage, setPwMessage] = useState("")
    const [PwConfirmMessage, setPwConfirmMessage] =useState("")
    const [PhoneMessage, setPhoneMessage] = useState("")



    const [isDepartment,setIsDepartment] = useState(false)
    const [isPosition,setIsPosition] = useState(false)
    const [isBirth,setIsBirth] = useState(false)
    const [isName, setIsName] = useState(false)
    const [isEmail, setIsEmail] = useState(false)
    const [isPw, setIsPw] = useState(false)
    const [isPwConfirm, setIsPwConfirm] = useState(false)
    const [isPhone,setIsPhone] = useState(false)
    const [isAuthNum, setIsAuthNum]= useState(false)
    const [isSendAuth, setIsSendAuth]= useState(false)
    const [complete, setComplete] = useState(false)

    const now = new Date();
    const open = moment().tz('Asia/Seoul')
    const formYear = open.format(`YYYY`)


    const today = open.format('YYYY-MM-DD').split('-').join('')



    const [breaks,setBreaks] = useState({
        year:data[0].yearBreak,
        month:data[0].monthBreak,
        family:data[0].family,
        maternity:data[0].maternity
    })

    const [form, setForm] = useState({
        year: formYear,
        month: "01",
        day: "01",
    });

    const [join, setJoin] = useState({
        year: formYear,
        month: "01",
        day: "01",
    });
    const [attendance, setAttendance] = useState({
        hours: "00",
        min: "00",
    });
    const [quit, setQuit] = useState({
        hours: "00",
        min: "00",
    });
    const [lunchTime, setLunchTime] = useState({
        hours: "00",
        min: "00",
    });
    const [lunchEndTime, setLunchEndTime] = useState({
        hours: "00",
        min: "00",
    });

    let birth = `${form.year}-${form.month}-${form.day}`

    let years = [];
    for (let y = now.getFullYear(); y >= 1900; y -= 1) {
        years.push(y);
    }

    let month = [];
    for (let m = 1; m <= 12; m += 1) {
        if (m < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 월에 0을 붙혀준다
            month.push("0" + m.toString());
        } else {
            month.push(m.toString());
        }
    }
    let days = [];
    let date = new Date(form.year, form.month, 0).getDate();
    for (let d = 1; d <= date; d += 1) {
        if (d < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 일에 0을 붙혀준다
            days.push("0" + d.toString());
        } else {
            days.push(d.toString());
        }
    }

    let dates = new Date(join.year, join.month, 0).getDate();
    for (let d = 1; d <= dates; d += 1) {
        if (d < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 일에 0을 붙혀준다
            days.push("0" + d.toString());
        } else {
            days.push(d.toString());
        }
    }

    let hours =[];

    for (let m = 0; m <= 23; m += 1) {
        if (m < 10) {
            hours.push("0" + m.toString());
        } else {
            hours.push(m.toString());
        }
    }

    let minutes =[];

    for (let m = 0; m <= 59; m += 1) {
        if (m < 10) {
            minutes.push("0" + m.toString());
        } else {
            minutes.push(m.toString());
        }
    }

    const onChangeDepartment = (e) =>{
        const current = e.target.value
        setDepartment(current)

        let filter =[]
        companyData.organizations.map(e=>{
            if(current === e.department){
                e.position.map(item=>{
                    filter.push(item.name)
                })
            }
        })
        setPosition(filter[0])
        setPositionList(filter)
        if(current.length >= 1){
            setIsDepartment(true)
        }else{
            setIsDepartment(false)
        }
    }

    const onChangePosition = (e) =>{
        const current = e.target.value
        setPosition(current)
        if(current.length >= 1){
            setIsPosition(true)
        }else{
            setIsPosition(false)
        }
    }

    const [yearState,setYearState] =useState(false)
    const [monthState,setMonthState] =useState(false)
    const [familyState,setFamilyState] =useState(false)
    const [maternityState,setMaternityState] =useState(false)

    const onChangeBreak = (e) =>{
        const current = e.target.value
        const targetId = e.target.id
        const check = /^[0-9]*$/;

        if(targetId === 'year'){
            if(check.test(current)){
                setBreaks({...breaks, year: current})
                setYearState(true)
            }else{
                setYearState(false)
            }

        }
        if(targetId === 'month'){
            if(check.test(current)){
                setBreaks({...breaks, month: current})
                setMonthState(true)
            }else{
                setMonthState(false)
            }

        }
        if(targetId === 'family'){
            if(check.test(current)){
                setFamilyState(true)
                setBreaks({...breaks, family: current})
            }else{
                setFamilyState(false)
            }

        }
        if(targetId === 'maternity'){
            if(check.test(current)){
                setMaternityState(true)
                setBreaks({...breaks, maternity: current})
            }else{
                setMaternityState(false)
            }

        }

    }

    const onChangeName = (e) => {
        const currentName = e.currentTarget.value;
        setName(currentName)

        if(Number(today) <= Number(birth.split('-').join(''))){
            alert('생년월일이 현재 날짜와 동일하거나 높을 수 없습니다. 생년월일을 다시 한번 확인해 주세요.')
            setIsBirth(false)
            setName('')
        }else{
            setIsBirth(true)
        }

        const nameRegExp = /^[가-힣a-zA-Z]+$/;

        if (!nameRegExp.test(currentName) || name.length<1) {
            setNameMessage('이름을 두 글자 이상 입력하세요!');
            setIsName(false);
        } else {
            setNameMessage('');
            setIsName(true);
        }

    }

    const onChangeEmail = (e) => {
        const currentEmail = e.currentTarget.value;
        setUserId(currentEmail)
        if(Number(today) <= Number(birth.split('-').join(''))){
            alert('생년월일이 현재 날짜와 동일하거나 높을 수 없습니다. 생년월일을 다시 한번 확인해 주세요.')
            setIsBirth(false)
            setUserId('')
        }else{
            setIsBirth(true)
        }

        const emailRegExp =
            /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;

        if (!emailRegExp.test(currentEmail)) {
            setEmailMessage("이메일의 형식이 올바르지 않습니다!");
            setIsEmail(false);
        } else {
            setEmailMessage("");
            setIsEmail(true);
        }
    };
    const onChangePassword = (e) => {
        const currentPw = e.currentTarget.value;
        setPassword(currentPw);

        if(Number(today) <= Number(birth.split('-').join(''))){
            alert('생년월일이 현재 날짜와 동일하거나 높을 수 없습니다. 생년월일을 다시 한번 확인해 주세요.')
            setIsBirth(false)
            setPassword('')
        }else{
            setIsBirth(true)
        }
        const passwordRegExp =
            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
        if (!passwordRegExp.test(currentPw)) {
            setPwMessage(
                "숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"
            );
            setIsPw(false);
        } else {
            setPwMessage("");
            setIsPw(true);
        }
    };

    const onChangePhone = (getNumber) => {
        const currentPhone = getNumber;
        setPhone(currentPhone);

        if(Number(today) <= Number(birth.split('-').join(''))){
            alert('생년월일이 현재 날짜와 동일하거나 높을 수 없습니다. 생년월일을 다시 한번 확인해 주세요.')
            setIsBirth(false)
            setPhone('')
        }else{
            setIsBirth(true)
        }
        const phoneRegExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

        if (!phoneRegExp.test(currentPhone)) {
            setPhoneMessage("핸드폰 번호를 입력해 주세요!");
            setIsPhone(false);
        } else {
            setPhoneMessage("");
            setIsPhone(true);
        }
    };
    const addHyphen = (e) => {
        const currentNumber = e.currentTarget.value;
        setPhone(currentNumber);
        if (currentNumber.length === 3 || currentNumber.length === 8) {
            setPhone(currentNumber + "-");
            onChangePhone(currentNumber + "-");
        } else {
            onChangePhone(currentNumber);
        }
    };


    const handleSubmit = (e)=>{
        e.preventDefault()

        let macAddress

        Store().getCompanyData().organizations.map(el=>{
            if(el.department === department){
                el.position.map(item=>{
                    if(item.name === position){
                        macAddress=item.mac
                    }
                })
            }
        })

        let sendData = {
            company:Store().getCompanyData().company,
            affiliation:{
                department:department,
                position:position,
                macAddress:macAddress
            },
            name:name,
            userId:userId,
            password:password,
            phone:phone,
            break:{
                year:Number(breaks.year),
                month:Number(breaks.month),
                special:{
                    family:Number(breaks.family),
                    maternity:Number(breaks.maternity)
                }
            },
            form:data[0].admin === 'X' ? {
                normal:true,
                admin:false,
                manager:false
            }:{normal:false,
                admin:true,
                manager:false},
            etc:{
                birth:birth,
                joinDate:`${join.year}-${join.month}-${join.day}`,
                workTime: `${attendance.hours}:${attendance.min}-${quit.hours}:${quit.min}`,
                lunchTime: `${lunchTime.hours}:${lunchTime.min}-${lunchEndTime.hours}:${lunchEndTime.min}`
            }
        }
        let params = {
            plane:password === 'PlanePassword' ? true:false
        }
        updateUser(sendData,params)
            .then(res=>{
                if(res.data.data === 'None'){
                    alert(res.data.msg)
                    window.location.replace('/adminPage')
                }else{
                    alert(res.data.msg)
                    Store().setLoginData(res.data.data)
                    window.location.replace('/adminPage')
                }
            })
            .catch(err=>{
                alert(err.response.data)
            })
    }


    useEffect(()=>{
        const selectData = data[0]
        // let depart = data.map(item=>item.department)[0]
        setName(selectData.name)
        setUserId(selectData.id)
        setPhone(selectData.phone)
        setPassword("PlanePassword")
        setDepartment(selectData.department)
        setPosition(selectData.position)
        let dl =[]
        let dp =[]
        companyData.organizations.map(e=>{
            if(selectData.department === e.department){
                e.position.map(item=>{
                    dp.push(item.name)
                })
            }
            dl.push(e.department)
        })
        setPositionList(dp)
        setDepartmentList(dl)


        let setBirthData = selectData.birth.split('-')
        setForm({
            year: setBirthData[0],
            month: setBirthData[1],
            day: setBirthData[2],
        })

        let setJoinData = selectData.joinDate.split('-')
        setJoin({
            year: setJoinData[0],
            month: setJoinData[1],
            day: setJoinData[2]
        })

        let workTimeData = selectData.workTime.split('-')
        setAttendance({
            hours: workTimeData[0].split(':')[0],
            min: workTimeData[0].split(':')[1]
        })
        setQuit({
            hours: workTimeData[1].split(':')[0],
            min: workTimeData[1].split(':')[1]
        })
        let lunchTimeData = selectData.lunchTime.split('-')
        setLunchTime({
            hours: lunchTimeData[0].split(':')[0],
            min: lunchTimeData[0].split(':')[1]
        })
        setLunchEndTime({
            hours: lunchTimeData[1].split(':')[0],
            min: lunchTimeData[1].split(':')[1]
        })

    },[])


    return(
        <>
            <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
                <Box
                    sx={{
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        width: '100%',
                        borderRadius: '0.5rem',
                        opacity: 0.9
                    }}
                >
                    <div style={{width: "90%", marginBottom: 20}}>
                        <FormControl sx={{width: '45%', margin: 1}}>
                            <InputLabel>부서</InputLabel>
                            <Select
                                //defaultValue={data.map(e=>e.department)}
                                value={department}
                                //disabled={isSendAuth === true}
                                autoFocus
                                name="department"
                                onChange={onChangeDepartment}
                                input={<OutlinedInput label="name"/>}
                            >
                                {departmentList.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{width: '45%', margin: 1}}>
                            <InputLabel>직급</InputLabel>
                            <Select
                                //disabled={isDepartment === false || isSendAuth === true}
                                value={position}
                                name="position"
                                onChange={onChangePosition}
                                input={<OutlinedInput label="name"/>}
                            >
                                {positionList.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>


                    <div style={{width: "90%", marginBottom: 20}}>
                        <TextField
                            onChange={onChangeName}
                            required
                            value={name}
                            sx={{width: '22%', margin: 1}}
                            id="name"
                            name="name"
                            label="이름(성함)"
                            error={NameMessage === '이름을 두 글자 이상 입력하세요!'}
                        />

                        <TextField
                            onChange={onChangeEmail}
                            required
                            value={userId}
                            disabled
                            sx={{width: '22%', margin: 1}}
                            id="userId"
                            name="userId"
                            label="아이디(E-Mail)"
                            error={EmailMessage === '이메일의 형식이 올바르지 않습니다!'}
                        />
                        <TextField
                            required
                            value={phone}
                            sx={{width: "22%", margin: 1}}
                            type="text"
                            onChange={addHyphen}
                            name="phone"
                            label="전화번호( - 빼고 기입)"
                            error={PhoneMessage === '핸드폰 번호를 입력해 주세요!'}
                        />
                        <TextField
                            onChange={onChangePassword}
                            required
                            sx={{width: '22%', margin: 1}}
                            type="password"
                            value={password}
                            name="password"
                            label="비밀번호 (숫자+영문자+특수문자 8자리 이상)"
                            error={PwMessage === '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!'}
                        />

                    </div>

                    <div style={{width: "90%", marginBottom: 30, marginLeft: "5%"}}>
                        <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 1}} variant="standard">
                            <InputLabel id="demo-simple-select-label">생년</InputLabel>
                            <Select
                                value={form.year}
                                onChange={(e) =>
                                    setForm({...form, year: e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                //disabled={isSendAuth === true || isDepartment === false || isPosition === false}
                                sx={{height: '31px', width: '80%'}}
                            >
                                <MenuItem value="">
                                    <em>생년</em>
                                </MenuItem>
                                {years.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 1}} variant="standard">
                            <InputLabel id="demo-simple-select-label">생년 월</InputLabel>
                            <Select
                                value={form.month}
                                onChange={(e) =>
                                    setForm({...form, month: e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                //disabled={isSendAuth === true || isDepartment === false || isPosition === false}
                                sx={{height: '31px', width: '80%'}}
                            >
                                <MenuItem value="">
                                    <em> 생년 월</em>
                                </MenuItem>
                                {month.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 3}} variant="standard">
                            <InputLabel id="demo-simple-select-label">생년 일</InputLabel>
                            <Select
                                value={form.day}
                                onChange={(e) => {
                                    setForm({...form, day: e.target.value})
                                }}
                                labelId="demo-simple-select-label"
                                autoWidth
                                //disabled={isSendAuth === true || isDepartment === false || isPosition === false}
                                sx={{height: '31px', width: '80%'}}
                            >
                                <MenuItem value="">
                                    <em>생년 일</em>
                                </MenuItem>
                                {days.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 1}} variant="standard">
                            <InputLabel id="demo-simple-select-label">입사 년</InputLabel>
                            <Select
                                value={join.year}
                                onChange={(e) =>
                                    setJoin({...join, year: e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                sx={{height: '31px', width: '80%'}}
                            >
                                <MenuItem value="">
                                    <em>입사 년</em>
                                </MenuItem>
                                {years.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 1}} variant="standard">
                            <InputLabel id="demo-simple-select-label">입사 월</InputLabel>
                            <Select
                                value={join.month}
                                onChange={(e) =>
                                    setJoin({...join, month: e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                sx={{height: '31px', width: '80%'}}
                            >
                                <MenuItem value="">
                                    <em>입사 월</em>
                                </MenuItem>
                                {month.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 1}} variant="standard">
                            <InputLabel id="demo-simple-select-label">입사 일</InputLabel>
                            <Select
                                value={join.day}
                                onChange={(e) => {
                                    setJoin({...join, day: e.target.value})
                                }}
                                labelId="demo-simple-select-label"
                                autoWidth
                                sx={{height: '31px', width: '80%'}}
                            >
                                <MenuItem value="">
                                    <em>입사 일</em>
                                </MenuItem>
                                {days.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </div>

                    <div style={{width: "90%", marginBottom: 30, marginLeft: "5%"}}>
                            <TextField
                                value={breaks.year}
                                onChange={onChangeBreak}
                                required
                                sx={{width: "22%", mt: 1, mb: 1, mr: 2}}
                                id="year"
                                name="year"
                                label='연차'
                            />
                            <TextField
                                required
                                value={breaks.month}
                                onChange={onChangeBreak}
                                sx={{width: "22%", mt: 1, mb: 1, mr: 2}}
                                id="month"
                                name="month"
                                label='월차'
                            />
                            <TextField
                                value={breaks.family}
                                required
                                onChange={onChangeBreak}
                                sx={{width: "22%", mt: 1, mb: 1, mr: 2}}
                                id="family"
                                name="family"
                                label='경조사'
                            />
                            <TextField
                                value={breaks.maternity}
                                required
                                onChange={onChangeBreak}
                                sx={{width: "22%", mt: 1, mb: 1}}
                                id="maternity"
                                name="maternity"
                                label='육아휴직'
                            />
                    </div>

                    <div style={{width: "90%", marginBottom: 30, marginLeft: "30%"}}>

                        <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 2}} variant="standard">
                            <InputLabel id="demo-simple-select-label">출근시간(시)</InputLabel>
                            <Select
                                value={attendance.hours}
                                onChange={(e) =>
                                    setAttendance({...attendance, hours: e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                sx={{height: '31px', width: '90%'}}
                            >
                                <MenuItem value="">
                                    <em>출근시간(시)</em>
                                </MenuItem>
                                {hours.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 5}} variant="standard">
                            <InputLabel id="demo-simple-select-label">출근시간(분)</InputLabel>
                            <Select
                                value={attendance.min}
                                onChange={(e) =>
                                    setAttendance({...attendance, min: e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                sx={{height: '31px', width: '90%'}}
                            >
                                <MenuItem value="">
                                    <em>출근시간(분)</em>
                                </MenuItem>
                                {minutes.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                        <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 2}} variant="standard">
                            <InputLabel id="demo-simple-select-label">퇴근시간(시)</InputLabel>
                            <Select
                                value={quit.hours}
                                onChange={(e) =>
                                    setQuit({...quit, hours: e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                sx={{height: '31px', width: '90%'}}
                            >
                                <MenuItem value="">
                                    <em>퇴근시간(시)</em>
                                </MenuItem>
                                {hours.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                        <FormControl sx={{marginTop: 0.1, minWidth: 100}} variant="standard">
                            <InputLabel id="demo-simple-select-label">퇴근시간(분)</InputLabel>
                            <Select
                                value={quit.min}
                                onChange={(e) =>
                                    setQuit({...quit, min: e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                sx={{height: '31px', width: '90%'}}
                            >
                                <MenuItem value="">
                                    <em>퇴근시간(분)</em>
                                </MenuItem>
                                {minutes.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                    </div>

                    <div style={{width: "90%", marginBottom: 20, marginLeft: "30%"}}>
                        <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 2}} variant="standard">
                            <InputLabel id="demo-simple-select-label">점심시작 시간(시)</InputLabel>
                            <Select
                                value={lunchTime.hours}
                                onChange={(e) =>
                                    setLunchTime({...lunchTime, hours: e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                sx={{height: '31px', width: '90%'}}
                            >
                                <MenuItem value="">
                                    <em>점심시작 시간(시)</em>
                                </MenuItem>
                                {hours.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 5}} variant="standard">
                            <InputLabel id="demo-simple-select-label">점심시작 시간(분)</InputLabel>
                            <Select
                                value={lunchTime.min}
                                onChange={(e) =>
                                    setLunchTime({...lunchTime, min: e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                sx={{height: '31px', width: '90%'}}
                            >
                                <MenuItem value="">
                                    <em>점심 시작시간(분)</em>
                                </MenuItem>
                                {minutes.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>



                        <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 2}} variant="standard">
                            <InputLabel id="demo-simple-select-label">점심종료 시간(시)</InputLabel>
                            <Select
                                value={lunchEndTime.hours}
                                onChange={(e) =>
                                    setLunchEndTime({...lunchEndTime, hours: e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                sx={{height: '31px', width: '90%'}}
                            >
                                <MenuItem value="">
                                    <em>점심종료 시간(시)</em>
                                </MenuItem>
                                {hours.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{marginTop: 0.1, minWidth: 100}} variant="standard">
                            <InputLabel id="demo-simple-select-label">점심종료 시간(분)</InputLabel>
                            <Select
                                value={lunchEndTime.min}
                                onChange={(e) =>
                                    setLunchEndTime({...lunchEndTime, min: e.target.value})}
                                labelId="demo-simple-select-label"
                                autoWidth
                                sx={{height: '31px', width: '90%'}}
                            >
                                <MenuItem value="">
                                    <em>점심종료 시간(분)</em>
                                </MenuItem>
                                {minutes.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <Button
                        onClick={handleSubmit}
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{
                            mt: 2, mb: 2,
                            width: "90%",
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        정보 수정하기
                    </Button>

                </Box>
            </div>
        </>
    )
}