import Store from "../../../../context/store";
import React, {useEffect, useRef, useState} from "react";
import {
    Box, Button, Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {ko} from "date-fns/esm/locale";
import DatePicker from "react-datepicker";
import {getAttendance, vacationService} from "../../../../api";


export default function Update(){

    const [selectList,setSelectList] = useState([])
    const [checkList, setCheckList] = useState([]);

    const vacationData = Store().getUserVacation()

    const checkAll = (e) => {
        e.target.checked
            ? setCheckList([...selectList])
            : setCheckList([])
    }
    const isAllChecked = checkList.length > 0;
    const disabled = !isAllChecked

    const check = (e) => {
        e.target.checked
            ? setCheckList([...checkList, e.target.name])
            : setCheckList(checkList.filter((choice)=> choice !== e.target.name))
    }

    const handleClick =(e)=>{
        e.preventDefault()
        let pushData = []
        let yearCount = 0;
        let monthCount = 0;
        let familyCount = 0;
        let maternityCount = 0;
        checkList.map(e=>{
            const type = e.split(':')[0].trim()
            const delDate = e.split(':')[1].split(' ')[1]
            if(type === '연차'){
                pushData.push({date:delDate,type:"year"})
                yearCount+=1
            }
            if(type === '월차'){
                pushData.push({date:delDate,type:"month"})
                monthCount+=1
            }
            if(type === '경조사'){
                pushData.push({date:delDate,type:"family"})
                familyCount+=1
            }
            if(type === '육아휴직'){
                pushData.push({date:delDate,type:"maternity"})
                maternityCount+=1
            }
        })
        let sendData = {
            company:Store().getCompanyData().company,
            loginData:Store().getLoginData(),
            info: { userId: Store().getLoginData().info.userId },
            delData:pushData,
            breakCount:{
                year:yearCount,
                month:monthCount,
                family:familyCount,
                maternity:maternityCount,
            }
        }
        let params = {
            service:'change',
            checked:'delete'
        }

        vacationService(sendData,params)
            .then(res=>{
                alert('요청하신 휴가가 정상적으로 취소처리 되었습니다.')
                Store().setUserVacation(res.data.vacation)
                Store().setLoginData(res.data.userData)
                let sendData2 = {
                    company: Store().getCompanyData().company,
                    userData: Store().getLoginData()
                }
                getAttendance(sendData2)
                    .then(res1=>{
                        Store().setAttendanceData(res1.data)
                        let params = {
                            service:'getData'
                        }
                        vacationService(Store().getLoginData(),params)
                            .then(res2=>{
                                Store().setVacationState(res2.data)
                                window.location.replace('/vacation')
                            })
                            .catch(err=>{

                            })
                    })
                    .catch(err=>{

                    })

            })
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
            })

    }



    useEffect(()=>{
        let pushData = []
        vacationData.map(e=>{
            const week= new Array('일요일','월요일','화요일','수요일','목요일','금요일','토요일');
            const today= new Date(e.date).getDay();
            const todayLabel=week[today];

            if(e.type === 'year'){
                pushData.push(`연차 : ${e.date} (${todayLabel})`)
            }
            if(e.type === 'month'){

                pushData.push(`월차 : ${e.date} (${todayLabel})`)
            }
            if(e.type === 'family'){
                pushData.push(`경조사 : ${e.date} (${todayLabel})`)
            }
            if(e.type === 'maternity'){
                pushData.push(`육아휴직 : ${e.date} (${todayLabel})`)
            }
        })
        setSelectList([...pushData])
    },[])


    return(
        <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
            <div style={{alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                <div style={{width: "100%"}}>
                    <FormControlLabel
                        control={<Checkbox
                            style={{fontWeight: 2}}
                            margin="normal"
                            required
                            name="all"
                            onChange={checkAll}
                            checked={checkList.length === selectList.length ? true : false}
                            color="primary"/>}
                        label={<Typography variant='body2'>전체 선택</Typography>}
                    /><br/><br/>
                    {selectList.map((item) => (
                        <div key={item}>
                            <FormControlLabel
                                control={<Checkbox color="primary"
                                                   margin="normal"
                                                   required
                                                   name={item}
                                                   onChange={check}
                                                   checked={checkList.includes(item) ? true : false}/>}
                                label={<Typography variant='body2'>{item}</Typography>}
                            />

                            <br/><br/>
                        </div>
                    ))}
                    <br/>
                    <Typography component="h5" variant="h7" sx={{color: 'red'}}>
                        ★ 휴가 타입(년차,월차,경조사,육아휴직) 변경은 휴가취소 후 재신청 해주시기 바랍니다.
                    </Typography><br/><br/>

                    <Button disabled={disabled}
                            autoFocus
                            type="submit"
                            size="large"
                            onClick={handleClick}
                            required
                            sx={{
                                width: "100%"
                            }}
                            style={
                                disabled
                                    ? {backgroundColor: '#859594'}
                                    : {backgroundColor: '#1e90ff'}
                            }
                    >
                        <Typography variant='body2' color="primary.contrastText">
                            휴가 취소하기</Typography>
                    </Button><br/><br/>

                </div>
            </div>

        </div>
    )
}