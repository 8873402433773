import Common from "../Crud/common";
import React from "react";
import {DataGrid} from "@mui/x-data-grid";
import Create from "../Crud/users/create";
import Update from "../Crud/users/update";
import Delete from "../Crud/users/delete";



export default function Users(props){

    const {isFindData,isCheck,setIsFindData,setFindData,search,setSearch,companyData,isAdmin,findData,
        columns,onUserRowsSelectionHandler,userDBData,selectedData} = props

    return(
        <>
            <div>
                <Common isFindData={isFindData} isCheck={isCheck} setIsFindData={setIsFindData}
                       setData={setFindData} search={search} setSearch={setSearch}
                       company={companyData.company} isAdmin={isAdmin} /><br/>
                <div style={{alignItems:'center',display:'flex',flexDirection:'column',margin:10,paddingBottom:5}}>
                    <div>
                        <Create />
                        <Update selectedData={selectedData}/>
                        <Delete selectedData={selectedData}/>
                        {/*<ExcelUpload />*/}
                        {/*<ExcelDownload selectedData={selectedData}/>*/}
                    </div>

                </div>
            </div>

            <div style={{ height: 500, width: '90%' }}>

                        <DataGrid
                            rows={isFindData === true ? findData:userDBData}
                            columns={columns}
                            pageSize={20}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            onRowSelectionModelChange={(ids) => onUserRowsSelectionHandler(ids)}
                        />

            </div>
        </>
    )
}