import Store from "../../../../../../../context/store";
import React, {useEffect, useRef, useState} from "react";
import {Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import Modals from "../../../../modals/admin";
import {adminService} from "../../../../../../../api";


export default function Department(props){

    const { sizeType }=props

    const companyData = Store().getCompanyData()
    const loginData = Store().getLoginData()

    const nextID = useRef(0)
    const [department,setDepartment] = useState("")

    const [departmentList,setDepartmentList] = useState([])
    const [positionList,setPositionList] = useState([])
    const [macList,setMacList] = useState([])


    const [isDepartment,setIsDepartment] = useState(false)
    const [isPosition,setIsPosition] = useState(false)
    const [position,setPosition] =useState([{
        id:0,
        position:'',
        mac:[],
        label:`직급(Position)`,
        macLabel: '사용기기 수정'
    }])

    const onChangeDepartment = (e)=>{
        const current =e.target.value
        setDepartment(current)

        if(isDepartment === false){
            let filter = []
            companyData.organizations.map(item=>{
                if(current === item.department){
                    item.position.map(e=>{
                        const input = {
                            id:filter.length === 0 ? 0 : nextID.current,
                            position:e.name,
                            mac:e.mac,
                            label:`직급(Position) ${nextID.current}`,
                            macLabel: `사용기기 수정`
                        }
                        filter.push(input)
                        nextID.current += 1;
                    })
                }
            })
            setPosition(filter)
            setIsDepartment(true)
        }else{
            setPosition([{
                id:0,
                position:'',
                mac:[],
                label:`직급(Position)`,
                macLabel: '사용기기 수정'
            }])
            nextID.current = 1

            let filter = []
            companyData.organizations.map(item=>{
                if(current === item.department){
                    item.position.map(e=>{
                        const input = {
                            id:filter.length === 0 ? 0 : nextID.current,
                            position:e.name,
                            mac:e.mac,
                            label:`직급(Position) ${nextID.current}`,
                            macLabel: `사용기기 수정`
                        }
                        filter.push(input)
                        nextID.current += 1;
                    })
                }
            })
            setPosition(filter)
        }

    }

    const addPosition = (e)=>{
        const input = {
            id:nextID.current,
            position:'',
            mac:[],
            label:`직급(Position) ${nextID.current}`,
            macLabel: `사용기기 수정`
        }
        setPosition([...position,input])
        nextID.current += 1;
    }
    const delPosition = (index)=>{
        setPosition(position.filter(item=>item.id !== index))
    }

    const onChangePosition = (e,index)=>{
        if(index > position.length) return;

        const formItemsCopy = JSON.parse(JSON.stringify(position))

        formItemsCopy[index].position = e.target.value;
        setPosition(formItemsCopy)

    }

    const [modalOpen, setModalOpen] = useState(false);
    const [changeModalOpen,setChangeModalOpen] = useState(false)
    const [modalNum,setModalNum] = useState('')
    const openModal=(e)=>{
        setModalNum(e.target.name)
        setModalOpen(true);
    };
    const closeModal = ()=>{
        setModalOpen(false);
    }
    const changeOpenModal=()=>{
        setChangeModalOpen(true);
    };
    const changeCloseModal = ()=>{
        setChangeModalOpen(false);
    }

    const [comResData,setComResData] =useState([])
    const [userResData,setUserResData] =useState([])

    const submitHandler = (e)=>{
        e.preventDefault()
        let data ={
            company:companyData.company,
            loginData:loginData,
            organizations:{department:department,position:position.map(items=>({name:items.position,mac:items.mac}))}
        }
        let params = {
            contents:'organizations',
            type:'departUpdate'
        }
        adminService(data,params)
            .then(res=>{
                if(res.data.msg === 'Selection Required'){
                    alert('해당 부서에 포지션 또는 기기등록 선택이 필요한 직원이 있습니다.(포지션 및 기기등록 선택 완료 후 데이터가 수정됩니다.)')
                    setComResData(res.data.companyData)
                    setUserResData(res.data.userData)
                    changeOpenModal()
                }else{
                    if(res.data.userData === 'None'){
                        alert(res.data.msg)
                        Store().setCompanyData(res.data.companyData)
                        window.location.replace('/adminPage')
                    }else{
                        alert(res.data.msg)
                        Store().setLoginData(res.data.userData)
                        Store().setCompanyData(res.data.companyData)
                        window.location.replace('/adminPage')
                    }

                }

            })
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
            })

    }


    useEffect(()=>{
        let pushData = []
        companyData.organizations.map(item=>{
            pushData.push(item.department)
        })
        setDepartmentList(pushData)
    })

    return(
        <>
            <div style={{alignItems:'center',flexDirection:'column',display:'flex',marginLeft:40}}>
                <div style={{width:"100%"}}>
                    <br/>
                    <FormControl sx={{width:'85%'}}>
                        <InputLabel>부서 명</InputLabel>
                        <Select
                            value={department}
                            name="department"
                            onChange={onChangeDepartment}
                            input={<OutlinedInput label="name" />}
                        >
                            {departmentList.map((item) => (
                                <MenuItem
                                    key={item}
                                    value={item}
                                >
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br/><br/>
                    {position.map((item,index)=>(
                        <div key={index}>
                            <TextField
                                onChange={e=> onChangePosition(e,index)}
                                required
                                value={item.position}
                                disabled={isDepartment === false}
                                sx={{width:"30%",mt:1,mb:1}}
                                id="position"
                                name="position"
                                label={item.label}
                            />
                            {index >= 0 && position.length < 10 && (
                                <>
                                    <Button
                                        variant={position[index].macLabel === '기기 재등록' ? "contained":"outlined"}
                                        disabled={isDepartment === false}
                                        name={index}
                                        sx={{
                                            mt:1,
                                            ml:1,
                                            width:"25%",
                                            height:55,
                                            "&.MuiButton-root:hover":{
                                                color:'#008DDC',
                                                backgroundColor:'#c7ebff',
                                                borderColor:'#008DDC'
                                            }}}
                                        onClick={openModal}
                                    >
                                        {item.macLabel}
                                    </Button>
                                    {modalOpen && <>
                                        {sizeType === 'mobile' ? (
                                            <>
                                                <Modals open={modalOpen} close={closeModal} header="기기 등록"
                                                        sizeType="mobile" position={position} updateCheck="department"
                                                        item={item} setPosition={setPosition} modalNum={modalNum} />
                                            </>
                                        ):(
                                            <>
                                                <Modals open={modalOpen} close={closeModal}
                                                        position={position} updateCheck="department"
                                                        item={item} setPosition={setPosition}
                                                        modalNum={modalNum} header="기기 등록" />
                                            </>
                                        )}
                                    </>}
                                </>
                            )}
                            {index >= 0 && position.length < 10 && (
                                <>
                                    <Button
                                        variant="outlined"
                                        disabled={isDepartment === false}
                                        sx={{
                                            mt:1,
                                            ml:1,
                                            width:"10%",
                                            height:55,
                                            "&.MuiButton-root:hover":{
                                                color:'#008DDC',
                                                backgroundColor:'#c7ebff',
                                                borderColor:'#008DDC'
                                            }}}
                                        onClick={addPosition}
                                    >
                                        추가
                                    </Button>
                                </>
                            )}
                            {index >= 0 && position[index] && (
                                <>
                                    <Button
                                        variant="outlined"
                                        disabled={isDepartment === false}
                                        sx={{
                                            mt:1,
                                            ml:1,
                                            width:"10%",
                                            height:55,
                                            "&.MuiButton-root:hover":{
                                                color:'#008DDC',
                                                backgroundColor:'#c7ebff',
                                                borderColor:'#008DDC'
                                            }}}
                                        onClick={()=>delPosition(item.id)}
                                    >
                                        삭제
                                    </Button>
                                </>
                            )}


                        </div>
                    ))}
                    <Button
                        disabled={isDepartment === false}
                        onClick={submitHandler}
                        variant="contained"
                        type="submit"
                        size="medium"
                        fullWidth
                        sx={{
                            marginTop: 2,
                            width:"90%",
                            height: '45px',
                            border: 3,
                            "&.MuiButton-root:hover": {
                                color: '#008DDC',
                                backgroundColor: '#c7ebff',
                                borderColor: '#008DDC'
                            }
                        }}

                    >
                        부서 수정
                    </Button>

                    {changeModalOpen && <>
                        {sizeType === 'mobile' ? (
                            <>
                                <Modals open={changeModalOpen} close={changeCloseModal} header="포지션 & 기기 선택"
                                        sizeType="mobile" companyData={comResData} userData={userResData} department={department}  />
                            </>
                        ):(
                            <>
                                <Modals open={changeModalOpen} close={changeCloseModal}
                                        header="포지션 & 기기 선택" companyData={comResData} userData={userResData} department={department} />
                            </>
                        )}
                    </>}
                </div>
            </div>
        </>
    )
}