import React from "react";
import SignUp from "./signUp";
import Delete from "./Delete";


export default function Modal(props){

    const {open, close, header,sizeType,yearBreak,monthBreak,maternityBreak,familyBreak,attendanceData } = props;

    return(
        <>
            <div className={open ? 'openModal modal' : 'modal'}>
                {open ? (
                    <>
                        {sizeType === 'mobile' ? (
                            <>
                                <sectionRegisterMobile style={{marginTop:50}}>
                                    <header>
                                        {header}
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                    </header>
                                    <main style={{alignItems: "center"}}>
                                        {header === '휴가 신청' ? (
                                            <>
                                                <SignUp yearBreak={yearBreak} monthBreak={monthBreak} familyBreak={familyBreak} maternityBreak={maternityBreak}
                                                        attendanceData={attendanceData}/>
                                            </>
                                        ):(
                                            <>
                                                <Delete />
                                            </>
                                        )}

                                    </main>

                                    <footer>
                                        <button className="close" onClick={close}>
                                            닫기
                                        </button>
                                    </footer>
                                </sectionRegisterMobile>
                            </>
                        ):(
                            <>
                                <section style={{marginTop:50}}>
                                    <header>
                                        {header}
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                    </header>
                                    <main style={{alignItems: "center"}}>
                                        {header === '휴가 신청' ? (
                                            <>
                                                <SignUp yearBreak={yearBreak} monthBreak={monthBreak} familyBreak={familyBreak}
                                                        maternityBreak={maternityBreak} attendanceData={attendanceData} />
                                            </>
                                        ):(
                                            <>
                                                <Delete />
                                            </>
                                        )}

                                    </main>
                                    <footer>
                                        <button className="close" onClick={close}>
                                            닫기
                                        </button>
                                    </footer>
                                </section>
                            </>
                        )}
                    </>
                ) : null}
            </div>
        </>
    )
}