import React, {useContext, useEffect, useState} from 'react'
import {Box, Typography} from "@mui/material";
import Store from "../../context/store";
import {getAttendance} from "../../api";
import {MainContext} from "../../context";
import greenLogo from "../../picture/checkGreen.png";
import redLogo from "../../picture/checkRed.png";
import greenSpinner from "../../picture/greenSpinner.gif"
import redSpinner from "../../picture/spinner.gif"
import moment from "moment-timezone";
import Button from '@mui/material/Button';
import ButtonGroup from "@mui/material/ButtonGroup";
import Latest from "./latest";
import yellowLogo from "../../picture/yellowLogo.png";
import holidayLogo from "../../picture/holiday.png";
import homeWorkLogo from "../../picture/homeWork.png";
import absenceLogo from "../../picture/absence.png"
import overTimeLogo from "../../picture/overTime.png"
import yearBreakLogo from "../../picture/yearBreak.png"
import monthBreakLogo from "../../picture/monthBreak.png"
import familyBreakLogo from "../../picture/familyBreak.png"
import maternityBreakLogo from "../../picture/maternityBreak.png"
import Month from "./month";
import Week from "./week";
import Modal from "../Vacation/Modal";
import Sign from "../Vacation/sign";

function createData(date, time, userId) {
    return { date, time, userId };
}
export default function Index(){
    const { storeLogin } = useContext(MainContext)



    const [getData,setGetData] = useState([])
    const [startLogo,setStartLogo] = useState('')
    const [endLogo,setEndLogo] = useState('')
    const [startStateLogo,setStartStateLogo] = useState('')
    const [endStateLogo,setEndStateLogo] = useState('')
    const [startLabel,setStartLabel] = useState('')
    const [endLabel,setEndLabel] = useState('')
    const [startStateLabel,setStartStateLabel] = useState('')
    const [endStateLabel,setEndStateLabel] = useState('')
    
    const [latestDate,setLatestDate] = useState('')
    const [startTime,setStartTime] = useState('')
    const [quitTime,setQuitTime] = useState('')
    const [workStart,setWorkStart] = useState('')
    const [workQuit,setWorkQuit] = useState('')

    const [currentClick,setCurrentClick] = useState(null)
    const [check,setCheck] = useState(true)
    const [week,setWeek]=useState(false)
    const [prevClick, setPrevClick] = useState(null)

    const [monthData,setMonthData] = useState([])

    let [weekState,setWeekState] = useState({})


    let attendanceData = Store().getAttendanceData()

    const nowTime = moment().tz('Asia/Seoul').format('YYYY-MM-DD')




    const rows = [];


    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }


    const onSubmit = (e)=>{
        e.preventDefault()
        setCurrentClick(e.target.id)
    }

    useEffect((e)=>{
        if(currentClick !== null){
            let current = document.getElementById(currentClick)
            current.style.color = '#165FC7';
            current.style.backgroundColor = '#c7ebff'
            if(current.id === 'default'){
                setCheck(true)
                setWeek(false)
            }else{
                if(current.id === 'week'){
                    setWeek(true)
                    setCheck(false)
                }else{
                    setCheck(false)
                    setWeek(false)
                }
            }
        }
        if(prevClick !== null){
            let prev = document.getElementById(prevClick)
            prev.style.color = 'white'
            prev.style.backgroundColor = '#1976D2'
        }
        setPrevClick(currentClick)
    },[currentClick])

    useEffect(()=>{
        const timer = setInterval(()=>{

            let sendData = {
                company: Store().getCompanyData().company,
                userData: Store().getLoginData()
            }

            getAttendance(sendData)
                .then(res=>{
                    const basicData = Store().getAttendanceData()
                    if(basicData.log[0]._id !== res.data.log[0]._id){
                        Store().setAttendanceData(res.data)
                        attendanceData = res.data
                        window.location.reload()
                    }

                })
                .catch(err=>{

                })
        },1000)
    },[])

    useEffect(()=>{
        if(Store().getLogin() === true){
            if(attendanceData === null){
                let sendData = {
                    company: Store().getCompanyData().company,
                    userData: Store().getLoginData()
                }
                getAttendance(sendData)
                    .then(res => {
                        Store().setAttendanceData(res.data)
                        attendanceData = res.data
                        window.location.reload()
                    })
                    .catch(err => {

                    })
            }else{
                let time = moment().tz('Asia/Seoul')
                let now = time.format('YYYY-MM-DD')
                let nowDays = time.format('dddd')

                if(now !== attendanceData.latestData.start.date){
                    if(nowDays === 'Saturday' || nowDays === 'Sunday'){
                        setStartLogo(holidayLogo)
                        setEndLogo(holidayLogo)

                        if(attendanceData.state.start === 'normal'){
                            if (attendanceData.state.end === 'normal') {
                                setStartLabel('정상')
                                setEndLabel('정상')
                                setStartStateLogo(greenLogo)
                                setEndStateLogo(greenLogo)
                            }
                            if (attendanceData.state.end === 'abNormal') {
                                setStartLabel('정상')
                                setEndLabel('비정상 퇴근')
                                setStartStateLogo(greenLogo)
                                setEndStateLogo(redLogo)
                            }
                            if (attendanceData.state.end === 'onDuty') {
                                setStartLabel('정상')
                                setEndLabel('근무 중...')
                                setStartStateLogo(greenLogo)
                                setEndStateLogo(greenSpinner)
                            }
                        }else{
                            if (attendanceData.state.end === 'normal') {
                                setStartLabel('비정상')
                                setEndLabel('정상')
                                setStartStateLogo(redLogo)
                                setEndStateLogo(greenLogo)
                            }
                            if (attendanceData.state.end === 'abNormal') {
                                setStartLabel('비정상')
                                setEndLabel('비정상')
                                setStartStateLogo(redLogo)
                                setEndStateLogo(redLogo)
                            }
                            if (attendanceData.state.end === 'onDuty') {
                                setStartLabel('비정상')
                                setEndLabel('근무 중...')
                                setStartStateLogo(redLogo)
                                setEndStateLogo(redSpinner)
                            }
                        }
                        setStartLabel('휴무')
                        setEndLabel('휴무')
                    }else{
                        setStartLogo(absenceLogo)
                        setEndLogo(absenceLogo)

                        if(attendanceData.state.start === 'normal'){
                            if (attendanceData.state.end === 'normal') {
                                setStartLabel('정상')
                                setEndLabel('정상')
                                setStartStateLogo(greenLogo)
                                setEndStateLogo(greenLogo)
                            }
                            if (attendanceData.state.end === 'abNormal') {
                                setStartLabel('정상')
                                setEndLabel('비정상 퇴근')
                                setStartStateLogo(greenLogo)
                                setEndStateLogo(redLogo)
                            }
                            if (attendanceData.state.end === 'onDuty') {
                                setStartLabel('정상')
                                setEndLabel('근무 중...')
                                setStartStateLogo(greenLogo)
                                setEndStateLogo(greenSpinner)
                            }
                        }else{
                            if (attendanceData.state.end === 'normal') {
                                setStartLabel('비정상')
                                setEndLabel('정상')
                                setStartStateLogo(redLogo)
                                setEndStateLogo(greenLogo)
                            }
                            if (attendanceData.state.end === 'abNormal') {
                                setStartLabel('비정상')
                                setEndLabel('비정상')
                                setStartStateLogo(redLogo)
                                setEndStateLogo(redLogo)
                            }
                            if (attendanceData.state.end === 'onDuty') {
                                setStartLabel('비정상')
                                setEndLabel('근무 중...')
                                setStartStateLogo(redLogo)
                                setEndStateLogo(redSpinner)
                            }

                        }
                        setStartLabel('결근')
                        setEndLabel('결근')

                    }
                    setStartStateLabel('최신 출근 시간')
                    setEndStateLabel('최신 퇴근 시간')
                }else{
                    setStartStateLabel('출근 시간')
                    setEndStateLabel('퇴근 시간')
                    if(attendanceData.state.start === 'normal'){
                        if (attendanceData.state.end === 'normal') {
                            setStartLabel('정상')
                            setEndLabel('정상')
                            setStartLogo(greenLogo)
                            setEndLogo(greenLogo)
                        }
                        if (attendanceData.state.end === 'abNormal') {
                            setStartLabel('정상')
                            setEndLabel('비정상 퇴근')
                            setStartLogo(greenLogo)
                            setEndLogo(redLogo)
                        }
                        if (attendanceData.state.end === 'onDuty') {
                            setStartLabel('정상')
                            setEndLabel('근무 중...')
                            setStartLogo(greenLogo)
                            setEndLogo(greenSpinner)
                        }
                    }else{
                        if (attendanceData.state.end === 'normal') {
                            setStartLabel('비정상')
                            setEndLabel('정상')
                            setStartLogo(redLogo)
                            setEndLogo(greenLogo)
                        }
                        if (attendanceData.state.end === 'abNormal') {
                            setStartLabel('비정상')
                            setEndLabel('비정상')
                            setStartLogo(redLogo)
                            setEndLogo(redLogo)
                        }
                        if (attendanceData.state.end === 'onDuty') {
                            setStartLabel('비정상')
                            setEndLabel('근무 중...')
                            setStartLogo(redLogo)
                            setEndLogo(redSpinner)
                        }

                    }
                }

                setLatestDate(attendanceData.latestData.start.date)
                setStartTime(attendanceData.latestData.start.time)
                setQuitTime(attendanceData.latestData.end.time === attendanceData.latestData.start.time ? '근무 중...':attendanceData.latestData.end.time)
                setWorkStart(attendanceData.basicData.start)
                setWorkQuit(attendanceData.basicData.end)
                setGetData(attendanceData.log)
                setMonthData(attendanceData.monthData)

            }

        }
    },[])


    const [weekData,setWeekData] = useState([]);

    useEffect(()=>{
        let weekData = []
        let weekCount = 0;

        const day = new Date();
        const sunday = day.getTime() - 86400000 * day.getDay();

        day.setTime(sunday);

        const column = ['일요일','월요일','화요일','수요일','목요일','금요일','토요일']
        const weeks = [{column:column[0],date:day.toISOString().slice(0, 10)}];

        for (let i = 1; i < 7; i++) {
            day.setTime(day.getTime() + 86400000);
            weeks.push({column:column[i],date:day.toISOString().slice(0, 10)});
        }

        attendanceData.monthData.map(e=>{
            const findWeeksCheck = weeks.find((el)=>el.date === e.date)
            if(findWeeksCheck !== undefined){
                if(weekData.length === 0){
                    let pushData = {
                        _id:e._id,
                        userId:e.userId,
                        date:e.date,
                        time:e.time,
                        state:e.state
                    }
                    weekData.push(pushData)
                    weekCount = 1
                }else{
                    if(e.date === weekData.slice(-1)[0].date){
                        if(weekCount === 1){
                            let pushData = {
                                _id:e._id,
                                userId:e.userId,
                                date:e.date,
                                time:e.time,
                                state:'start'
                            }
                            weekData.push(pushData)
                            weekCount = 2
                        }else{
                            if(weekCount === 2){
                                let pushData = {
                                    _id:e._id,
                                    userId:e.userId,
                                    date:e.date,
                                    time:e.time,
                                    state:'start'
                                }
                                weekData[weekData.length -1] = pushData
                            }
                        }

                    }else{
                        let pushData = {
                            _id:e._id,
                            userId:e.userId,
                            date:e.date,
                            time:e.time,
                            state:'end'
                        }
                        weekData.push(pushData)
                        weekCount = 1
                    }
                }
            }
        })

        weeks.map(e=>{
            weekData.map(el=>{
                if(e.date === el.date){
                    let index = weeks.findIndex(obj=>obj.date == el.date)
                    if(el.state === 'start'){
                        if(attendanceData.basicData.start.split(':').join('') >= el.time.split(':').join('')){
                            weeks[index].startState = 'normal'
                        }else{
                            weeks[index].startState = "abNormal"
                        }
                        weeks[index].start = el.time
                    }else{
                        if(attendanceData.basicData.end.split(':').join('') < el.time.split(':').join('')){
                            weeks[index].endState = 'normal'
                        }else{
                            weeks[index].endState = "abNormal"
                        }
                        weeks[index].end = el.time
                    }
                }
            })
        })
        let holidays = []
        weeks.map(e=>{
            let index = weeks.findIndex(obj=>obj.date == e.date)
            attendanceData.holidays.map(item=>{
                if(e.date === item.date){
                    weeks[index].holiday = item
                    holidays.push(item)
                }
            })
        })

        let normalState = 0
        let abNormalState = 0
        let redNormalState = 0
        let absenceState = 0
        let overTimeState = 0
        let yearBreakState = 0
        let monthBreakState = 0
        let familyBreakState = 0
        let maternityBreakState = 0
        let homeWorkState =0

        weeks.map(e=>{
            let index = weeks.findIndex(obj=>obj.date == e.date)
            if(e.column === '일요일' || e.column === '토요일'){
                weeks[index].logoInfo = {label:'휴무 : ',logo:holidayLogo}
            }else{
                if(e.startState === 'normal'){
                    if(e.endState === 'normal'){
                        if(holidays.length === 0){
                            weeks[index].logoInfo = {label:'정상 : ',logo:greenLogo}
                            normalState +=1
                        }else{
                            const dateA = new Date(`${e.date} ${e.end}`);
                            const dateB = new Date(`${e.date} ${attendanceData.basicData.end}`);
                            const diffMSec = dateA.getTime() - dateB.getTime();
                            const diffMin = diffMSec / (60 * 1000);
                            if(diffMin > 30){
                                weeks[index].logoInfo = {label:'연장근무 : ',logo:overTimeLogo}
                                overTimeState +=1
                            }else{
                                weeks[index].logoInfo = {label:'정상 : ',logo:greenLogo}
                                normalState +=1
                            }
                        }
                    }else if(e.endState === 'abNormal'){
                        weeks[index].logoInfo = {label:'비정상 : ',logo:yellowLogo}
                        abNormalState +=1
                    }else{
                        weeks[index].logoInfo = {label:'근무 중 : ',logo:greenSpinner}
                    }
                }else{
                    if(e.endState === 'normal'){
                        weeks[index].logoInfo = {label:'비정상 : ',logo:yellowLogo}
                        abNormalState +=1
                    }else if(e.endState === 'abNormal'){
                        weeks[index].logoInfo = {label:'비정상 : ',logo:redLogo}
                        redNormalState +=1
                    }else{
                        if(e.startState === undefined){
                            let tzData = moment().tz('Asia/Seoul')
                            let now = tzData.format('YYYY-MM-DD')

                            if(holidays.length === 0){
                                if(e.date.split('-').join('') > now.split('-').join('')){
                                    weeks[index].logoInfo = {label:'future',logo:''}
                                }else{
                                    weeks[index].logoInfo = {label:'결근 : ',logo:absenceLogo}
                                    absenceState +=1
                                }
                            }else{
                                if(e.date.split('-').join('') > now.split('-').join('')){
                                    weeks[index].logoInfo = {label:'future',logo:''}
                                }else{
                                    weeks[index].logoInfo = {label:'결근 : ',logo:absenceLogo}
                                    absenceState +=1
                                }
                            }
                        }else{
                            weeks[index].logoInfo = {label:'근무 중 : ',logo:redSpinner}
                        }

                    }

                }
            }

            holidays.map(el=>{
                if(el.date === e.date){
                    if(el.isHoliday === true){
                        if(el.title === '연차'){
                            yearBreakState += 1
                            weeks[index].logoInfo = {label:`${el.title} : `,logo:yearBreakLogo}
                        }else if(el.title === '월차'){
                            monthBreakState +=1
                            weeks[index].logoInfo = {label:`${el.title} : `,logo:monthBreakLogo}
                        } else if(el.title === '경조사'){
                            familyBreakState +=1
                            weeks[index].logoInfo = {label:`${el.title} : `,logo:familyBreakLogo}
                        } else if(el.title === '육아휴직'){
                            maternityBreakState +=1
                            weeks[index].logoInfo = {label:`${el.title} : `,logo:maternityBreakLogo}
                        }else{
                            weeks[index].logoInfo = {label:`${el.title} : `,logo:holidayLogo}
                        }
                    }else{
                        if(el.title === '재택근무') {
                            weeks[index].logoInfo = {label: `${el.title} : `, logo: homeWorkLogo}
                            homeWorkState += 1
                        }
                    }
                }
            })
        })
        setWeekState({
            normal:normalState,
            abNormal:abNormalState,
            redAbNormal:redNormalState,
            absence:absenceState,
            overTime:overTimeState,
            yearBreak:yearBreakState,
            monthBreak:monthBreakState,
            familyBreak:familyBreakState,
            maternityBreak:maternityBreakState,
            homeWork:homeWorkState
        })
        setWeekData(weeks)
        let times = moment().tz('Asia/Seoul')
        let now = times.format('YYYY-MM-DD')
        weeks.map(e=>{
            if(e.date === now ){
                setStartLabel(e.logoInfo.label.split(':')[0])
                setEndLabel(e.logoInfo.label.split(':')[0])
                setStartLogo(e.logoInfo.logo)
                setEndLogo(e.logoInfo.logo)
            }
        })


        Store().setAttendanceState(weeks)


    },[])



    return(
        <>
            <br/>
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button onClick={onSubmit} id="default">최신 근태정보</Button>
                <Button onClick={onSubmit} id="week">주간 근태정보</Button>
                <Button onClick={onSubmit} id="month">월별 근태정보</Button>
            </ButtonGroup>
            <br/>
            <Sign />
            {check ? (
                <>
                    <Latest getData={getData} startLogo={startLogo} endLogo={endLogo} startLabel={startLabel} endLabel={endLabel}
                            latestDate={latestDate} startTime={startTime} quitTime={quitTime} workStart={workStart}
                            workQuit={workQuit} nowTime={nowTime} startStateLabel={startStateLabel} endStateLabel={endStateLabel}
                            startStateLogo={startStateLogo} endStateLogo={endStateLogo} />
                </>
            ):(
                <>
                    {week ? (
                        <>
                            <Week weekData={weekData} setWeekData={setWeekData} attendanceData={attendanceData}
                                  weekState={weekState} setWeekState={setWeekState} />
                        </>
                    ):(
                        <>
                            <Month monthData={monthData} attendanceData={attendanceData} />
                        </>
                    )}

                </>
            )}

            <br/>


        </>
    )
}