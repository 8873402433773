import {Box, Typography} from "@mui/material";
import greenLogo from "../../../picture/checkGreen.png";
import redLogo from "../../../picture/checkRed.png";
import yellowLogo from "../../../picture/yellowLogo.png";
import absenceLogo from "../../../picture/absence.png"
import holidayLogo from "../../../picture/holiday.png";
import overTimeLogo from "../../../picture/overTime.png";
import greenSpinner from "../../../picture/greenSpinner.gif";
import redSpinner from "../../../picture/spinner.gif";
import homeWorkLogo from "../../../picture/homeWork.png";
import yearBreakLogo from "../../../picture/yearBreak.png";
import monthBreakLogo from "../../../picture/monthBreak.png";
import familyBreakLogo from "../../../picture/familyBreak.png";
import maternityBreakLogo from "../../../picture/maternityBreak.png";
import React from "react";


export default function State(props){

    const { weekState } =props

    return(
        <>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: 2, marginLeft: "1%"}}>
                <Box
                    sx={{
                        backgroundColor: 'floralwhite',
                        width: "12vw",
                        height: 60,
                        borderRadius: '1rem',
                        marginBottom: 2,
                        marginRight: 2,
                        paddingRight: 2,
                        display: 'flex',
                        alignItems: 'center',
                    }}>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img alt="No Images" src={greenLogo} style={{paddingLeft: "20%", marginTop: 1, width: '15%'}}/>
                        <Typography component="h4" variant="h7" sx={{paddingLeft: "7%"}}>
                            정상 :
                        </Typography>
                        <Typography component="h5" variant="h6" color='black'
                                    sx={{paddingLeft: "5%"}}>
                            {weekState.normal} 회
                        </Typography>


                    </div>
                </Box>

                <Box
                    sx={{
                        backgroundColor: 'floralwhite',
                        width: "12vw",
                        height: 60,
                        borderRadius: '1rem',
                        marginBottom: 2,
                        marginRight: 2,
                        paddingRight: 2,
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img alt="No Images" src={yellowLogo} style={{paddingLeft: "10%", marginTop: 1, width: '15%'}}/>
                        <Typography component="h4" variant="h7" sx={{paddingLeft: "7%"}}>
                            비정상(경고) :
                        </Typography>
                        <Typography component="h5" variant="h6" color='black'
                                    sx={{paddingLeft: "5%"}}>
                            {weekState.abNormal} 회
                        </Typography>

                    </div>

                </Box>

                <Box
                    sx={{
                        backgroundColor: 'floralwhite',
                        width: "12vw",
                        height: 60,
                        borderRadius: '1rem',
                        marginBottom: 2,
                        marginRight: 2,
                        paddingRight: 2,
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img alt="No Images" src={redLogo} style={{paddingLeft: "20%", marginTop: 1, width: '15%'}}/>
                        <Typography component="h4" variant="h7" sx={{paddingLeft: "7%"}}>
                            비정상 :
                        </Typography>
                        <Typography component="h5" variant="h6" color='black'
                                    sx={{paddingLeft: "5%"}}>
                            {weekState.redAbNormal} 회
                        </Typography>

                    </div>

                </Box>

                <Box
                    sx={{
                        backgroundColor: 'floralwhite',
                        width: "12vw",
                        height: 60,
                        borderRadius: '1rem',
                        marginBottom: 2,
                        marginRight: 2,
                        paddingRight: 2,
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img alt="No Images" src={homeWorkLogo}
                             style={{paddingLeft: "10%", marginTop: 1, width: '15%'}}/>
                        <Typography component="h4" variant="h7" sx={{paddingLeft: "10%"}}>
                            재택근무 :
                        </Typography>
                        <Typography component="h5" variant="h6" color='black'
                                    sx={{paddingLeft: "5%", paddingRight: 2}}>
                            {weekState.homeWork} 회
                        </Typography>
                    </div>

                </Box>

                <Box
                    sx={{
                        backgroundColor: 'floralwhite',
                        width: "12vw",
                        height: 60,
                        borderRadius: '1rem',
                        marginBottom: 2,
                        marginRight: 2,
                        paddingRight: 2,
                        display: 'flex',
                        alignItems: 'center',
                    }}>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img alt="No Images" src={overTimeLogo}
                             style={{paddingLeft: "10%", marginTop: 1, width: '15%'}}/>
                        <Typography component="h4" variant="h7" sx={{paddingLeft: "7%"}}>
                            연장근무 :
                        </Typography>
                        <Typography component="h5" variant="h6" color='black'
                                    sx={{paddingLeft: "5%"}}>
                            {weekState.overTime} 회
                        </Typography>


                    </div>
                </Box>
            </div>

            <div style={{display: 'flex', alignItems: 'center', marginBottom: 2, marginLeft: "1%"}}>
                <Box
                    sx={{
                        backgroundColor: 'floralwhite',
                        width: "12vw",
                        height: 60,
                        borderRadius: '1rem',
                        marginBottom: 2,
                        marginRight: 2,
                        paddingRight: 2,
                        display: 'flex',
                        alignItems: 'center',
                    }}>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img alt="No Images" src={absenceLogo} style={{paddingLeft: "20%", marginTop: 1, width: '15%'}}/>
                        <Typography component="h4" variant="h7" sx={{paddingLeft: "7%"}}>
                            결근 :
                        </Typography>
                        <Typography component="h5" variant="h6" color='black'
                                    sx={{paddingLeft: "5%"}}>
                            {weekState.absence} 회
                        </Typography>


                    </div>
                </Box>

                <Box
                    sx={{
                        backgroundColor: 'floralwhite',
                        width: "12vw",
                        height: 60,
                        borderRadius: '1rem',
                        marginBottom: 2,
                        marginRight: 2,
                        paddingRight: 2,
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img alt="No Images" src={yearBreakLogo} style={{paddingLeft: "20%", marginTop: 1, width: '15%'}}/>
                        <Typography component="h4" variant="h7" sx={{paddingLeft: "7%"}}>
                            연차 :
                        </Typography>
                        <Typography component="h5" variant="h6" color='black'
                                    sx={{paddingLeft: "5%"}}>
                            {weekState.yearBreak} 회
                        </Typography>

                    </div>

                </Box>

                <Box
                    sx={{
                        backgroundColor: 'floralwhite',
                        width: "12vw",
                        height: 60,
                        borderRadius: '1rem',
                        marginBottom: 2,
                        marginRight: 2,
                        paddingRight: 2,
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img alt="No Images" src={monthBreakLogo} style={{paddingLeft: "20%", marginTop: 1, width: '15%'}}/>
                        <Typography component="h4" variant="h7" sx={{paddingLeft: "7%"}}>
                            월차 :
                        </Typography>
                        <Typography component="h5" variant="h6" color='black'
                                    sx={{paddingLeft: "5%"}}>
                            {weekState.monthBreak} 회
                        </Typography>

                    </div>

                </Box>

                <Box
                    sx={{
                        backgroundColor: 'floralwhite',
                        width: "12vw",
                        height: 60,
                        borderRadius: '1rem',
                        marginBottom: 2,
                        marginRight: 2,
                        paddingRight: 2,
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img alt="No Images" src={familyBreakLogo}
                             style={{paddingLeft: "10%", marginTop: 1, width: '15%'}}/>
                        <Typography component="h4" variant="h7" sx={{paddingLeft: "10%"}}>
                            경조사 :
                        </Typography>
                        <Typography component="h5" variant="h6" color='black'
                                    sx={{paddingLeft: "5%", paddingRight: 2}}>
                            {weekState.familyBreak} 회
                        </Typography>
                    </div>

                </Box>

                <Box
                    sx={{
                        backgroundColor: 'floralwhite',
                        width: "12vw",
                        height: 60,
                        borderRadius: '1rem',
                        marginBottom: 2,
                        marginRight: 2,
                        paddingRight: 2,
                        display: 'flex',
                        alignItems: 'center',
                    }}>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img alt="No Images" src={maternityBreakLogo}
                             style={{paddingLeft: "10%", marginTop: 1, width: '15%'}}/>
                        <Typography component="h4" variant="h7" sx={{paddingLeft: "7%"}}>
                            육아휴직 :
                        </Typography>
                        <Typography component="h5" variant="h6" color='black'
                                    sx={{paddingLeft: "5%"}}>
                            {weekState.maternityBreak} 회
                        </Typography>


                    </div>
                </Box>
            </div>
        </>
    )
}