import styled from "styled-components";
import {
    Box, Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel, MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import Logo from "../../../../../../picture/Logo.png";
import React, {useRef, useState} from "react";
import {adminService, companyInfoUpdate, organizationService} from "../../../../../../api";
import moment from "moment-timezone";
import Store from "../../../../../../context/store";
import Modals from "../../../modals/admin"

const FormHelperTextsRED = styled(FormHelperText)`
  width: 100%;
  padding-left: 110px;
  padding-bottom: 10px;
  font-weight: 700 !important;
  color: #d32f2f !important;
  display: flex;
  align-items: center;
`;

const FormHelperTextsBLUE = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #0f27d9 !important;
`;


export default function Index(props){

    const { sizeType }=props

    const companyData = Store().getCompanyData()

    const nextID = useRef(1)
    const [department,setDepartment] = useState('')
    const [isDepartment,setIsDepartment] = useState(false)
    const [isMac,setIsMac] = useState(false)
    const [deMessage,setDeMessage] = useState('')
    const [position,setPosition] =useState([{
        id:0,
        position:'',
        mac:[],
        label:`직급(Position)`,
        macLabel: '사용기기 등록'
    }])

    const onChangeDepartment = (e) => {
        const currentName = e.currentTarget.value;
        setDepartment(currentName)
        setIsDepartment(true)
    }

    const addPosition = (e)=>{
        const input = {
            id:nextID.current,
            position:'',
            mac:[],
            label:`직급(Position) ${nextID.current}`,
            macLabel: `사용기기 등록`
        }
        setPosition([...position,input])
        nextID.current += 1;
    }
    const delPosition = (index)=>{
        setPosition(position.filter(item=>item.id !== index))
    }
    const onChangePosition = (e,index)=>{
        if(index > position.length) return;

        const formItemsCopy = JSON.parse(JSON.stringify(position))

        formItemsCopy[index].position = e.target.value;
        setPosition(formItemsCopy)

    }

    const submitHandler = (e)=>{
        e.preventDefault()
        let data ={
            company:companyData.company,
            organizations:{department:department,position:position.map(items=>({name:items.position,mac:items.mac}))}
        }
        let params = {
            contents:'organizations',
            type:'create'
        }
        adminService(data,params)
            .then(res=>{
                alert(res.data.msg)
                Store().setCompanyData(res.data.data)
                window.location.replace('/adminPage')
            })
            .catch(err=>{
                alert(err.response.data)
            })

    }

    const [modalOpen, setModalOpen] = useState(false);
    const [modalNum,setModalNum] = useState('')
    const openModal=(e)=>{
        setModalNum(e.target.name)
        setModalOpen(true);
    };
    const closeModal = ()=>{
        setModalOpen(false);
    }




    return(
        <div style={{alignItems:'center',flexDirection:'column',display:'flex',marginLeft:50}}>
            <div style={{width:"100%"}}>
                <TextField
                    onChange={onChangeDepartment}
                    autoFocus
                    required
                    type="text"
                    sx={{marginBottom:1,marginTop:2,width:"90%"}}
                    name="department"
                    label='부서명'
                />

                {position.map((item,index)=>(
                    <div key={index}>
                        <TextField
                            onChange={e=> onChangePosition(e,index)}
                            required
                            value={item.position}
                            disabled={isDepartment === false}
                            sx={{width:"30%",mt:1,mb:1}}
                            id="position"
                            name="position"
                            label={item.label}
                        />
                        {index >= 0 && position.length < 10 && (
                            <>
                                <Button
                                    variant={position[index].macLabel === '기기 재등록' ? "contained":"outlined"}
                                    disabled={isDepartment === false}
                                    name={index}
                                    sx={{
                                        mt:1,
                                        ml:1,
                                        width:"25%",
                                        height:55,
                                        "&.MuiButton-root:hover":{
                                            color:'#008DDC',
                                            backgroundColor:'#c7ebff',
                                            borderColor:'#008DDC'
                                        }}}
                                    onClick={openModal}
                                >
                                    {item.macLabel}
                                </Button>
                                {modalOpen && <>
                                    {sizeType === 'mobile' ? (
                                        <>
                                            <Modals open={modalOpen} close={closeModal} header="기기 등록"
                                                    sizeType="mobile" position={position} setIsMac={setIsMac}
                                                    item={item} setPosition={setPosition} modalNum={modalNum} />
                                        </>
                                    ):(
                                        <>
                                            <Modals open={modalOpen} close={closeModal}
                                                    position={position} setIsMac={setIsMac}
                                                    item={item} setPosition={setPosition}
                                                    modalNum={modalNum} header="기기 등록" />
                                        </>
                                    )}
                                </>}
                            </>
                        )}
                        {index >= 0 && position.length < 10 && (
                            <>
                                <Button
                                    variant="outlined"
                                    disabled={isDepartment === false}
                                    sx={{
                                        mt:1,
                                        ml:1,
                                        width:"10%",
                                        height:55,
                                        "&.MuiButton-root:hover":{
                                            color:'#008DDC',
                                            backgroundColor:'#c7ebff',
                                            borderColor:'#008DDC'
                                        }}}
                                    onClick={addPosition}
                                >
                                    추가
                                </Button>
                            </>
                        )}
                        {index >= 0 && position[index] && (
                            <>
                                <Button
                                    variant="outlined"
                                    disabled={isDepartment === false}
                                    sx={{
                                        mt:1,
                                        ml:1,
                                        width:"10%",
                                        height:55,
                                        "&.MuiButton-root:hover":{
                                            color:'#008DDC',
                                            backgroundColor:'#c7ebff',
                                            borderColor:'#008DDC'
                                        }}}
                                    onClick={()=>delPosition(item.id)}
                                >
                                    삭제
                                </Button>
                            </>
                        )}


                    </div>
                ))}

                <Button
                    disabled={position.findIndex(e=>e.macLabel === '사용기기 등록') !== -1}
                    onClick={submitHandler}
                    variant="contained"
                    type="submit"
                    size="medium"
                    fullWidth
                    sx={{
                        marginTop: 2,
                        width:"90%",
                        height: '45px',
                        border: 3,
                        "&.MuiButton-root:hover": {
                            color: '#008DDC',
                            backgroundColor: '#c7ebff',
                            borderColor: '#008DDC'
                        }
                    }}

                >
                    신규 부서 생성
                </Button>
            </div>

        </div>
    )
}