import {Box, Typography} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import React from "react";


export default function Latest(props){

    const {getData,startLogo,endLogo,startLabel,endLabel,latestDate,startTime,quitTime,workStart,
        workQuit,nowTime,startStateLabel,endStateLabel,startStateLogo,endStateLogo} = props


    return(
        <>
            <div style={{display: 'flex', alignItems: 'center', width: '90%'}}>
                <Box
                    sx={{
                        backgroundColor: 'lightgrey',
                        width: '100%',
                        borderRadius: '1rem',
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                    <br/>

                    {/*<Typography component="h3" variant="h7" sx={{paddingTop: 2}}>*/}
                    {/*    최신 근태정보*/}
                    {/*</Typography><br/>*/}
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            width: 700,
                            height: 50,
                            borderRadius: '1rem',
                            marginBottom: 2,
                            marginRight: 2,
                            paddingRight: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        <Typography component="h4" variant="h8" sx={{paddingLeft: 20}}>
                            오늘 날짜 :
                        </Typography>
                        <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                            {nowTime}
                        </Typography>
                        <Typography component="h4" variant="h8" sx={{paddingLeft: 5}}>
                            최신 업데이트 날짜 :
                        </Typography>
                        <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                            {latestDate}
                        </Typography>
                    </Box>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 2}}>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                width: 330,
                                height: 60,
                                borderRadius: '1rem',
                                marginBottom: 2,
                                marginRight: 2,
                                paddingRight: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}>

                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography component="h4" variant="h7" sx={{paddingLeft: "30%"}}>
                                    출근 상태 :
                                </Typography>
                                <Typography component="h5" variant="h6" color='black'
                                            sx={{paddingLeft: "5%", paddingRight: 2}}>
                                    {startLabel}
                                </Typography>

                                <img alt="No Images" src={startLogo} style={{marginTop: 1, width: '10%'}}/>

                            </div>
                        </Box>

                        <Box
                            sx={{
                                backgroundColor: 'white',
                                width: 330,
                                height: 60,
                                borderRadius: '1rem',
                                marginBottom: 2,
                                marginRight: 2,
                                paddingRight: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Typography component="h4" variant="h7" sx={{paddingLeft: "25%"}}>
                                    퇴근 상태 :
                                </Typography>
                                <Typography component="h5" variant="h6" color='black'
                                            sx={{paddingLeft: "5%", paddingRight: 2}}>
                                    {endLabel}
                                </Typography>
                                <img alt="No Images" src={endLogo} style={{marginTop: 1, width: '10%'}}/>
                            </div>

                        </Box>

                    </div>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 2}}>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                width: 330,
                                height: 60,
                                borderRadius: '1rem',
                                marginBottom: 2,
                                marginRight: 2,
                                paddingRight: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <Typography component="h4" variant="h8" sx={{paddingLeft: 9}}>
                                등록된 출근시간 :
                            </Typography>
                            <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                {workStart}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                backgroundColor: 'white',
                                width: 330,
                                height: 60,
                                borderRadius: '1rem',
                                marginBottom: 2,
                                marginRight: 2,
                                paddingRight: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <Typography component="h4" variant="h8" sx={{paddingLeft: 9}}>
                                등록된 퇴근시간 :
                            </Typography>
                            <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                {workQuit}
                            </Typography>
                        </Box>
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 2}}>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                width: 330,
                                height: 60,
                                borderRadius: '1rem',
                                marginBottom: 2,
                                marginRight: 2,
                                paddingRight: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            {startStateLabel === '출근 시간' ? (
                                <>
                                    <Typography component="h4" variant="h8" sx={{paddingLeft: 12}}>
                                        출근 시간 :
                                    </Typography>
                                    <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                        {startTime}
                                    </Typography>
                                    <img alt="No Images" src={startLogo} style={{marginTop: 1, width: '10%', paddingLeft: 10}}/>
                                </>
                            ) : (
                                <>
                                    <Typography component="h4" variant="h8" sx={{paddingLeft: 5}}>
                                        최근 업데이트 출근 시간 :
                                    </Typography>
                                    <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                        {startTime}
                                    </Typography>
                                    <img alt="No Images" src={startStateLogo} style={{marginTop: 1, width: '10%', paddingLeft: 10}}/>
                                </>
                            )}

                        </Box>

                        <Box
                            sx={{
                                backgroundColor: 'white',
                                width: 330,
                                height: 60,
                                borderRadius: '1rem',
                                marginBottom: 2,
                                marginRight: 2,
                                paddingRight: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            {endStateLabel === '퇴근 시간' ? (
                                <>
                                    <Typography component="h4" variant="h8" sx={{paddingLeft: 10}}>
                                        퇴근 시간 :
                                    </Typography>
                                    <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                        {quitTime}
                                    </Typography>
                                    <img alt="No Images" src={endLogo} style={{marginTop: 1, width: '10%', paddingLeft: 10}}/>
                                </>
                            ):(
                                <>
                                    <Typography component="h4" variant="h8" sx={{paddingLeft: 5}}>
                                        최근 업데이트 퇴근 시간 :
                                    </Typography>
                                    <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                        {quitTime}
                                    </Typography>
                                    <img alt="No Images" src={endStateLogo} style={{marginTop: 1, width: '10%', paddingLeft: 10}}/>
                                </>
                            )}

                        </Box>
                    </div>
                    <br/>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 2}}>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                width: 800,
                                height:400,
                                borderRadius: '1rem',
                                marginBottom: 2,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                            <br/>
                            <Typography component="h5" variant="h6" sx={{margin: 2}}>
                                히스토리 로그
                            </Typography><br/>

                            <TableContainer component={Paper}>
                                <Table sx={{minWidth: 100}} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>날짜</TableCell>
                                            <TableCell align="right">시간</TableCell>
                                            <TableCell align="right">유저아이디</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {getData.map((row) => (
                                            <TableRow
                                                key={row.time}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.date}
                                                </TableCell>
                                                <TableCell align="right">{row.time}</TableCell>
                                                <TableCell align="right">{row.userId}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                    </div>

                </Box>
            </div>
        </>
    )
}