import React, {useContext, useEffect, useState} from 'react'
import Background from '../picture/background.png'
import Store from "../context/store";
import {MainContext} from "../context";
import Login from './Login'
import {Box} from "@mui/material";
import Logo from "../picture/Logo.png";
import Main from "../component/Main"
import {getAttendance, vacationService} from "../api";


export default function Index(){

    const { toggleClose,storeLogin } = useContext(MainContext)

    useEffect(()=>{
        if(storeLogin === true){
            let data = {
                company:Store().getCompanyData().company,
                userData:Store().getLoginData()
            }
            getAttendance(data)
                .then(res=>{
                    Store().setAttendanceData(res.data)
                })
                .catch(err=>{

                })
            let params = {
                service:'getData'
            }
            vacationService(Store().getLoginData(),params)
                .then(res=>{
                    Store().setVacationState(res.data)
                })
                .catch(err=>{

                })
            let param = {
                service:'change',
                checked:'search'
            }
            vacationService(Store().getLoginData(),param)
                .then(res=>{
                    Store().setUserVacation(res.data)
                })
                .catch(err=>{

                })
        }
    },[])


    return(
        <>

            {storeLogin ? (
                <>
                        <div style={{
                            backgroundImage: `url(${Background})`,
                            backgroundSize: "cover",width:'100%'
                        }} onClick={toggleClose}>
                            <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
                                <Box
                                    sx={{
                                        backgroundColor: 'white',
                                        width: '95%',
                                        border: 1,
                                        borderRadius: '2rem',
                                        marginTop: 2,
                                        marginBottom: 5,
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        opacity: 1
                                    }}>
                                    <img alt="No Images" src={Logo} style={{marginTop: 30, width: '20%'}}/>
                                    <br/>
                                    <Main/>
                                </Box>
                            </div>
                        </div>
                </>
            ) : (
                <>
                    <Login/>
                </>
            )}

        </>

    )
}