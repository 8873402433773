import React, {useEffect, useState} from "react";
import Background from "../picture/background.png";
import CompanyLogo from "../picture/new_blaubit.png";
import {Box, Button, Divider, TextField, Typography} from "@mui/material";
import Store from "../context/store";
import {getAttendance, login, selectInfo} from "../api";
import Find from './Find'


export default function Login(){

    let storeLogin = Store().getLogin()

    let findData = Store().getFindData()

    if(storeLogin !== true){
        Store().delItems('loginData')
        Store().delItems('login')
        Store().delItems('agree')
        Store().delItems('modifyInfo')
    }

    useEffect(()=>{
        if(findData !== null){
            setUserId(findData.userId)
            setIsEmail(true)
            if(findData.password !== undefined){
                let data= {
                    userId:findData.userId,
                    password:findData.password
                }
                login(data)
                    .then(res => {
                        Store().setLoginData(res.data.userData)
                        Store().setCompanyData(res.data.companyData)
                        Store().setLogin(true)
                        let attendanceData = {
                            company: res.data.companyData.company,
                            userData: res.data.userData
                        }
                        getAttendance(attendanceData)
                            .then(res1 => {
                                Store().setAttendanceData(res1.data)
                            })
                            .catch(err => {

                            })
                        Store().delItems('findData')
                        window.location.replace('/')
                    })
                    .catch(err=>{
                        alert(JSON.stringify(err.response.data))
                        Store().setLogin(false)
                    })
            }
        }
    },[])


    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");

    const [EmailMessage, setEmailMessage] = useState("")
    const [PwMessage, setPwMessage] = useState("")

    const [isEmail, setIsEmail] = useState(false)
    const [isPw, setIsPw] = useState(false)

    const onChangeEmail = (e) => {
        const currentEmail = e.currentTarget.value;
        setUserId(currentEmail)
        setIsEmail(true)
    };

    const onChangePassword = (e) => {
        const currentPw = e.currentTarget.value;
        setPassword(currentPw);
        setIsPw(true)
    };

    const onSubmitHandler = (e)=>{
        e.preventDefault()
        let data= {
            userId:userId,
            password:password
        }
        login(data)
            .then(res=>{
                Store().setLoginData(res.data.userData)
                Store().setCompanyData(res.data.companyData)
                Store().setLogin(true)
                window.location.replace('/')
            })
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
                Store().setLogin(false)
            })


    }

    const [idModalOpen, setIdModalOpen] = useState(false);
    const [pwModalOpen, setPwModalOpen] = useState(false);
    const [changeOpen,setChangeOpen] =useState(false)

    const idOpenModal=()=>{
        setIdModalOpen(true);
    };
    const pwOpenModal=()=>{
        setPwModalOpen(true);
    };
    const changePwOpenModal=()=>{
        setChangeOpen(true);
    };
    const idCloseModal=()=>{
        setIdModalOpen(false);
    }
    const pwCloseModal=()=>{
        setPwModalOpen(false);
    }
    const changePwCloseModal=()=>{
        setChangeOpen(false);
    }


    useEffect(()=>{
        selectInfo()
            .then(res=>{
                const info = res.data
                Store().setInfoData(info)
            })
    },[])

    return(
        <>
            <div style={{backgroundImage:`url(${Background})`,
                backgroundSize:'cover',paddingBottom:450,width:'100%',height:'100%'}}>
                <img alt="No Images" src={CompanyLogo} style={{width:250,marginLeft:50,marginTop:50}}/>
                <div style={{flexDirection:"column",float:"right"}}>
                    <Typography component="h1" variant="h3" color='white' sx={{marginTop: 25,marginRight:"10%",}}>
                        AWS 기반
                    </Typography>
                    <Typography component="h1" variant="h3" color='white' sx={{marginTop: 2,marginLeft:"20%",}}>
                        출입관리 플랫폼
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            float:"right",
                            marginTop:10,
                            marginRight:25,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            width: '450px',
                            borderRadius:'0.5rem',
                            border:1,
                            borderColor:'grey.500',
                            opacity:0.8,
                        }}><br/><br/>
                        <Typography component="h1" variant="h5" sx={{paddingBottom:2}}>
                            로그인
                        </Typography>
                        <Divider color="#696969" sx={{height: 2, width: '360px'}}></Divider><br/>


                        {findData !== null ? (
                            <>
                                <TextField
                                    value={userId}
                                    margin="normal"
                                    required
                                    sx={{width:360}}
                                    label="ID"
                                    name="userId"
                                    onChange={onChangeEmail}
                                />
                                <TextField
                                    value={password}
                                    margin="normal"
                                    required
                                    autoFocus
                                    sx={{width:360}}
                                    label="Password"
                                    type="password"
                                    onChange={onChangePassword}
                                />
                            </>
                        ):(
                            <>
                                <TextField
                                    value={userId}
                                    margin="normal"
                                    required
                                    sx={{width:360}}
                                    label="ID"
                                    name="userId"
                                    autoFocus
                                    onChange={onChangeEmail}
                                />
                                <TextField
                                    value={password}
                                    margin="normal"
                                    required
                                    sx={{width:360}}
                                    label="Password"
                                    type="password"
                                    onChange={onChangePassword}
                                />
                            </>
                        )}



                        <Button
                            onClick={onSubmitHandler}
                            disabled={ isEmail === false || isPw === false }
                            type="submit"
                            variant="contained"
                            size="large"
                            sx={{ mt: 3, mb: 2,
                                width:360,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            로그인
                        </Button>

                        <div style={{alignItems:'center',display:'flex',margin:10,paddingBottom:10}}>
                            <Button
                                variant="text"
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width:"120px"}}
                                size="large"
                                onClick={idOpenModal}
                                style={{backgroundColor:"white"}}
                            >
                                아이디 찾기
                            </Button>
                            {idModalOpen && <Find open={idModalOpen} close={idCloseModal} header="아이디 찾기" />}

                            <Button
                                variant="text"
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width:"150px"}}
                                size="large"
                                onClick={pwOpenModal}
                                style={{backgroundColor:"white"}}
                            >
                                비밀번호 찾기
                            </Button>

                            {pwModalOpen && <Find open={pwModalOpen} close={pwCloseModal} header="비밀번호 찾기" />}

                            <Button
                                variant="text"
                                sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width:"120px"}}
                                size="large"
                                onClick={changePwOpenModal}
                                style={{backgroundColor:"white"}}
                            >
                                비밀번호 변경
                            </Button>

                            {changeOpen && <Find open={changeOpen} close={changePwCloseModal} header="고객 비밀번호 변경" />}

                        </div>
                    </Box>
                </div>

            </div>
        </>
    )
}