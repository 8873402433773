import React, {useContext, useEffect, useState} from 'react'
import Background from "../../picture/background.png";
import {MainContext} from "../../context";
import {Box, Typography} from "@mui/material";
import Logo from '../../picture/Logo.png'
import Products from '../../picture/background.png'
import Store from "../../context/store";
import {getAttendance, vacationService} from "../../api";
import Sign from "./sign";
import Calender from "./calender";

export default function Index(){

    const { toggleClose,companyLogin } = useContext(MainContext)
    const [monthData,setMonthData] = useState([])
    let [event,setEvent] = useState([])


    let attendanceData = Store().getAttendanceData()
    let vacationData = Store().getVacationState()

    useEffect(() => {
        if(Store().getLogin() === true){
            const timer = setInterval(()=>{


                if(vacationData === null){
                    let params = {
                        service:'getData'
                    }

                    vacationService(Store().getLoginData(),params)
                        .then(res=>{
                            Store().setVacationState(res.data)
                        })
                        .catch(err=>{

                        })
                }

                if(attendanceData === null){
                    let sendData = {
                        company: Store().getCompanyData().company,
                        userData: Store().getLoginData()
                    }
                    getAttendance(sendData)
                        .then(res => {
                            Store().setAttendanceData(res.data)
                            attendanceData = res.data
                            window.location.reload()
                        })
                        .catch(err => {

                        })
                }

                let holidays =[]
                vacationData.map(e=>{
                    if(e.isHoliday === false){
                        if(e.info === '재택근무'){
                            let pushData = {
                                date:e.date,
                                title:e.title,
                                textColor:'white',
                                index:2
                            }
                            holidays.push(pushData)
                        }else{
                            let pushData = {
                                date:e.date,
                                title:e.title,
                                color:'white',
                                textColor:'black',
                                index:2
                            }
                            holidays.push(pushData)
                        }

                    }else{
                        let pushData = {
                            date:e.date,
                            title:e.title,
                            color:'#98E2EC',
                            textColor:'red',
                            index:2
                        }
                        holidays.push(pushData)
                    }
                })
                setEvent([...holidays])
            },1000)

        }
    }, []);



    return(
        <>
            <div style={{
                backgroundImage: `url(${Background})`,
                backgroundSize: "cover",width:'100%'
            }} onClick={toggleClose}>
                <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            width: '95%',
                            border: 1,
                            borderRadius: '2rem',
                            marginTop: 2,
                            marginBottom: 5,
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            opacity: 1
                        }}>
                        <img alt="No Images" src={Logo} style={{marginTop: 30, width: '15%'}}/>
                        <Typography component="h1" variant="h5" sx={{paddingTop: 2}}>
                            휴가 관리
                        </Typography><br/>

                        <Sign management="true" />

                        <Calender event={event} />

                        <br/>
                    </Box>
                    <br/>
                </div>
            </div>
        </>
    )
}