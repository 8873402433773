import React, {useContext} from 'react'
import styled from "styled-components";
import {Typography} from "@mui/material";
import {Desktop, Mobile, Tablet} from "../../Responsive";
import {MainContext} from "../../context";


const Common = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  background-color: whitesmoke;
  flex-direction: column;
`


export default function Footer(){

    const { toggleClose,companyLogin } = useContext(MainContext)

    return(
        <>
            {companyLogin ? (
                <>
                    <Desktop>
                        <Common>
                            <div style={{display: 'flex',
                                alignItems: "center"}} onClick={toggleClose}>
                                <img alt="No Images" src="images/new_blaubit.png"
                                     style={{
                                         alignItems:'center', display:'flex',padding:5,width:250
                                     }}/>
                                <div>
                                    <Typography  component="h5" variant="h7" color="grey" style={{padding:10}}>
                                        대표전화 031-605-7906
                                    </Typography>
                                    <Typography  component="h5" variant="h7" color="grey" style={{padding:10}}>
                                        본사: 경기도 성남시 수정구 창업로 54, 704호,705호
                                    </Typography>
                                </div>
                            </div>
                        </Common>
                    </Desktop>
                    <Tablet>
                        <Common>
                            <div style={{display: 'flex',
                                alignItems: "center",margin:10}} onClick={toggleClose}>
                                <img alt="No Images" src="images/new_blaubit.png"
                                     style={{
                                         alignItems:'center', display:'flex',padding:5,width:250
                                     }}/>
                                <div>
                                    <Typography  component="h5" variant="h7" color="grey" style={{padding:10}}>
                                        대표전화 031-605-7906
                                    </Typography>
                                    <Typography  component="h5" variant="h7" color="grey" style={{padding:10}}>
                                        본사: 경기도 성남시 수정구 창업로 54, 704호,705호
                                    </Typography>
                                </div>
                            </div>
                        </Common>
                    </Tablet>
                    <Mobile>
                        <div style={{display: 'flex',
                            alignItems: "center",flexDirection:'column'}} onClick={toggleClose}>
                            <img alt="No Images" src="images/new_blaubit.png"
                                 style={{
                                     alignItems:'center', display:'flex',padding:5,width:200
                                 }}/>

                            <Typography  component="h5" variant="h7" color="grey" style={{padding:10}}>
                                대표전화 031-605-7906
                            </Typography>
                            <Typography  component="h5" variant="h7" color="grey" style={{padding:10}}>
                                본사: 경기도 성남시 수정구 창업로 54, 704호,705호
                            </Typography>
                        </div>
                    </Mobile>
                </>
            ):(
                <>
                </>
            )}


        </>
    )
}