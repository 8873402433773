import {Button} from "@mui/material";
import React, {useRef, useState} from "react";
import * as XLSX from "xlsx";

import Store from "../../../../../../../context/store";


export default function ExcelUpload(){

    const fileInputInfo = useRef(null);
    const [file,setFile]=useState('')

    const onUploadButton = (e) =>{
        fileInputInfo.current.click();
    }

    const inputChange = (e)=>{
        const input = e.target;
        const reader = new FileReader();
        reader.onload = function (){
            const fileData = reader.result;
            const wb = XLSX.read(fileData,{type: 'binary'});
            wb.SheetNames.forEach(function (sheetName){
                const rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
                let data ={
                    upload:rowObj,
                    company:Store().getCompanyData().company
                }
                let params = {
                    contents:"upload"
                }
                // companyInfoUpdate(data,params)
                //     .then(res=>{
                //         setFile(res.data)
                //         alert("업로드 완료")
                //         window.location.reload()
                //     })
                //     .catch(err=>{
                //         window.location.reload()
                //         alert(JSON.stringify(err.response.data))
                //     })

            })
        };
        reader.readAsBinaryString(input.files[0]);
    }

    return(
        <>
            <Button
                variant="contained"
                size="large"
                sx={{marginRight:2}}
                onClick={onUploadButton}
            >
                대량 생성/수정(Excel)
            </Button>
            <input
                type="file"
                id="excelFile"
                ref={fileInputInfo}
                onChange={inputChange}
                style={{display:"none"}}/>
        </>
    )
}