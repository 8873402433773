import Store from "../../../../../../context/store";
import React, {useEffect, useRef, useState} from "react";
import {Button, Checkbox, FormControlLabel, Typography} from "@mui/material";


export default function Mac(props){

    const {position,item,setPosition,close,modalNum,updateCheck,changeCompanyData} =props

    const companyData = Store().getCompanyData()

    const [checkList, setCheckList] = useState([]);



    const checkAll = (e) => {
        e.target.checked
            ? setCheckList([...companyData.macAddress])
            : setCheckList([])
    }
    const isAllChecked = checkList.length > 0;
    const disabled = !isAllChecked

    const check = (e) => {
        e.target.checked
            ? setCheckList([...checkList, e.target.name])
            : setCheckList(checkList.filter((choice)=> choice !== e.target.name))
    }

    const handleClick = ()=>{
        let filterData =[]
        let input = []


        if(item.id === 0 && position[modalNum].macLabel === '기기 등록'){
            const data = {
                id: item.id,
                department:item.department,
                userId:item.userId,
                name:item.name,
                position: item.position,
                positionList: item.positionList,
                mac: checkList,
                macLabel: '기기 재등록'
            }
            setPosition([position[item.id] = data])
        }else{
            if(item.id === 0 && position[modalNum].macLabel === '사용기기 등록'){
                const data = {
                    id: item.id,
                    position: item.position,
                    mac: checkList,
                    label: item.label,
                    macLabel: '기기 재등록'
                }
                setPosition([position[item.id] = data])

            }else{
                if(updateCheck === 'dataChange'){
                    position.map(e=>{
                        if(e.id !== Number(modalNum)){
                            filterData.push(e)
                        }else{
                            input.push({
                                id:e.id,
                                department: e.department,
                                position:e.position,
                                positionList: e.positionList,
                                userId:e.userId,
                                mac:checkList,
                                name:e.name,
                                macLabel: '기기 재등록'
                            })
                        }
                    })
                    setPosition([...filterData,...input].sort((a,b)=>a.id - b.id))
                }else{
                    if(updateCheck === 'select'){
                        position.map(e=>{
                            if(e.id !== Number(modalNum)){
                                filterData.push(e)
                            }else{
                                input.push({
                                    id:e.id,
                                    department: e.department,
                                    positionList:e.positionList,
                                    basicPosition: e.basicPosition,
                                    position:e.position,
                                    mac:checkList,
                                    basicMac:e.basicMac,
                                    macLabel: '기기 재등록'
                                })
                            }
                        })
                        setPosition([...filterData,...input].sort((a,b)=>a.id - b.id))
                    }else{
                        position.map(e=>{
                            if(e.id !== Number(modalNum)){
                                filterData.push(e)
                            }else{
                                input.push({
                                    id:e.id,
                                    position:e.position,
                                    mac:checkList,
                                    label:e.label,
                                    macLabel: '기기 재등록'
                                })
                            }
                        })
                        setPosition([...filterData,...input].sort((a,b)=>a.id - b.id))
                    }

                }
            }
        }
        close()
    }

    useEffect(()=>{
        if(position[modalNum].macLabel === '기기 재등록'){
            setCheckList([...position[modalNum].mac])
        }
        if(updateCheck === 'select'){
            setCheckList([...position[modalNum].mac])
        }
        if(updateCheck === 'department'){
            setCheckList([...position[modalNum].mac])
        }
        if(updateCheck === 'dataChange'){
            if(position[modalNum].macLabel === '기기 재등록'){
                setCheckList([...position[modalNum].mac])
            }else{
                changeCompanyData.organizations.map(e=>{
                    if(e.department === item.department){
                        e.position.map(items=>{
                            if(items.name === item.position){
                                setCheckList(items.mac)
                            }
                        })
                    }
                })
            }
        }


    },[])


    return(
        <>
            <div style={{alignItems:'center',flexDirection:'column',display:'flex',marginLeft:50}}>
                <div style={{width:"100%"}}>
                            <FormControlLabel
                                control={<Checkbox
                                    style={{fontWeight:2}}
                                    margin="normal"
                                    required
                                    name="all"
                                    onChange={checkAll}
                                    checked={checkList.length === companyData.macAddress.length ? true : false}
                                    color="primary"/>}
                                label={<Typography variant='body2' >전체 선택</Typography>}
                            /><br/><br/>
                            {companyData.macAddress.map((item)=>(
                                <div key={item}>
                                    <FormControlLabel
                                        control={<Checkbox color="primary"
                                                           margin="normal"
                                                           required
                                                           name={item}
                                                           onChange={check}
                                                           checked={checkList.includes(item) ? true : false}/>}
                                        label={<Typography variant='body2' >{item}</Typography>}
                                    /><br/><br/>
                                </div>
                            ))}

                            <Button disabled={disabled}
                                    autoFocus
                                    type="submit"
                                    onClick={handleClick}
                                    required
                                    sx={{
                                        width:"90%"
                                    }}
                                    style={
                                        disabled
                                            ?{backgroundColor:'#859594'}
                                            :{backgroundColor:'#1e90ff'}
                                    }
                            >
                                <Typography variant='body2' color="primary.contrastText">
                                    기기 등록</Typography>
                            </Button><br/>

                </div>
            </div>
        </>
    )
}