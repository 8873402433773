import Logo from "../../../picture/Logo.png";
import {Button, FormHelperText, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {authNumCheck, findService, SMSService} from "../../../api";
import styled from "styled-components";
import Store from "../../../context/store";


const FormHelperTextsRED = styled(FormHelperText)`
  width: 100%;
  padding-left: 30%;
  font-weight: 700 !important;
  color: #d32f2f !important;
`;
const FormHelperTextsBlue = styled(FormHelperText)`
  width: 100%;
  padding-left: 30%;
  font-weight: 700 !important;
  color: #008DDC !important;
`;

export default function FindId(){

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [authNum, setAuthNum] = useState('')

    const [NameMessage, setNameMessage] = useState("")
    const [PhoneMessage, setPhoneMessage] = useState("")

    const [isName, setIsName] = useState(false)
    const [isPhone,setIsPhone] = useState(false)
    const [isAuthNum, setIsAuthNum]= useState(false)
    const [isSendAuth, setIsSendAuth]= useState(false)
    const [complete, setComplete] = useState(false)

    const [authTime,setAuthTime] = useState(180)
    const [timerStart,setTimerStart] = useState(false)
    const [timeLimit,setTimeLimit] = useState('')

    const onChangeName = (e) => {
        const currentName = e.currentTarget.value;
        setName(currentName)

        const nameRegExp = /^[가-힣a-zA-Z]+$/;

        if (!nameRegExp.test(currentName) || name.length<1) {
            setNameMessage('이름을 두 글자 이상 입력하세요!');
            setIsName(false);
        } else {
            setNameMessage('');
            setIsName(true);
        }
    }

    const onChangePhone = (getNumber) => {
        const currentPhone = getNumber;
        setPhone(currentPhone);

        const phoneRegExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

        if (!phoneRegExp.test(currentPhone)) {
            setPhoneMessage("핸드폰 번호를 입력해 주세요!");
            setIsPhone(false);
        } else {
            setPhoneMessage("");
            setIsPhone(true);
        }
    };
    const addHyphen = (e) => {
        const currentNumber = e.currentTarget.value;
        setPhone(currentNumber);
        if (currentNumber.length === 3 || currentNumber.length === 8) {
            setPhone(currentNumber + "-");
            onChangePhone(currentNumber + "-");
        } else {
            onChangePhone(currentNumber);
        }
    };

    const sendAuthSMS =(e)=>{
        e.preventDefault()

        let data = {
            phone:phone,
            phoneSubject:'아이디 찾기'
        }
            setIsSendAuth(true)

        SMSService(data)
                .then((res)=>{
                    alert(res.data)
                    setIsAuthNum(true)
                    setTimerStart(true)
                    setAuthTime(180)
                })
                .catch(function (err){
                    alert(err.response.data)
                    setIsAuthNum(false)
                    setTimerStart(false)
                })



    }

    const onChangeAuthNum = (e) =>{
        setAuthNum(e.target.value)
        setIsAuthNum(true)
    }

    const onClickAuthNumCheck = (e)=>{
        let data ={
            authNum:authNum
        }
        authNumCheck(data)
            .then(res=>{
                alert(res.data)
                setComplete(true)
                setTimeLimit('인증완료')
            })
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
                setComplete(false)
            })

    }

    const onSubmitHandler = (e)=>{
        e.preventDefault()
        let data = {
            name:name,
            phone:phone,
        }
        let params = {
            select:'Id'
        }

        findService(data,params)
            .then(res=>{
                alert(`[${res.data.company}] ${res.data.name} 담당자 님의 아이디는 ${res.data.userId} 입니다.`)
                Store().setFindData(res.data)
                window.location.replace('/')
            })
            .catch(err=>{
                console.log(JSON.stringify(err.response.data))
            })

    }

    useEffect(()=>{
        if(isSendAuth === true){
            const id = setInterval(() => {
                // 타이머 숫자가 하나씩 줄어들도록
                setAuthTime((count) => count - 1);
                const Min = Math.floor(authTime / 60);
                let Sec = Math.floor(authTime % 60);
                if(Sec < 10){
                    Sec= '0'+Sec
                }
                if(Min < 1 && Sec < 1 ){
                    Sec = '00'
                }
                setTimeLimit(`인증 남은시간 :${Min}:${Sec}`)

            }, 1000);

            if(complete === true){
                clearInterval(id)
                setTimerStart(false)
                setTimeLimit('인증완료')
            }


            // 0이 되면 카운트가 멈춤
            if(authTime === 0) {
                clearInterval(id);
                setTimerStart(false)
                setTimeLimit('인증시간 초과')
            }
            if(authTime < 1){
                clearInterval(id);
                setTimerStart(false)
                setTimeLimit('인증시간 초과')
            }

            return () => clearInterval(id);
            // 카운트 변수가 바뀔때마다 useEffecct 실행
        }

    },[authTime])

    return(
        <>
            <div style={{display: "flex", alignItems: 'center', flexDirection: 'column'}}>
                <img alt="No Images" src={Logo} style={{width: 220}}/><br/>
                <Typography component="h2" variant="h6" sx={{paddingBottom: 2}}>
                    아이디 찾기
                </Typography>
                <TextField
                    onChange={onChangeName}
                    autoFocus
                    disabled={isSendAuth === true}
                    required
                    value={name}
                    sx={{width: '70%', margin: 1}}
                    id="name"
                    name="name"
                    label="성함"
                    error={NameMessage === '이름을 두 글자 이상 입력하세요!'}
                />

                <FormHelperTextsRED>{NameMessage}</FormHelperTextsRED>

                <div style={{width: '73%'}}>
                    <TextField
                        required
                        value={phone}
                        disabled={isSendAuth === true || isName === false}
                        sx={{width: "64%", margin: 1}}
                        type="text"
                        onChange={addHyphen}
                        name="phone"
                        label="전화번호( - 빼고 기입)"
                        error={PhoneMessage === '핸드폰 번호를 입력해 주세요!'}
                    />


                    {isAuthNum || timeLimit === '인증시간 초과' ? (
                        <>
                            <Button
                                onClick={sendAuthSMS}
                                variant="contained"
                                type="submit"
                                size="medium"
                                sx={{
                                    marginTop: 1,
                                    width: "30%",
                                    height: '56px',
                                    border: 3,
                                    "&.MuiButton-root:hover": {
                                        color: '#008DDC',
                                        backgroundColor: '#c7ebff',
                                        borderColor: '#008DDC'
                                    }
                                }}
                                disabled={isName === false || isPhone === false || complete === true}
                            >

                                <Typography component="h3" variant="h7">
                                    {timeLimit === '인증시간 초과' ? (
                                        <>
                                            재인증
                                        </>
                                    ) : (
                                        <>
                                            재전송
                                        </>
                                    )}
                                </Typography>
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={sendAuthSMS}
                                variant="contained"
                                type="submit"
                                size="medium"
                                sx={{
                                    marginTop: 1,
                                    width: "30%",
                                    height: '56px',
                                    border: 3,
                                    "&.MuiButton-root:hover": {
                                        color: '#008DDC',
                                        backgroundColor: '#c7ebff',
                                        borderColor: '#008DDC'
                                    }
                                }}
                                disabled={isName === false || isPhone === false}
                            >
                                <Typography component="h3" variant="h7">
                                    인증받기
                                </Typography>
                            </Button>
                        </>

                    )}
                </div>
                <FormHelperTextsRED>{PhoneMessage}</FormHelperTextsRED>

                <div style={{width: '73%'}}>
                    <TextField
                        disabled={isSendAuth === false || complete === true}
                        value={authNum}
                        required
                        sx={{width: "64%", margin: 1}}
                        id="sign"
                        name="sign"
                        label="인증번호"
                        onChange={onChangeAuthNum}
                    />
                    <Button
                        onClick={onClickAuthNumCheck}
                        variant="contained"
                        type="submit"
                        size="medium"
                        sx={{
                            marginTop: 1,
                            width: "30%",
                            height: '56px',
                            border: 3,
                            "&.MuiButton-root:hover": {
                                color: '#008DDC',
                                backgroundColor: '#c7ebff',
                                borderColor: '#008DDC'
                            }
                        }}
                        disabled={isName === false || isPhone === false ||
                            timeLimit === '인증시간 초과' || complete === true || timerStart === false}
                    >
                        <Typography component="h3" variant="h7">
                            인증확인
                        </Typography>
                    </Button>
                </div>
                {isSendAuth ? (
                    <>
                        {complete ? (
                            <>
                                <FormHelperTextsBlue>{timeLimit}</FormHelperTextsBlue>
                            </>
                        ) : (
                            <>
                                <FormHelperTextsRED>{timeLimit}</FormHelperTextsRED>
                            </>
                        )}
                    </>
                ) : (
                    <>
                    </>
                )}

                <Button
                    onClick={onSubmitHandler}
                    type="submit"
                    variant="contained"
                    sx={{
                        mt: 2, mb: 1,
                        width: "70%",
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    size="large"
                    disabled={complete === false}
                >
                    아이디 찾기
                </Button>
            </div>
        </>
    )
}