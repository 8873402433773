import Logo from "../../../picture/Logo.png";
import {Button, FormHelperText, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import {findService} from "../../../api";
import Store from "../../../context/store";
import styled from "styled-components";

const FormHelperTextsRED = styled(FormHelperText)`
  width: 100%;
  padding-left: 30%;
  font-weight: 700 !important;
  color: #d32f2f !important;
`;

export default function Main(){

    const findData = Store().getFindData()

    const [password, setPassword] = useState("");
    const [PwConfirm, setPwConfirm] = useState("");
    const [PwMessage, setPwMessage] = useState("")
    const [PwConfirmMessage, setPwConfirmMessage] =useState("")
    const [isPw, setIsPw] = useState(false)
    const [isPwConfirm, setIsPwConfirm] = useState(false)
    const onChangePassword = (e) => {
        const currentPw = e.currentTarget.value;
        setPassword(currentPw);

        const passwordRegExp =
            /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
        if (!passwordRegExp.test(currentPw)) {
            setPwMessage(
                "숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!"
            );
            setIsPw(false);
        } else {
            setPwMessage("");
            setIsPw(true);
        }
    };
    const onChangePasswordConfirm = (e) => {
        const currentPasswordConfirm = e.currentTarget.value;
        setPwConfirm(currentPasswordConfirm);

        if (password !== currentPasswordConfirm) {
            setPwConfirmMessage("비밀번호가 일치하지 않습니다.");
            setIsPwConfirm(false);
        } else {
            setPwConfirmMessage("");
            setIsPwConfirm(true);
        }
    };

    const onSubmitPwHandler = (e)=>{
        let data = {
            company:findData.company,
            userId:findData.userId,
            phone:findData.phone,
            name:findData.name,
            pw:password
        }
        let params = {
            select:'Change'
        }

        findService(data,params)
            .then(res=>{
                alert(`[${res.data.company}] ${res.data.name} 님의 비밀번호가 변경되었습니다. 자동으로 로그인 됩니다.`)
                Store().setFindData(res.data)
                window.location.replace('/')
            })
            .catch(err=>{
                console.log(err)
            })

    }



    return(
        <>
            <div style={{display: "flex", alignItems: 'center', flexDirection: 'column'}}>
                <img alt="No Images" src={Logo} style={{width: 220}}/><br/>
                <Typography component="h2" variant="h6" sx={{paddingBottom: 2}}>
                    비밀번호 변경
                </Typography>

                <TextField
                    onChange={onChangePassword}
                    required
                    sx={{width:'70%', margin:1}}
                    type="password"
                    value={password}
                    name="password"
                    label="비밀번호 (숫자+영문자+특수문자 8자리 이상)"
                    error={PwMessage === '숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요!'}
                />

                <FormHelperTextsRED>{PwMessage}</FormHelperTextsRED>

                <TextField
                    required
                    sx={{width:'70%', margin:1}}
                    value={PwConfirm}
                    disabled={isPw === false}
                    onChange={onChangePasswordConfirm}
                    type="password"
                    name="PwConfirm"
                    label="비밀번호 재입력"
                    error={PwConfirmMessage === '비밀번호가 일치하지 않습니다.'}
                />

                <FormHelperTextsRED>{PwConfirmMessage}</FormHelperTextsRED>

                <Button
                    onClick={onSubmitPwHandler}
                    type="submit"
                    variant="contained"
                    sx={{
                        mt: 2, mb: 1,
                        width: "70%",
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    size="large"
                    disabled={isPwConfirm === false}
                >
                    비밀번호 변경하기
                </Button>
            </div>
        </>
    )
}