import React, {useContext, useEffect, useState} from 'react'
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import {Box, Typography,Button} from "@mui/material";
import Store from "../../context/store";
import {MainContext} from "../../context";
import TextField from "@mui/material/TextField";
import Textarea from "@mui/joy/Textarea";
import Logo from '../../picture/Logo.png'
import Background from '../../picture/background.png'

export default function Index(){

    const { toggleClose,storeLogin } = useContext(MainContext)

    const companyData = Store().getCompanyData()
    console.log(companyData)


    const [userId,setUserId] = useState('')
    const [phone, setPhone] = useState('')

    const [contact,setContact] =useState('')
    const [answer, setAnswer] = useState('')
    const [infoContent,setInfoContent]=useState('')

    const [EmailMessage, setEmailMessage] = useState("")
    const [PhoneMessage, setPhoneMessage] = useState("")


    const [isEmail, setIsEmail] = useState(false)
    const [isPhone,setIsPhone] = useState(false)

    const [isContact,setIsContact] = useState(false)
    const [isAnswer,setIsAnswer] = useState(false)
    const [isEmailAnswer,setIsEmailAnswer]=useState(false)
    const [isBasic,setIsBasic] = useState(false)
    const [isInfoContent,setIsInfoContent]=useState(false)
    const [isAdmin,setIsAdmin] = useState(false)

    const [isLogin,setIsLogin] = useState(false)

    const contactList=['서비스 문의','상품 문의','제품 불량 및 A/S','기타문의']
    const answerList=['Email로 답변받기', '핸드폰 문자로 답변받기']








    const onChangeContact = (e)=>{
        const currentContact = e.target.value;
        setContact(e.target.value);

        if(currentContact === '서비스 문의'){
            setContact(currentContact)
            setIsContact(true)
            setInfoContent('해당 서비스 명: \n' +
                '\n문의 내용: ')
        }else if(currentContact === '상품 문의'){
            setContact(currentContact)
            setIsContact(true)
            setInfoContent('모델 명: \n' +
                '\n문의 내용: ')
        }else if(currentContact === '제품 불량 및 A/S'){
            setContact(currentContact)
            setIsContact(true)
            setInfoContent('해당 서비스 명: \n' +
                '\n모델 명: \n' +
                '\n문의 내용: ')
        }else if(currentContact === '기타문의'){
            setContact(currentContact)
            setIsContact(true)
            setInfoContent('문의 내용: ')
        }


    }

    const onChangeAnswer =(e)=>{
        const currentAnswer = e.target.value
        setAnswer(currentAnswer)
        if(currentAnswer === 'Email로 답변받기'){
            setAnswer(currentAnswer)
            setIsAnswer(true)
            setIsEmailAnswer(true)
            setIsBasic(true)
            if(Store().getLogin() === 'true'){
                const loginData = Store().getLoginData()
                setIsLogin(true)
                setIsEmail(true)
                setIsPhone(false)
                setPhone('')
                setUserId(loginData.userId)
            }else{
                if(isAdmin === true){
                    setIsLogin(true)
                    setIsEmail(true)
                    setIsPhone(false)
                    setPhone('')
                    companyData.responsibility.map(res=>{
                        if(res.companyId === companyData.loginId){
                            setUserId(res.email)
                        }
                    })
                }
            }

        }else if(currentAnswer === '핸드폰 문자로 답변받기'){
            setAnswer(currentAnswer)
            setIsAnswer(true)
            setIsEmailAnswer(false)
            setIsBasic(true)
            if(Store().getLogin() === 'true'){
                const loginData = Store().getLoginData()
                setIsLogin(true)
                setIsPhone(true)
                setIsEmail(false)
                setUserId('')
                setPhone(loginData.phone)
            }else{
                if(isAdmin === true){
                    setIsLogin(true)
                    setIsPhone(true)
                    setIsEmail(false)
                    setUserId('')
                    companyData.responsibility.map(res=>{
                        if(res.companyId === companyData.loginId){
                            setPhone(res.phone)
                        }
                    })
                }
            }
        }

    }


    const onChangeEmail = (e) => {
        const currentEmail = e.currentTarget.value;
        setUserId(currentEmail)
        const emailRegExp =
            /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;

        if (!emailRegExp.test(currentEmail)) {
            setEmailMessage("이메일의 형식이 올바르지 않습니다!");
            setIsEmail(false);
        } else {
            setEmailMessage("");
            setIsEmail(true);
        }
    };
    const onChangePhone = (getNumber) => {
        const currentPhone = getNumber;
        setPhone(currentPhone);
        const phoneRegExp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

        if (!phoneRegExp.test(currentPhone)) {
            setPhoneMessage("핸드폰 번호를 입력해 주세요!");
            setIsPhone(false);
        } else {
            setPhoneMessage("");
            setIsPhone(true);
        }
    };
    const addHyphen = (e) => {
        const currentNumber = e.currentTarget.value;
        setPhone(currentNumber);
        if (currentNumber.length === 3 || currentNumber.length === 8) {
            setPhone(currentNumber + "-");
            onChangePhone(currentNumber + "-");
        } else {
            onChangePhone(currentNumber);
        }
    };

    const onChangeContent= (e)=>{
        setInfoContent(e.target.value)
        setIsInfoContent(true)
    }

    const onClickSend = (e)=>{
        e.preventDefault()
        let data = {
            contact:contact,
            answer:answer,
            userId:userId,
            phone:phone,
            infoContent:infoContent
        }

        // customerService(data)
        //     .then(res=>{
        //         alert(res.data)
        //         window.location.reload()
        //     })
        //     .catch(err=>{
        //         alert(JSON.stringify(err.response.data))
        //     })



    }

    useEffect(()=>{
        if(companyData.admin === true){
            setIsAdmin(true)
        }

    },[])

    return(
        <>
            <div style={{backgroundImage:`url(${Background})`,
                backgroundSize:'cover',width:'100%'}} onClick={toggleClose}>
                <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
                    <Box
                        sx={{
                            backgroundColor:'white',
                            width:"70%",
                            border:1,
                            borderRadius:'2rem',
                            marginTop:15,
                            marginBottom:2,
                            display:'flex',
                            alignItems:'center',
                            flexDirection:'column',
                            opacity:0.95
                        }}>
                        <img alt="No Images" src={Logo} style={{width:250,paddingRight:30,paddingTop:20}}/>
                        <Typography component="h1" variant="h5" sx={{paddingTop:3}}>
                            고객센터
                        </Typography><br/>
                        <Typography color='grey' component="h2" variant="h8" sx={{marginTop: 1}}>
                            1:1 문의하기 서비스 입니다.
                        </Typography>
                        <Typography color='grey' component="h4" variant="h7" sx={{marginTop: 2}}>
                            최대한 빠르게 검토 후 연락드리겠습니다.
                        </Typography><br/>
                        <div style={{alignItems: 'center', display: 'flex', paddingBottom:20,width:'90%'}}>
                            <FormControl sx={{marginTop: 3, width: '100%'}}>
                                <InputLabel>문의유형</InputLabel>
                                <Select
                                    autoFocus
                                    value={contact}
                                    name="contact"
                                    onChange={onChangeContact}
                                    input={<OutlinedInput label="name"/>}
                                >
                                    {contactList.map((item) => (
                                        <MenuItem
                                            key={item}
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{marginTop: 3, width: '100%', marginLeft:2}}>
                                <InputLabel>답변유형</InputLabel>
                                <Select
                                    value={answer}
                                    name="answer"
                                    disabled={isContact === false}
                                    onChange={onChangeAnswer}
                                    input={<OutlinedInput label="name"/>}
                                >
                                    {answerList.map((item) => (
                                        <MenuItem
                                            key={item}
                                            value={item}

                                        >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {isBasic === true ? (
                                <>
                                    {isEmailAnswer === true ? (
                                        <>
                                            <TextField
                                                value={userId}
                                                margin="normal"
                                                disabled={storeLogin === true || isAdmin === true}
                                                sx={{marginTop:4,width:'100%',marginLeft:2}}
                                                label="이메일 주소"
                                                name="sendInfo"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </>
                                    ):(
                                        <>
                                            <TextField
                                                value={phone}
                                                margin="normal"
                                                disabled={storeLogin === true || isAdmin === true}
                                                sx={{marginTop:4,width:'100%',marginLeft:2}}
                                                label="전화번호"
                                                name="sendInfo"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />

                                        </>
                                    )}
                                </>
                            ):(
                                <>
                                    <TextField
                                        defaultValue="이메일 또는 전화번호"
                                        margin="normal"
                                        disabled={isAnswer === false}
                                        onChange={addHyphen}
                                        sx={{marginTop:4,width:'100%',marginLeft:2}}
                                        label="답변 받을 이메일 또는 전화번호"
                                        name="sendInfo"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </>
                            )}


                        </div>

                        {isEmailAnswer === true ? (
                            <>
                                <Textarea
                                    value={infoContent}
                                    disabled={isEmail === false}
                                    placeholder="문의하실 내용을 기입해주세요."
                                    sx={{marginTop:1,width:'90%',height:200, border:1,
                                        borderColor:'grey.500',marginBottom:2}}
                                    name="sendInfo"
                                    onChange={onChangeContent}
                                />
                            </>
                        ):(
                            <>
                                <Textarea
                                    value={infoContent}
                                    disabled={isPhone === false}
                                    placeholder="문의하실 내용을 기입해주세요."
                                    sx={{marginTop:1,width:'90%',height:200, border:1,
                                        borderColor:'grey.500',marginBottom:2}}
                                    name="sendInfo"
                                    onChange={onChangeContent}
                                />
                            </>
                        )}



                        <FormControl sx={{width: '40%'}}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                onClick={onClickSend}
                                sx={{ mt: 2, mb: 3 ,
                                    justifyContent: 'center',
                                    alignItems: 'center' }}
                                size="large"
                                disabled={ isInfoContent===false ||
                                    isAnswer ===false || isContact === false}
                            >
                                문의하기
                            </Button><br/>
                        </FormControl>
                    </Box>
                </div>
            </div>
        </>
    )
}