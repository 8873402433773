import Create from "./create";
import DepartmentUpdate from "./update/department"
import IndividualUpdate from "./update/individual"
import DepartmentName from "./update/departmentName";
import React from "react";
import ChangeSelect from "./update/department/changeSelect";
import Mac from "./mac";
import Affiliation from "./affiliation";
import DepartmentDelete from "./delete/department";
import Change from "./delete/change";



export default function Modal(props){

    const {open, close, header,sizeType,data,companyData,userData,department,position,item,setPosition,setIsMac,
        updateCheck,modalNum,changeCompanyData,selectedData } = props;

    return (
        <>
            <div className={open ? 'openModal modal' : 'modal'}>
                {open ? (
                    <>
                        {sizeType === 'mobile' ? (
                            <>
                                <sectionRegisterMobile style={{marginTop: 50}}>
                                    <header>
                                        {header}
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                    </header>
                                    <main style={{alignItems: "center"}}>
                                        {header === '신규 부서 생성' ? (
                                            <>
                                                <Create
                                                    style={{alignItems: "center"}} sizeType="mobile"/>
                                            </>
                                        ) : (
                                            <>
                                                {header === '기기 등록' ? (
                                                    <>
                                                        <Mac style={{alignItems: "center"}}
                                                             sizeType="mobile" close={close} position={position}
                                                             setIsMac={setIsMac}
                                                             item={item} setPosition={setPosition} modalNum={modalNum}
                                                             updateCheck={updateCheck}
                                                             changeCompanyData={changeCompanyData}/>
                                                    </>
                                                ) : (
                                                    <>
                                                        {header === "포지션 & 기기 선택" ? (
                                                            <>
                                                                <ChangeSelect style={{alignItems: "center"}}
                                                                              companyData={companyData}
                                                                              userData={userData}
                                                                              department={department}
                                                                              sizeType="mobile"/>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {header === "부서 별 수정" ? (
                                                                    <>
                                                                        <DepartmentUpdate style={{alignItems: "center"}}
                                                                                          data={data}
                                                                                          sizeType="mobile"/>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {header === '부서명 변경' ? (
                                                                            <>
                                                                                <DepartmentName
                                                                                    style={{alignItems: "center"}}
                                                                                    sizeType="mobile"/>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {header === '유저 소속변경' ? (
                                                                                    <>
                                                                                        <Affiliation userData={userData}
                                                                                                     companyData={companyData}
                                                                                                     selectedData={selectedData}/>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {header === '부서 삭제' ? (
                                                                                            <>
                                                                                                <DepartmentDelete
                                                                                                    style={{alignItems: "center"}}
                                                                                                    sizeType="mobile"/>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {header === '부서 삭제 전 유저 소속변경' ? (
                                                                                                    <>
                                                                                                        <Change style={{alignItems: "center"}}
                                                                                                                sizeType="mobile"
                                                                                                                userData={userData}
                                                                                                                companyData={companyData}
                                                                                                                department={department}/>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <IndividualUpdate
                                                                                                            style={{alignItems: "center"}}
                                                                                                            data={data}
                                                                                                            sizeType="mobile"/>
                                                                                                    </>
                                                                                                )}

                                                                                            </>
                                                                                        )}

                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}


                                            </>
                                        )}
                                    </main>

                                    <footer>
                                        <button className="close" onClick={close}>
                                            닫기
                                        </button>
                                    </footer>
                                </sectionRegisterMobile>
                            </>
                        ) : (
                            <>
                                <section style={{marginTop: 50}}>
                                    <header>
                                        {header}
                                        <button className="close" onClick={close}>
                                            &times;
                                        </button>
                                    </header>
                                    <main style={{alignItems: "center"}}>
                                        {header === '신규 부서 생성' ? (
                                            <>
                                                <Create
                                                    style={{alignItems: "center"}}/>
                                            </>
                                        ) : (
                                            <>
                                                {header === '기기 등록' ? (
                                                    <>
                                                        <Mac style={{alignItems: "center"}}
                                                             close={close} position={position} setIsMac={setIsMac}
                                                             item={item} setPosition={setPosition} modalNum={modalNum}
                                                             updateCheck={updateCheck}
                                                             changeCompanyData={changeCompanyData}/>
                                                    </>
                                                ) : (
                                                    <>
                                                        {header === "포지션 & 기기 선택" ? (
                                                            <>
                                                                <ChangeSelect style={{alignItems: "center"}}
                                                                              companyData={companyData}
                                                                              userData={userData}/>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {header === "부서 별 수정" ? (
                                                                    <>
                                                                        <DepartmentUpdate style={{alignItems: "center"}}
                                                                                          data={data}/>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {header === '부서명 변경' ? (
                                                                            <>
                                                                                <DepartmentName
                                                                                    style={{alignItems: "center"}}/>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {header === '유저 소속변경' ? (
                                                                                    <>
                                                                                        <Affiliation userData={userData}
                                                                                                     companyData={companyData}
                                                                                                     selectedData={selectedData}/>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {header === '부서 삭제' ? (
                                                                                            <>
                                                                                                <DepartmentDelete
                                                                                                    style={{alignItems: "center"}}/>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {header === '부서 삭제 전 유저 소속변경' ? (
                                                                                                    <>
                                                                                                        <Change style={{alignItems: "center"}}
                                                                                                                userData={userData}
                                                                                                                companyData={companyData}
                                                                                                                department={department}/>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <IndividualUpdate
                                                                                                            style={{alignItems: "center"}}
                                                                                                            data={data}
                                                                                                        />
                                                                                                    </>
                                                                                                )}

                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}


                                            </>
                                        )}

                                    </main>
                                    <footer>
                                        <button className="close" onClick={close}>
                                            닫기
                                        </button>
                                    </footer>
                                </section>
                            </>
                        )}

                    </>
                ) : null}
            </div>
        </>
    );
}
