import {Button} from "@mui/material";
import React, {useState} from "react";
import Modal from "../../../../../modals/admin";


export default function Index(props){

    const {selectedData, sizeType}=props

    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }


    return(
        <>
            <Button
                variant="contained"
                size="large"
                onClick={openModal}
                sx={{marginRight:2}}
                disabled={selectedData.length < 1}
            >
                선택 수정
            </Button>
            {modalOpen &&
                <>
                    {sizeType === 'mobile' ? (
                        <>
                            <Modal open={modalOpen} close={closeModal} header="선택 정보수정" data={selectedData}
                                             sizeType="mobile" />
                        </>
                    ):(
                        <>
                            <Modal open={modalOpen} close={closeModal} header="선택 정보수정" data={selectedData} />
                        </>
                    )}
                    </>
                }
        </>
    )
}