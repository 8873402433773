import React, {useEffect, useState} from "react";
import moment from "moment-timezone";
import {Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import Store from "../../../../../../context/store";
import {register} from "../../../../../../api";


export default function DetailCreate(props){

    const { data } = props

    const now = new Date();
    const open = moment().tz('Asia/Seoul')
    const formYear = open.format(`YYYY`)

    const today = open.format('YYYY-MM-DD')

    const [breaks,setBreaks] = useState({
        year:'',
        month:'',
        family:'',
        maternity:''
    })

    const [form, setForm] = useState({
        year: formYear,
        month: today.split('-')[1],
        day: today.split('-')[2],
    });
    let joinDate = `${form.year}-${form.month}-${form.day}`

    const [attendance, setAttendance] = useState({
        hours: "00",
        min: "00",
    });
    const [quit, setQuit] = useState({
        hours: "00",
        min: "00",
    });
    const [startLunch, setStartLunch] = useState({
        hours: "00",
        min: "00",
    });
    const [endLunch, setEndLunch] = useState({
        hours: "00",
        min: "00",
    });


    let years = [];
    for (let y = now.getFullYear(); y >= 1900; y -= 1) {
        years.push(y);
    }

    let month = [];
    for (let m = 1; m <= 12; m += 1) {
        if (m < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 월에 0을 붙혀준다
            month.push("0" + m.toString());
        } else {
            month.push(m.toString());
        }
    }
    let days = [];
    let date = new Date(form.year, form.month, 0).getDate();
    for (let d = 1; d <= date; d += 1) {
        if (d < 10) {
            // 날짜가 2자리로 나타나야 했기 때문에 1자리 일에 0을 붙혀준다
            days.push("0" + d.toString());
        } else {
            days.push(d.toString());
        }
    }

    let hours =[];

    for (let m = 0; m <= 23; m += 1) {
        if (m < 10) {
            hours.push("0" + m.toString());
        } else {
            hours.push(m.toString());
        }
    }

    let minutes =[];

    for (let m = 0; m <= 59; m += 1) {
        if (m < 10) {
            minutes.push("0" + m.toString());
        } else {
            minutes.push(m.toString());
        }
    }



    const [yearState,setYearState] =useState(false)
    const [monthState,setMonthState] =useState(false)
    const [familyState,setFamilyState] =useState(false)
    const [maternityState,setMaternityState] =useState(false)

    const onChangeBreak = (e) =>{
        const current = e.target.value
        const targetId = e.target.id
        const check = /^[0-9]*$/;

        if(targetId === 'year'){
            if(check.test(current)){
                setBreaks({...breaks, year: current})
                setYearState(true)
            }else{
                setYearState(false)
            }

        }
        if(targetId === 'month'){
            if(check.test(current)){
                setBreaks({...breaks, month: current})
                setMonthState(true)
            }else{
                setMonthState(false)
            }

        }
        if(targetId === 'family'){
            if(check.test(current)){
                setFamilyState(true)
                setBreaks({...breaks, family: current})
            }else{
                setFamilyState(false)
            }

        }
        if(targetId === 'maternity'){
            if(check.test(current)){
                setMaternityState(true)
                setBreaks({...breaks, maternity: current})
            }else{
                setMaternityState(false)
            }

        }

    }

    const onSubmitHandler = (e) =>{
        e.preventDefault()

        let macAddress

        Store().getCompanyData().organizations.map(el=>{
            if(el.department === data.department){
                el.position.map(item=>{
                    if(item.name === data.position){
                        macAddress=item.mac
                    }
                })
            }
        })

        let pushData = {
            company:data.company,
            affiliation:{
                department:data.department,
                position:data.position,
                macAddress:macAddress
            },
            name:data.name,
            userId:data.userId,
            password:data.password,
            phone:data.phone,
            break:{
                year:breaks.year,
                month:breaks.month,
                special:{
                    family:breaks.family,
                    maternity:breaks.maternity
                }
            },
            form:{
                normal:true,
                admin:false,
                manager:false
            },
            etc:{
                birth:data.birth,
                joinDate:joinDate,
                workTime: `${attendance.hours}:${attendance.min}-${quit.hours}:${quit.min}`,
                lunchTime: `${startLunch.hours}:${startLunch.min}-${endLunch.hours}:${endLunch.min}`
            }

        }

        const today = open.format('YYYY-MM-DD').split('-').join('')

        if(Number(today) <= Number(data.birth.split('-').join(''))){
            alert('생년월일이 현재 날짜와 동일하거나 높을 수 없습니다. 생년월일을 다시 한번 확인해 주세요.')
        }else{
            register(pushData)
                .then(res=>{
                    alert('일반 유저 생성에 성공했습니다.')
                    window.location.replace('/adminPage')
                })
                .catch(function (err){
                    alert(JSON.stringify(err.response.data))
                })
        }
    }


    return(
        <>
            <div style={{margin: 5, paddingLeft: 30, paddingBottom: 30, width: '80%'}}>
                <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 3}} variant="standard">
                    <InputLabel id="demo-simple-select-label">입사 일자</InputLabel>
                    <Select
                        value={form.year}
                        onChange={(e) =>
                            setForm({...form, year: e.target.value})}
                        labelId="demo-simple-select-label"
                        autoWidth
                        //disabled={isSendAuth === true || isDepartment === false || isPosition === false}
                        sx={{height: '31px', width: '90%'}}
                    >
                        <MenuItem value="">
                            <em>입사 년도</em>
                        </MenuItem>
                        {years.map((item) => (
                            <MenuItem
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 3}} variant="standard">
                    <InputLabel id="demo-simple-select-label">월</InputLabel>
                    <Select
                        value={form.month}
                        onChange={(e) =>
                            setForm({...form, month: e.target.value})}
                        labelId="demo-simple-select-label"
                        autoWidth
                        //disabled={isSendAuth === true || isDepartment === false || isPosition === false}
                        sx={{height: '31px', width: '90%'}}
                    >
                        <MenuItem value="">
                            <em>입사 월</em>
                        </MenuItem>
                        {month.map((item) => (
                            <MenuItem
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{marginTop: 0.1, minWidth: 100}} variant="standard">
                    <InputLabel id="demo-simple-select-label">일</InputLabel>
                    <Select
                        value={form.day}
                        onChange={(e) => {
                            setForm({...form, day: e.target.value})
                        }}
                        labelId="demo-simple-select-label"
                        autoWidth
                        //disabled={isSendAuth === true || isDepartment === false || isPosition === false}
                        sx={{height: '31px', width: '90%'}}
                    >
                        <MenuItem value="">
                            <em>입사 일</em>
                        </MenuItem>
                        {days.map((item) => (
                            <MenuItem
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <div style={{margin: 5, paddingLeft: 30, paddingBottom: 30, width: '90%'}}>

                <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 1}} variant="standard">
                    <InputLabel id="demo-simple-select-label">출근시간(시)</InputLabel>
                    <Select
                        value={attendance.hours}
                        onChange={(e) =>
                            setAttendance({...attendance, hours: e.target.value})}
                        labelId="demo-simple-select-label"
                        autoWidth
                        sx={{height: '31px', width: '90%'}}
                    >
                        <MenuItem value="">
                            <em>출근시간(시)</em>
                        </MenuItem>
                        {hours.map((item) => (
                            <MenuItem
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 2}} variant="standard">
                    <InputLabel id="demo-simple-select-label">출근시간(분)</InputLabel>
                    <Select
                        value={attendance.min}
                        onChange={(e) =>
                            setAttendance({...attendance, min: e.target.value})}
                        labelId="demo-simple-select-label"
                        autoWidth
                        sx={{height: '31px', width: '90%'}}
                    >
                        <MenuItem value="">
                            <em>출근시간(분)</em>
                        </MenuItem>
                        {minutes.map((item) => (
                            <MenuItem
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </div>


            <div style={{margin: 5, paddingLeft: 30, paddingBottom: 30, width: '90%'}}>

                <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 1}} variant="standard">
                    <InputLabel id="demo-simple-select-label">퇴근시간(시)</InputLabel>
                    <Select
                        value={quit.hours}
                        onChange={(e) =>
                            setQuit({...quit, hours: e.target.value})}
                        labelId="demo-simple-select-label"
                        autoWidth
                        sx={{height: '31px', width: '90%'}}
                    >
                        <MenuItem value="">
                            <em>퇴근시간(시)</em>
                        </MenuItem>
                        {hours.map((item) => (
                            <MenuItem
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>


                <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 2}} variant="standard">
                    <InputLabel id="demo-simple-select-label">퇴근시간(분)</InputLabel>
                    <Select
                        value={quit.min}
                        onChange={(e) =>
                            setQuit({...quit, min: e.target.value})}
                        labelId="demo-simple-select-label"
                        autoWidth
                        sx={{height: '31px', width: '90%'}}
                    >
                        <MenuItem value="">
                            <em>퇴근시간(분)</em>
                        </MenuItem>
                        {minutes.map((item) => (
                            <MenuItem
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </div>
            <div style={{margin: 5, paddingLeft: 30, paddingBottom: 30, width: '90%'}}>

                <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 1}} variant="standard">
                    <InputLabel id="demo-simple-select-label">점심 시작 시간(시)</InputLabel>
                    <Select
                        value={startLunch.hours}
                        onChange={(e) =>
                            setStartLunch({...startLunch, hours: e.target.value})}
                        labelId="demo-simple-select-label"
                        autoWidth
                        sx={{height: '31px', width: '90%'}}
                    >
                        <MenuItem value="">
                            <em>점심 시작 시간(시)</em>
                        </MenuItem>
                        {hours.map((item) => (
                            <MenuItem
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 2}} variant="standard">
                    <InputLabel id="demo-simple-select-label">점심 시작 시간(분)</InputLabel>
                    <Select
                        value={startLunch.min}
                        onChange={(e) =>
                            setStartLunch({...startLunch, min: e.target.value})}
                        labelId="demo-simple-select-label"
                        autoWidth
                        sx={{height: '31px', width: '90%'}}
                    >
                        <MenuItem value="">
                            <em>점심 시작 시간(분)</em>
                        </MenuItem>
                        {minutes.map((item) => (
                            <MenuItem
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </div>

            <div style={{margin: 5, paddingLeft: 30, paddingBottom: 30, width: '90%'}}>

                <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 1}} variant="standard">
                    <InputLabel id="demo-simple-select-label">점심 종료 시간(시)</InputLabel>
                    <Select
                        value={endLunch.hours}
                        onChange={(e) =>
                            setEndLunch({...endLunch, hours: e.target.value})}
                        labelId="demo-simple-select-label"
                        autoWidth
                        sx={{height: '31px', width: '90%'}}
                    >
                        <MenuItem value="">
                            <em>점심 종료 시간(시)</em>
                        </MenuItem>
                        {hours.map((item) => (
                            <MenuItem
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{marginTop: 0.1, minWidth: 100, paddingRight: 2}} variant="standard">
                    <InputLabel id="demo-simple-select-label">점심 종료 시간(분)</InputLabel>
                    <Select
                        value={endLunch.min}
                        onChange={(e) =>
                            setEndLunch({...endLunch, min: e.target.value})}
                        labelId="demo-simple-select-label"
                        autoWidth
                        sx={{height: '31px', width: '90%'}}
                    >
                        <MenuItem value="">
                            <em>점심 종료 시간(분)</em>
                        </MenuItem>
                        {minutes.map((item) => (
                            <MenuItem
                                key={item}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

            </div>
            <div style={{margin: 5, paddingLeft: 20, paddingBottom: 30, width: '100%'}}>
                <TextField
                    value={breaks.year}
                    onChange={onChangeBreak}
                    required
                    sx={{width:"20%",mt:1,mb:1,mr:1}}
                    id="year"
                    error={yearState === false}
                    name="year"
                    label='연차'
                />
                <TextField
                    required
                    value={breaks.month}
                    onChange={onChangeBreak}
                    sx={{width:"20%",mt:1,mb:1,mr:1}}
                    error={monthState === false}
                    id="month"
                    name="month"
                    label='월차'
                />
                <TextField
                    value={breaks.family}
                    required
                    onChange={onChangeBreak}
                    sx={{width:"20%",mt:1,mb:1,mr:1}}
                    error={familyState === false}
                    id="family"
                    name="family"
                    label='경조사'
                />
                <TextField
                    value={breaks.maternity}
                    required
                    onChange={onChangeBreak}
                    error={maternityState === false}
                    sx={{width:"20%",mt:1,mb:1}}
                    id="maternity"
                    name="maternity"
                    label='육아휴직'
                />
            </div>



            <Button
                onClick={onSubmitHandler}
                type="submit"
                variant="contained"
                size="large"
                sx={{
                    mt: 2, mb: 2,
                    width: "90%",
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                신규 가입
            </Button>


        </>
    )
}