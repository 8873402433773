import React, {useContext} from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes } from "react-router-dom";
import {MainContext, MainProvider} from "./context";
import SideBar from "./pages/containers/SideBar";
import styled from "styled-components";
import Footer from "./pages/containers/Footer";
import Nav from "./pages/countNav"

const Center = styled.div`
  width: auto;
  min-width: 140vh;
  height: auto;
  min-height: 110vh;
  overflow: auto;
  display: flex;
  flex-direction: row;
`


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MainProvider>
      <BrowserRouter>
          <Nav />
          <Center>
              <SideBar />
              <App />
          </Center>
          <Footer />
      </BrowserRouter>
    </MainProvider>
);


