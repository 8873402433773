import axios from 'axios';
const SERVER = process.env.REACT_APP_SERVER
const LOCAL = process.env.REACT_APP_LOCALSERVER

//ctrl+r로 전체 바꿀수 있음 ${}

export const login = loginRequest => axios.post(`${SERVER}/user/login`,loginRequest,{withCredentials:true})
export const logout = () => axios.get(`${SERVER}/user/logout`,{withCredentials:true})
export const register = registerRequest => axios.post(`${SERVER}/user/register`,registerRequest,{withCredentials:true})
export const addInfoService = addInfoRequest => axios.post(`${SERVER}/user/addInfo`,addInfoRequest,{withCredentials:true})
export const addLoginTimes = addLoginTimeRequest=> axios.post(`${SERVER}/user/addLoginTimes`,addLoginTimeRequest,{withCredentials:true})
export const updateUser = (updateUserRequest,params) => axios.post(`${SERVER}/user/update`,updateUserRequest,{params:params,withCredentials:true})
export const delUser = delUserRequest => axios.post(`${SERVER}/user/delUser`,delUserRequest,{withCredentials:true})


export const selectInfo = () => axios.get(`${SERVER}/data/selectInfo`,{withCredentials:true})
export const findService = (findServiceRequest,params) => axios.post(`${SERVER}/user/findService`,findServiceRequest,{params:params,withCredentials:true})

export const authNumCheck = authNumCheckRequest => axios.post(`${SERVER}/data/authNumCheck`,authNumCheckRequest,{withCredentials:true})
export const checkToken = checkTokenRequest =>axios.post(`${SERVER}/data/checkToken`,checkTokenRequest,{withCredentials:true})

export const registerSMS = registerSMSRequest => axios.post(`${SERVER}/send/smsRegister`,registerSMSRequest,{withCredentials:true})
export const SMSService = SMSServiceRequest => axios.post(`${SERVER}/send/SMSService`,SMSServiceRequest,{withCredentials:true})


export const getAdminData = params => axios.get(`${SERVER}/admin/get/adminService`,{params:params,withCredentials:true})
export const findAdminData = findAdminDataRequest => axios.post(`${SERVER}/admin/find/service`,findAdminDataRequest,{withCredentials:true})
export const adminService = (adminServiceRequest,params) => axios.post(`${SERVER}/admin/organizations/service`,adminServiceRequest,{params:params,withCredentials:true})


export const getAttendance = getAttendanceRequest => axios.post(`${SERVER}/attendance/refine/processing/getData`,getAttendanceRequest,{withCredentials:true})
export const vacationService = (vacationServiceRequest,params) => axios.post(`${SERVER}/attendance/vacation/service`,vacationServiceRequest,{params:params,withCredentials:true})