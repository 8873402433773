import React, {useEffect, useState} from "react";
import Store from "../../../../../../../context/store";
import {Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import {adminService} from "../../../../../../../api";


export default function DepartmentName(props){

    const {sizeType} =props
    const companyData = Store().getCompanyData()

    const [departmentList,setDepartmentList] = useState([])
    const [department,setDepartment] = useState("")
    const [basic,setBasic] = useState("")


    const onChangeBasicDepartment = (e)=>{
        setBasic(e.target.value)
    }
    const onChangeDepartment = (e)=>{
        setDepartment(e.target.value)
    }

    const onSubmitHandler = (e)=>{
        e.preventDefault()
        let data = {
            company:companyData.company,
            basic:basic,
            loginData:Store().getLoginData(),
            department:department
        }
        let params = {
            contents:'organizations',
            type:'changeDepartment'
        }
        adminService(data,params)
            .then(res=>{
                alert(res.data.msg)
                if(res.data.userData === 'Not Change'){
                    Store().setCompanyData(res.data.companyData)
                    window.location.replace('/adminPage')
                }else{
                    Store().setCompanyData(res.data.companyData)
                    Store().setLoginData(res.data.userData)
                    window.location.replace('/adminPage')
                }

            })
            .catch(err=>{
                alert(err.response.data)
            })
    }

    useEffect(()=>{
        let pushData = []
        companyData.organizations.map(item=>{
            pushData.push(item.department)
        })
        setDepartmentList(pushData)
    })

    return(
        <>
            <div style={{alignItems:'center',flexDirection:'column',display:'flex',marginLeft:40}}>
                <div style={{width:"100%"}}>
                    <br/>
                    <FormControl sx={{width:'90%'}}>
                        <InputLabel>기존 부서 명</InputLabel>
                        <Select
                            name="department"
                            value={basic}
                            onChange={onChangeBasicDepartment}
                            input={<OutlinedInput label="name" />}
                        >
                            {departmentList.map((item) => (
                                <MenuItem
                                    key={item}
                                    value={item}
                                >
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br/><br/>

                    <TextField
                        onChange={onChangeDepartment}
                        required
                        value={department}
                        disabled={basic.length <= 0}
                        sx={{width:"90%",mt:1,mb:1}}
                        id="position"
                        name="position"
                        label="변경하실 부서명"
                    />

                    <Button
                        disabled={department.length <= 0}
                        onClick={onSubmitHandler}
                        variant="contained"
                        type="submit"
                        size="medium"
                        sx={{
                            marginTop: 2,
                            width:"90%",
                            height: '45px',
                            border: 3,
                            "&.MuiButton-root:hover": {
                                color: '#008DDC',
                                backgroundColor: '#c7ebff',
                                borderColor: '#008DDC'
                            }
                        }}

                    >
                        부서명 수정
                    </Button>

                </div>
            </div>

        </>
    )
}