import React, {useEffect, useState} from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from '@fullcalendar/react';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import './MyCalendar.css';
import axios from "axios";


export default function Month(props){

    const {monthData,attendanceData} =props

    let [event,setEvent] = useState([])

    const apiKey = process.env.REACT_APP_GOOGLE_CALENDER
    const apiId = process.env.REACT_APP_GOOGLE_ID

    const dateClick = (e)=>{

    }


    useEffect(()=>{
        let start = []
        let end = []
        let holidays =[]
        monthData.map(e=>{
            if(e.state === 'start'){
                if(attendanceData.basicData.start.split(':').join('') >= e.time.split(':').join('')){
                    let pushData = {
                        date:e.date,
                        title:'start : '+e.time,
                        index:0
                    }
                    start.push(pushData)
                }else{
                    let pushData = {
                        date:e.date,
                        title:'start : '+e.time,
                        color:'#FF5A5A',
                        index:0
                    }
                    start.push(pushData)
                }

            }else{
                if(attendanceData.basicData.end.split(':').join('') >= e.time.split(':').join('')){
                    let pushData = {
                        date:e.date,
                        title:'end : '+e.time,
                        color:'#FF5A5A',
                        index:1
                    }
                    end.push(pushData)
                }else{
                    let pushData = {
                        date:e.date,
                        title:'end : '+e.time,
                        color:'#b1aee5',
                        index:1
                    }
                    end.push(pushData)
                }
            }
        })
        attendanceData.holidays.map(e=>{
            if(e.isHoliday === false){
                if(e.title === '재택근무'){
                    let pushData = {
                        date:e.date,
                        title:e.title,
                        textColor:'white',
                        index:2
                    }
                    holidays.push(pushData)
                }else{
                    let pushData = {
                        date:e.date,
                        title:e.title,
                        color:'white',
                        textColor:'black',
                        index:2
                    }
                    holidays.push(pushData)
                }

            }else{
                let pushData = {
                    date:e.date,
                    title:e.title,
                    color:'#98E2EC',
                    textColor:'red',
                    index:2
                }
                holidays.push(pushData)
            }

        })
        setEvent([...holidays,...end,...start])
    },[])

    return(
        <>
            <div style={{width:"90%"}}>
                <FullCalendar
                    locale="kr"
                    plugins={[googleCalendarPlugin,dayGridPlugin,timeGridPlugin,interactionPlugin]}
                    initialView={'dayGridMonth'}
                    googleCalendarApiKey={apiKey}
                    headerToolbar={
                        {
                            start: 'today',
                            center: 'title',
                            end: 'prevYear,prev,next,nextYear'
                        }
                    }
                    height={"85vh"}
                    dateClick={dateClick}
                    events={event}
                    eventOrder={'index'}
                />
            </div>

        </>
    )
}