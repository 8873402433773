import Store from "../../../context/store";
import React, {useEffect, useState} from "react";
import {getAttendance, vacationService} from "../../../api";
import {Box, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "../Modal";


export default function SignUp(props){

    const {management} =props

    let attendanceData = Store().getAttendanceData()
    let vacationData = Store().getUserVacation()

    const [modalOpen, setModalOpen] = useState(false);
    const [deleteOpen,setDeleteOpen] = useState(false)
    const [yearBreak,setYearBreak] = useState('')
    const [monthBreak,setMonthBreak] = useState('')
    const [familyBreak,setFamilyBreak] = useState('')
    const [maternityBreak,setMaternityBreak] = useState('')

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }
    const deleteOpenModal=()=>{
        if(vacationData.length === 0){
            alert('취소하실 수 있는 휴가 정보가 없습니다.')
            setDeleteOpen(false)
        }else{
            setDeleteOpen(true);
        }

    };
    const deleteCloseModal=()=>{
        setDeleteOpen(false);
    }

    useEffect(() => {
        if(Store().getLogin() === true){
            if(attendanceData === null){
                let sendData = {
                    company: Store().getCompanyData().company,
                    userData: Store().getLoginData()
                }
                getAttendance(sendData)
                    .then(res => {
                        Store().setAttendanceData(res.data)
                        attendanceData = res.data
                        window.location.reload()
                    })
                    .catch(err => {

                    })
            }else{
                setYearBreak(attendanceData.break.yearBreak)
                setMonthBreak(attendanceData.break.monthBreak)
                setFamilyBreak(attendanceData.break.familyBreak)
                setMaternityBreak(attendanceData.break.maternityBreak)
            }
        }
    }, []);

    useEffect(()=>{
        const timer = setInterval(()=>{
            if(Store().getLogin() === true){
                let params = {
                    service:'getData'
                }
                vacationService(Store().getLoginData(),params)
                    .then(res=>{
                        Store().setVacationState(res.data)
                    })
                    .catch(err=>{

                    })
                let param = {
                    service:'change',
                    checked:'search'
                }
                vacationService(Store().getLoginData(),param)
                    .then(res=>{
                        Store().setUserVacation(res.data)
                    })
                    .catch(err=>{

                    })
                let sendData = {
                    company: Store().getCompanyData().company,
                    userData: Store().getLoginData()
                }

                getAttendance(sendData)
                    .then(res=>{
                        const basicData = Store().getAttendanceData()
                        if(basicData.log[0]._id !== res.data.log[0]._id){
                            Store().setAttendanceData(res.data)
                            attendanceData = res.data
                            window.location.reload()
                        }

                        if(JSON.stringify(basicData.break) !== JSON.stringify(res.data.break)){
                            Store().setAttendanceData(res.data)
                            attendanceData = res.data
                            setYearBreak(attendanceData.break.yearBreak)
                            setMonthBreak(attendanceData.break.monthBreak)
                            setFamilyBreak(attendanceData.break.familyBreak)
                            setMaternityBreak(attendanceData.break.maternityBreak)
                        }

                    })
                    .catch(err=>{

                    })
            }

        },1000)
    },[])

    return(
        <>
            <div style={{display: 'flex', alignItems: 'center',flexDirection:'column', width: '90%'}}>
                <div>
                    {management === 'true' ? (
                        <div style={{marginTop: 20, marginBottom: 25}}>
                            <Button
                                variant="outlined"
                                size="large"
                                onClick={openModal}
                                sx={{marginRight: 7}}
                            >
                                휴가 신청
                            </Button>

                            <Button
                                variant="outlined"
                                size="large"
                                onClick={deleteOpenModal}
                            >
                                휴가 취소
                            </Button>
                        </div>
                    ) : (
                        <>
                        </>
                    )}

                </div>
                <Box
                    sx={{
                        backgroundColor: 'floralwhite',
                        width: '100%',
                        borderRadius: '1rem',
                        marginBottom: 2,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                    <Typography component="h5" variant="h6" sx={{paddingTop: 1}}>
                        남은 휴가
                    </Typography>

                    <div style={{display: 'flex', alignItems: 'center', padding: 5}}>

                        {management === "true" ? (
                            <>
                                <Typography component="h4" variant="h8">
                                    연차
                                </Typography>
                                <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                    {yearBreak} 회
                                </Typography>
                                <Typography component="h4" variant="h8" sx={{paddingLeft: 2}}>
                                    월차
                                </Typography>
                                <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                    {monthBreak} 회
                                </Typography>
                                <Typography component="h4" variant="h8" sx={{paddingLeft: 2}}>
                                    경조사
                                </Typography>
                                <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                    {familyBreak} 회
                                </Typography>
                                <Typography component="h4" variant="h8" sx={{paddingLeft: 2}}>
                                    육아휴직
                                </Typography>
                                <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                    {maternityBreak} 회
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography component="h4" variant="h8" sx={{paddingLeft: 20}}>
                                    연차
                                </Typography>
                                <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                    {yearBreak} 회
                                </Typography>
                                <Typography component="h4" variant="h8" sx={{paddingLeft: 2}}>
                                    월차
                                </Typography>
                                <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                    {monthBreak} 회
                                </Typography>
                                <Typography component="h4" variant="h8" sx={{paddingLeft: 2}}>
                                    경조사
                                </Typography>
                                <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                    {familyBreak} 회
                                </Typography>
                                <Typography component="h4" variant="h8" sx={{paddingLeft: 2}}>
                                    육아휴직
                                </Typography>
                                <Typography component="h5" variant="h6" color='black' sx={{paddingLeft: 1}}>
                                    {maternityBreak} 회
                                </Typography>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    onClick={openModal}
                                    sx={{marginRight: 5, marginLeft: 10}}
                                >
                                    휴가 신청
                                </Button>
                            </>
                        )}

                    </div>
                </Box>
                {modalOpen && <>
                    <Modal open={modalOpen} close={closeModal} header="휴가 신청"
                           yearBreak={yearBreak} monthBreak={monthBreak} familyBreak={familyBreak}
                           maternityBreak={maternityBreak}
                           attendanceData={attendanceData}/>
                </>}
                {deleteOpen && <>
                    <Modal open={deleteOpen} close={deleteCloseModal} header="휴가 취소"
                           yearBreak={yearBreak} monthBreak={monthBreak} familyBreak={familyBreak}
                           maternityBreak={maternityBreak}
                           attendanceData={attendanceData}/>
                </>}
            </div>
        </>
    )
}