import React, {useEffect, useRef, useState} from "react";
import {Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";
import Store from "../../../../../../../context/store";
import {adminService} from "../../../../../../../api";


export default function Change(props){

    const {userData,companyData,department} =props

    const nextID = useRef(0)
    const [departmentList,setDepartmentList] = useState([])
    const [positionList, setPositionList] = useState([])
    const [isPosition,setIsPosition] = useState(false)
    const [changeData,setChangeData] = useState([{
        id:0,
        department:'',
        userId:'',
        position:'',
        positionList:[],
        mac:[],
        name:'',
        macLabel:'기기 등록'
    }])

    const onChangeDepartment = (e,index)=>{
        if(index > changeData.length) return;

        const formItemsCopy = JSON.parse(JSON.stringify(changeData))

        formItemsCopy[index].department = e.target.value;
        setChangeData(formItemsCopy)

        companyData.organizations.map(item=>{
            if(e.target.value === item.department){
                formItemsCopy[index].positionList = item.position.map(e=>e.name);
            }
        })
    }

    const onChangePosition = (e,index)=>{
        if(index > changeData.length) return;

        const formItemsCopy = JSON.parse(JSON.stringify(changeData))

        formItemsCopy[index].position = e.target.value;
        setChangeData(formItemsCopy)
        companyData.organizations.map(item=>{
            item.position.map(el=>{
                if(e.target.value === el.name){
                    formItemsCopy[index].mac = el.mac
                }
            })

        })

        setIsPosition(true)
    }

    const onSubmitHandler = (e) =>{
        e.preventDefault()
        let sendUserData = []
        userData.map(e=>{
            changeData.map(item=>{
                if(e.userId === item.userId){
                    let pushData = {
                        _id:e._id,
                        company:e.company,
                        affiliation:{department:item.department,position:item.position,macAddress:item.mac,_id:e.affiliation._id},
                        name:item.name,
                        userId:item.userId,
                        password:e.password,
                        phone:e.phone,
                        break:e.break,
                        form:e.form,
                        etc:e.etc
                    }
                    sendUserData.push(pushData)
                }
            })
        })
        let sendData = {
            company:Store().getCompanyData().company,
            userData:sendUserData,
            companyData:companyData,
            loginData:Store().getLoginData(),
            delDepartment:department
        }
        let params = {
            contents:'organizations',
            type:'departmentDelete',
            reResponse:'changeData'
        }

        adminService(sendData,params)
            .then(res=>{
                //alert(res.data.msg)
                // if(res.data.userData === 'None'){
                //     Store().setCompanyData(res.data.companyData)
                //     window.location.replace('/adminPage')
                // }else{
                //     console.log(res.data)
                //     setChangeUserData(res.data.userData)
                //     setChangeCompanyData(res.data.companyData)
                //     openModal()
                // }

            })
            .catch(err=>{
                alert(err.response.data)
            })

    }


    useEffect(()=>{
        let pushData = []
        userData.map(e=>{
            companyData.organizations.map(item=>{
                    let data = {
                        id:nextID.current,
                        department:'',
                        userId:e.userId,
                        name:e.name,
                        positionList:item.position.map(e=>e.name),
                        position:'',
                        mac:item.position.map(el=>el.mac),
                        macLabel:'기기 등록'
                    }

                    pushData.push(data)
                    nextID.current += 1;
            })
        })
        setDepartmentList(companyData.organizations.map(el=>el.department))

        const setPushData = pushData.filter(
            (pData, idx) => {
                return (
                    pushData.findIndex((pData1) => {
                        return pData.userId === pData1.userId
                    }) === idx
                )
            }
        )
        setChangeData(setPushData)

    },[])


    return(
        <>
            <div style={{alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                <div>
                    <br/>
                    {changeData.map((item, index) => (
                        <div key={index} style={{marginBottom: 15}}>
                            <FormControl sx={{width: '40%'}}>
                                <InputLabel>부서 명</InputLabel>
                                <Select
                                    value={item.department || ''}
                                    defaultValue = ""
                                    name="department"
                                    onChange={e => onChangeDepartment(e, index)}
                                    input={<OutlinedInput label="name"/>}
                                >
                                    {departmentList.map((items) => (
                                        <MenuItem
                                            key={items}
                                            value={items}
                                        >
                                            {items}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{width: '25%', ml: 2}}>
                                <InputLabel>직급</InputLabel>
                                <Select
                                    value={item.position || '' }
                                    name="position"
                                    onChange={e => onChangePosition(e, index)}
                                    input={<OutlinedInput label="name"/>}
                                >
                                    {item.positionList.map((items) => (
                                        <MenuItem
                                            key={items}
                                            value={items}
                                        >
                                            {items}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                required
                                value={item.name}
                                disabled
                                sx={{width: "20%", ml: 2}}
                                id="name"
                                name="name"
                                label='이름'
                            />


                        </div>
                    ))}

                    <Button
                        onClick={onSubmitHandler}
                        variant="contained"
                        type="submit"
                        size="medium"
                        fullWidth
                        sx={{
                            marginTop: 2,
                            width: "100%",
                            height: '45px',
                            border: 3,
                            "&.MuiButton-root:hover": {
                                color: '#008DDC',
                                backgroundColor: '#c7ebff',
                                borderColor: '#008DDC'
                            }
                        }}

                    >
                        소속 변경
                    </Button>


                </div>
            </div>
        </>
    )
}