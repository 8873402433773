import {Button} from "@mui/material";
import React from "react";
import * as XLSX from "xlsx";
import moment from "moment-timezone";

import Store from "../../../../../../../context/store";


export default function ExcelDownload(props){

    const {selectedData} =props

    const onDownloadButton = (e) => {
        e.preventDefault()

        let params = {
            contents:"download"
        }
        // companyInfoUpdate(selectedData,params)
        //     .then(res=>{
        //         const downData = res.data
        //         const getCurrentTime = ()=>{
        //             const m =moment().tz("Asia/Seoul")
        //             return m.format("YYYY.MM.DD")
        //         }
        //         const ws= XLSX.utils.json_to_sheet(downData);
        //         const wb= XLSX.utils.book_new();
        //         XLSX.utils.book_append_sheet(wb,ws,`WeToGo`);
        //         XLSX.writeFile(wb, `[${Store().getCompanyData().company}]WeToGo ${getCurrentTime()}.xlsx`)
        //     })
        //     .catch(err=>{
        //         alert(JSON.stringify(err.response.data))
        //     })


    }

    return(
        <>
            <Button
                onClick={onDownloadButton}
                variant="contained"
                size="large"
                sx={{marginRight:2}}
                disabled={selectedData.length < 1}
            >
                다운로드(Excel)
            </Button>
        </>
        )
}