import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import { ko } from "date-fns/esm/locale";
import {Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography} from "@mui/material";
import HolidayLogo from "../../../../picture/holiday.png";
import Button from "@mui/material/Button";
import Store from "../../../../context/store";
import {getAttendance, vacationService} from "../../../../api";
import YearLogo from "../../../../picture/yearBreak.png"
import MonthLogo from "../../../../picture/monthBreak.png"
import FamilyLogo from "../../../../picture/familyBreak.png"
import MaternityLogo from "../../../../picture/maternityBreak.png"

export default function SignUp(props){

    const {yearBreak,monthBreak,maternityBreak,familyBreak,attendanceData} = props

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [select,setSelect] = useState('')
    const [breakLabel,setBreakLabel] = useState('')
    const [useBreakLabel,setUseBreakLabel] = useState('')
    const [breakCount,setBreakCount] = useState(0)
    const [useBreakCount,setUseBreakCount] = useState(0)
    const [holidays,setHolidays] = useState([])
    const [logo,setLogo] = useState(HolidayLogo)


    const [selectStart,setSelectStart] = useState(false)
    const [signState,setSignState] = useState(false)

    const selectBreak = [`잔여 연차 : ${yearBreak} 회`,`잔여 월차 : ${monthBreak} 회`,`잔여 경조사 : ${familyBreak} 회`,
        `잔여 육아휴직 : ${maternityBreak} 회`]



    const getDatesStartToLast = (startDate, lastDate) => {
        const result = [];
        while (startDate <= lastDate) {
            result.push(startDate.toISOString().split('T')[0]);
            startDate.setDate(startDate.getDate() + 1);
        }
        return result;
    };


    const onChangeSelect = (e)=>{
        setSelectStart(false)
        setSelect(e.target.value)
        if(e.target.value === `잔여 연차 : ${yearBreak} 회`){
            setBreakLabel('잔여 연차')
            setUseBreakLabel('사용 연차')
            setLogo(YearLogo)
            setBreakCount(yearBreak)
            setUseBreakCount(0)
        }
        if(e.target.value === `잔여 월차 : ${monthBreak} 회`){
            setBreakLabel('잔여 월차')
            setUseBreakLabel('사용 월차')
            setLogo(MonthLogo)
            setBreakCount(monthBreak)
            setUseBreakCount(0)
        }
        if(e.target.value === `잔여 경조사 : ${familyBreak} 회`){
            setBreakLabel('잔여 경조사')
            setUseBreakLabel('사용 경조사')
            setLogo(FamilyLogo)
            setBreakCount(familyBreak)
            setUseBreakCount(0)
        }
        if(e.target.value === `잔여 육아휴직 : ${maternityBreak} 회`){
            setBreakLabel('잔여 육아휴직')
            setUseBreakLabel('사용 육아휴직')
            setLogo(MaternityLogo)
            setBreakCount(maternityBreak)
            setUseBreakCount(0)
        }
    }

    const onChangeStartDate = (e) =>{
        setStartDate(e)
        setEndDate(e)
        setSelectStart(true)
        if(select === `잔여 연차 : ${yearBreak} 회`){
            setBreakLabel('잔여 연차')
            setUseBreakLabel('사용 연차')
            setBreakCount(yearBreak - 1)
            setUseBreakCount(yearBreak-(yearBreak - 1))
        }
        if(select === `잔여 월차 : ${monthBreak} 회`){
            setBreakLabel('잔여 월차')
            setUseBreakLabel('사용 월차')
            setBreakCount(monthBreak - 1)
            setUseBreakCount(monthBreak-(monthBreak - 1))
        }
        if(select === `잔여 경조사 : ${familyBreak} 회`){
            setBreakLabel('잔여 경조사')
            setUseBreakLabel('사용 경조사')
            setBreakCount(familyBreak - 1)
            setUseBreakCount(familyBreak-(familyBreak - 1))
        }
        if(select === `잔여 육아휴직 : ${maternityBreak} 회`){
            setBreakLabel('잔여 육아휴직')
            setUseBreakLabel('사용 육아휴직')
            setBreakCount(maternityBreak - 1)
            setUseBreakCount(maternityBreak-(maternityBreak - 1))
        }

    }

    const onChangeEndDate = (e)=>{
        setEndDate(e)
        let filterStartMonth;
        let filterStartDate;
        let filterEndMonth;
        let filterEndDate;

        if((startDate.getMonth() +1) <= 9){
            filterStartMonth = '0'+(startDate.getMonth() +1)
        }else{
            filterStartMonth = startDate.getMonth() +1
        }
        if(startDate.getDate() <= 9){
            filterStartDate = '0'+startDate.getDate()
        }else{
            filterStartDate = startDate.getDate()
        }

        if((e.getMonth() +1) <= 9){
            filterEndMonth = '0'+(e.getMonth() +1)
        }else{
            filterEndMonth = e.getMonth() +1
        }
        if(e.getDate() <= 9){
            filterEndDate = '0'+e.getDate()
        }else{
            filterEndDate = e.getDate()
        }



        const startFull = new Date(`${startDate.getFullYear()}-${filterStartMonth}-${filterStartDate}`)
        const endFull = new Date(`${e.getFullYear()}-${filterEndMonth}-${filterEndDate}`)

        let diffNum = Math.abs(startFull.getTime() - endFull.getTime());
        diffNum = Math.ceil(diffNum / (1000 * 60 * 60 * 24));

        let checkDate = getDatesStartToLast(startFull,endFull)

        let holiday = []

        checkDate.map(item=>{
            let checkDay = new Date(item).getDay();
            if(checkDay === 0 || checkDay === 6){
                holiday.push(item)
            }
            attendanceData.holidays.map(el=>{
                if(item === el.date){
                    const findWeeksCheck = holiday.find((items)=>items === el.date)
                    if(findWeeksCheck === undefined){
                        holiday.push(item)
                    }
                }
            })
        })
        setHolidays(holiday)

        if(select === `잔여 연차 : ${yearBreak} 회`){
            setBreakLabel('잔여 연차')
            const testBreak = (yearBreak + holiday.length) - (diffNum + 1)
            if(testBreak < 0){
                alert(`잔여 연차보다 ${testBreak} 일 만큼 선택하셨습니다. 다시 한번 선택 해 주세요.`)
                setSelectStart(false)
                setSignState(false)
            }else{
                setSignState(true)
                setBreakCount((yearBreak + holiday.length) - (diffNum + 1))
                setUseBreakCount(yearBreak - (yearBreak - (diffNum + 1 - holiday.length)))
            }

        }
        if(select === `잔여 월차 : ${monthBreak} 회`){
            const testBreak = monthBreak + holiday.length - (diffNum + 1)
            setBreakLabel('잔여 월차')
            if(testBreak < 0){
                alert(`잔여 월차보다 ${testBreak} 일 만큼 선택하셨습니다. 다시 한번 선택 해 주세요.`)
                setSelectStart(false)
                setSignState(false)
            }else{
                setSignState(true)
                setBreakCount(testBreak)
                setUseBreakCount(monthBreak - (monthBreak - (diffNum + 1 - holiday.length)))
            }

        }
        if(select === `잔여 경조사 : ${familyBreak} 회`){
            const testBreak = familyBreak + holiday.length - (diffNum + 1)
            setBreakLabel('잔여 경조사')
            if(testBreak < 0){
                alert(`잔여 경조사보다 ${testBreak} 일 만큼 선택하셨습니다. 다시 한번 선택 해 주세요.`)
                setSelectStart(false)
                setSignState(false)
            }else{
                setSignState(true)
                setBreakCount(testBreak)
                setUseBreakCount(familyBreak - (familyBreak - (diffNum + 1 - holiday.length)))
            }

        }
        if(select === `잔여 육아휴직 : ${maternityBreak} 회`){
            const testBreak = maternityBreak + holiday.length - (diffNum + 1)
            setBreakLabel('잔여 육아휴직')
            if(testBreak < 0){
                alert(`잔여 육아휴직보다 ${testBreak} 일 만큼 선택하셨습니다. 다시 한번 선택 해 주세요.`)
                setSelectStart(false)
                setSignState(false)
            }else{
                setSignState(true)
                setBreakCount(testBreak)
                setUseBreakCount(maternityBreak - (maternityBreak - (diffNum + 1 - holiday.length)))
            }

        }

    }



    const onSubmit = (e) => {
        e.preventDefault()

        let filterStartMonth;
        let filterStartDate;
        let filterEndMonth;
        let filterEndDate;

        if((startDate.getMonth() +1) <= 9){
            filterStartMonth = '0'+(startDate.getMonth() +1)
        }else{
            filterStartMonth = startDate.getMonth() +1
        }
        if(startDate.getDate() <= 9){
            filterStartDate = ('0'+startDate.getDate())
        }else{
            filterStartDate = startDate.getDate()
        }

        if((endDate.getMonth() +1) <= 9){
            filterEndMonth = '0'+(endDate.getMonth() +1)
        }else{
            filterEndMonth = endDate.getMonth() +1
        }
        if(endDate.getDate() <= 9){
            filterEndDate = '0'+endDate.getDate()
        }else{
            filterEndDate = endDate.getDate()
        }


        const startFull = new Date(`${startDate.getFullYear()}-${filterStartMonth}-${filterStartDate}`)
        const endFull = new Date(`${endDate.getFullYear()}-${filterEndMonth}-${filterEndDate}`)
        const breakDate = getDatesStartToLast(startFull,endFull)
        let signUpDate =[]
        let duplicateDate = []
        breakDate.map(e=>{
            const check = holidays.find((items)=>items === e)
            if(check === undefined){
                signUpDate.push(e)
            }
            attendanceData.holidays.map(item=>{
                if(e === item.date){
                    duplicateDate.push(item)
                }
            })
        })

        let type;
        if(select.split(':')[0].split(' ')[1] === '연차'){
            type = 'year'
        }
        if(select.split(':')[0].split(' ')[1] === '월차'){
            type = 'month'
        }
        if(select.split(':')[0].split(' ')[1] === '경조사'){
            type = 'family'
        }
        if(select.split(':')[0].split(' ')[1] === '육아휴직'){
            type = 'maternity'
        }


        if(duplicateDate.length === 0){
            let sendData = {
                breakDate:signUpDate,
                type:type,
                company:Store().getCompanyData().company,
                loginData:Store().getLoginData(),
                breakCount:breakCount
            }
            let params = {
                service:'signUp'
            }
            vacationService(sendData,params)
                .then(res=>{
                    alert(res.data.msg)
                    Store().setLoginData(res.data.userData)
                    let sendData2 = {
                        company: Store().getCompanyData().company,
                        userData: Store().getLoginData()
                    }
                    getAttendance(sendData2)
                        .then(res1=>{
                            Store().setAttendanceData(res1.data)
                            let params = {
                                service:'getData'
                            }
                            vacationService(Store().getLoginData(),params)
                                .then(res2=>{
                                    Store().setVacationState(res2.data)
                                    window.location.replace('/vacation')
                                })
                                .catch(err=>{

                                })
                        })
                        .catch(err=>{

                        })
                })
                .catch(err=>{
                    if(err.response.status === 402){
                        alert(err.response.data)
                    }else{
                        alert(JSON.stringify(err))
                    }

                })
        }else{
            if(signUpDate.length >= 1){
                if(window.confirm(`요청하신 날짜 ${duplicateDate.map(e=>e.date)}가 이미 휴가처리 되어있습니다. 중복되지 않는 ${signUpDate.map(e=>e)}를 휴가신청 하시겠습니까?`)){
                    let sendData = {
                        breakDate:signUpDate,
                        type:type,
                        company:Store().getCompanyData().company,
                        loginData:Store().getLoginData(),
                        breakCount:breakCount
                    }
                    let params = {
                        service:'signUp'
                    }

                    vacationService(sendData,params)
                        .then(res=>{
                            alert(res.data.msg)
                            Store().setLoginData(res.data.userData)
                            let sendData2 = {
                                company: Store().getCompanyData().company,
                                userData: Store().getLoginData()
                            }
                            getAttendance(sendData2)
                                .then(res1=>{
                                    Store().setAttendanceData(res1.data)
                                    let params = {
                                        service:'getData'
                                    }
                                    vacationService(Store().getLoginData(),params)
                                        .then(res2=>{
                                            Store().setVacationState(res2.data)
                                            window.location.replace('/vacation')
                                        })
                                        .catch(err=>{

                                        })
                                })
                                .catch(err=>{

                                })
                        })
                        .catch(err=>{
                            if(err.response.status === 402){
                                alert(err.response.data)
                            }else{
                                alert(JSON.stringify(err))
                            }

                        })
                }else{
                    return;
                }
            }else{
                alert(`요청하신 날짜 ${duplicateDate.map(e=>e.date)}가 이미 휴가처리 되어있습니다. 휴가 변경은 휴가 취소 후 재신청 해주시기 바랍니다.`)
            }

        }

    }





    return (
        <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
            <FormControl sx={{width: '75%'}}>
                <InputLabel>사용 하실 휴가를 선택해 주세요.</InputLabel>
                <Select
                    autoFocus
                    name="select"
                    value={select}
                    onChange={onChangeSelect}
                    input={<OutlinedInput label="name"/>}
                >
                    {selectBreak.map((item) => (
                        <MenuItem
                            key={item}
                            value={item}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br/>
            <div style={{width: "90%", marginLeft: 85}}>
                <DatePicker
                    disabled={select.length <= 0}
                    name="startDate"
                    selected={startDate}
                    onChange={onChangeStartDate}
                    locale={ko}                   // 한글로 변경
                    dateFormat="yyyy.MM.dd (eee)" // 시간 포맷 변경
                    showPopperArrow={false}       // 화살표 변경
                    minDate={new Date()}          // 오늘 날짜 전은 선택 못하게
                    customInput={		      // 날짜 뜨는 인풋 커스텀
                        //<Form.Control as="textarea" rows={1} style={{width:"250px"}}/>
                        <TextField
                            // value={userId}
                            margin="normal"
                            required
                            sx={{width: "95%"}}
                            label="시작 날짜를 선택해주세요."
                            name="userId"
                            autoFocus
                            //onChange={onChangeEmail}
                        />
                    }
                />


                <DatePicker
                    selected={endDate}
                    disabled={select.length <= 0 || selectStart === false}
                    onChange={onChangeEndDate}
                    locale={ko}                   // 한글로 변경
                    dateFormat="yyyy.MM.dd (eee)" // 시간 포맷 변경
                    showPopperArrow={false}       // 화살표 변경
                    minDate={new Date() && startDate}          // 오늘 날짜 전은 선택 못하게
                    customInput={		      // 날짜 뜨는 인풋 커스텀
                        //<Form.Control as="textarea" rows={1} style={{width:"250px"}}/>
                        <TextField
                            // value={userId}
                            margin="normal"
                            required
                            sx={{width: "95%"}}
                            label="종료 날짜를 선택해 주세요"
                            name="userId"
                            autoFocus
                            //onChange={onChangeEmail}
                        />
                    }
                />
            </div>
            <br/>

            <Box
                sx={{
                    backgroundColor: 'floralwhite',
                    width: "25vw",
                    height: 60,
                    borderRadius: '1rem',
                    marginBottom: 2,
                    marginRight: 1,
                    paddingRight: 2,
                    display: 'flex',
                    alignItems: 'center',
                }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img alt="No Images" src={logo} style={{paddingLeft: "20%", marginTop: 1, width: '10%'}}/>
                    <Typography component="h4" variant="h7" sx={{paddingLeft: "7%"}}>
                        {breakLabel} :
                    </Typography>
                    <Typography component="h5" variant="h6" color='black'
                                sx={{paddingLeft: "5%"}}>
                        {breakCount} 회
                    </Typography>
                </div>
            </Box>
            <br/>
            <Box
                sx={{
                    backgroundColor: 'floralwhite',
                    width: "25vw",
                    height: 60,
                    borderRadius: '1rem',
                    marginBottom: 2,
                    marginRight: 1,
                    paddingRight: 2,
                    display: 'flex',
                    alignItems: 'center',
                }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img alt="No Images" src={logo} style={{paddingLeft: "20%", marginTop: 1, width: '10%'}}/>
                    <Typography component="h4" variant="h7" sx={{paddingLeft: "7%"}}>
                        {useBreakLabel} :
                    </Typography>
                    <Typography component="h5" variant="h6" color='red'
                                sx={{paddingLeft: "5%"}}>
                        {useBreakCount} 회
                    </Typography>
                </div>
            </Box>
            <br/>
            <Typography component="h5" variant="h7" sx={{color: 'red'}}>
                ★ 휴가 타입(년차,월차,경조사,육아휴직) 변경은 휴가취소 후 재신청 해주시기 바랍니다.
            </Typography><br/>

            <Button
                disabled={signState === false}
                variant="contained"
                size="large"
                onClick={onSubmit}
                sx={{width: "75%"}}
            >
                휴가 신청
            </Button> <br/>


        </div>


    );
}