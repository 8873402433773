import React, {useContext, useEffect, useState} from 'react'
import Background from "../../../picture/background.png";
import {MainContext} from "../../../context";
import {Box, Typography} from "@mui/material";
import Logo from "../../../picture/Logo.png";
import AdminUser from "./service/admin"
import Users from "./service/user"
import Store from "../../../context/store";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import {getAdminData} from "../../../api";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));




const columns = [
    {field:'department', headerName: '부서', width:90},
    {field:'position', headerName: '직급', width:60},
    {field: 'macAddress',headerName: '사용가능 기기',width: 100},
    {field:'name', headerName: '이름', width:80},
    {field:'id', headerName: '아이디', width:200},
    {field:'phone', headerName: '전화번호', width:150},
    {field:'yearBreak', headerName: '연차', width:60},
    {field:'monthBreak', headerName: '월차', width:60},
    {field:'family', headerName: '경조사', width:60},
    {field:'maternity', headerName: '육아휴직', width:60},
    {field:'birth', headerName: '생년월일', width:120},
    {field:'joinDate', headerName: '입사날짜', width:100},
    {field:'workTime', headerName: '근무시간', width:100},
    {field:'lunchTime', headerName: '점심시간', width:100},
]

const adminColumns = [
    {field:'department', headerName: '부서', width:150},
    {field:'position', headerName: '직급', width:150},
    {field: 'mac',headerName: '사용가능 기기', width: 1100}
]

export default function Index(){

    const { toggleClose,companyLogin } = useContext(MainContext)

    const [userDBData,setUserDBData] = useState([])
    const [companyDBData,setCompanyDBData] = useState([])
    const [findData,setFindData] = useState([])

    const [selectedData,setSelectedData]=useState('')
    const [search, setSearch] = useState("")
    const [isFindData,setIsFindData] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    const [isCheck, setIsCheck] = useState(false)
    const [count, setCount] = useState(0)
    const [isReload,setIsReload] = useState(false)

    const [labels, setLabels] = useState('직원 정보관리')
    let companyData = Store().getCompanyData()
    let userData = [Store().getLoginData()]

    const toggle = ()=>{
        setIsCheck((prev)=>!prev)
        setSelectedData('')
        if(isCheck === true) {
            setLabels('직원정보 관리')
            setCount(1)
            setIsAdmin(false)
            if(isFindData === true){
                setIsFindData(false)
            }
        }else {
            setLabels('기업정보 관리')
            setCount(2)
            setIsAdmin(true)
            if(isFindData === true){
                setIsFindData(false)
            }
        }
    }

    const onCompanyRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => companyDBData.find((row) => row.id === id ));
        setSelectedData(selectedRowsData)
    };

    const onUserRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => userDBData.find((row) => row.id === id ));
        setSelectedData(selectedRowsData)
    };



    const inputData = ()=>{
        let params = {
            company:companyData.company
        }

        let companyInputData = []
        let index = 0
        getAdminData(params)
            .then(res=>{
                const companyData = res.data.companyData[0]
                const userData = res.data.userData

                companyData.organizations.map(item=>{
                    item.position.map(e=>{
                        let adminData = {
                            id:`${index+=1}`,
                            department:item.department,
                            position: e.name,
                            mac:e.mac
                        }
                        companyInputData.push(adminData)
                    })
                })



                let inputData = userData.map((item)=>({
                    admin:item.form.admin === true ? 'O':'X',
                    serverManager:item.form.manager,
                    department:item.affiliation.department,
                    position:item.affiliation.position,
                    macAddress:item.affiliation.macAddress,
                    name:item.name,
                    id:item.userId,
                    phone:item.phone,
                    yearBreak:item.break.year,
                    monthBreak:item.break.month,
                    family:item.break.special.family,
                    maternity:item.break.special.maternity,
                    birth:item.etc.birth,
                    joinDate:item.etc.joinDate,
                    lunchTime:item.etc.lunchTime,
                    workTime:item.etc.workTime
                }))
                const filterData = inputData.filter(item=>item.serverManager !== true)


                setCompanyDBData(companyDBData.concat(companyInputData))
                setUserDBData(userDBData.concat(filterData))
                setCount(count+1)
            })


    }

    useEffect(inputData,[])

    return(
        <>
            <div style={{
                backgroundImage: `url(${Background})`,
                backgroundSize: "cover",width:'100%'
            }} onClick={toggleClose}>
                <div style={{alignItems:'center',display:'flex',flexDirection:'column',paddingBottom:20}}>
                    <Box
                        sx={{
                            backgroundColor:'white',
                            width:"90%",
                            border:1,
                            borderRadius:'2rem',
                            margin:3,
                            display:'flex',
                            alignItems:'center',
                            flexDirection:'column',

                        }}
                    >
                        <img alt="No Images" src={Logo} style={{width:200,paddingTop:20}}/>
                        <Typography component="h4" variant="h6" sx={{paddingTop:1}}>
                            관리자 정보페이지
                        </Typography><br/>
                        <FormControlLabel
                            onClick={toggle}
                            control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked />}
                            label={labels}
                        />
                        {isAdmin === true ? (
                            <>
                                <AdminUser isFindData={isFindData} isCheck={isCheck} setIsFindData={setIsFindData}
                                           setFindData={setFindData} search={search} setSearch={setSearch}
                                           companyData={companyData} isAdmin={isAdmin} findData={findData}
                                           adminColumns={adminColumns} onCompanyRowsSelectionHandler={onCompanyRowsSelectionHandler}
                                           companyDBData={companyDBData} selectedData={selectedData}
                                 />
                            </>
                        ):(
                            <>
                                <Users isFindData={isFindData} isCheck={isCheck} setIsFindData={setIsFindData}
                                setFindData={setFindData} search={search} setSearch={setSearch}
                                       companyData={companyData} isAdmin={isAdmin} findData={findData}
                                columns={columns} onUserRowsSelectionHandler={onUserRowsSelectionHandler}
                                       userDBData={userDBData} selectedData={selectedData} />
                            </>
                        )}


                        <br/><br/>
                    </Box>
                </div>
            </div>
        </>
    )
}