import {Button} from "@mui/material";
import React, {useState} from "react";
import Store from "../../../../../../../../context/store";
import Modal from "../../../../../modals/admin";


export default function Index(props){


    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }


    return(
        <>
            <Button
                variant="contained"
                size="large"
                onClick={openModal}
                sx={{marginRight:2}}
            >
                부서 삭제
            </Button>
            {modalOpen && <>
                <Modal open={modalOpen} close={closeModal} header="부서 삭제" />
            </>}
        </>
    )
}