import React, {useEffect, useState} from "react";
import {FormControl, IconButton, InputLabel, Link, MenuItem, Select, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {findAdminData, searchData} from "../../../../../../api";


const excelUploadForm = process.env.REACT_APP_EXCELUPLOADFORM

export default function Index(props){

    const {count,isFindData,setData, company, sizeType,isAdmin,setIsFindData,search,setSearch } = props

    let selectList = []
    const [changeSelect, setChangeSelect]=useState('')
    const [selectSearch, setSelectSearch] = useState("")

    if(isAdmin === true){
        selectList = ['부서','직급','기기번호']
    }else{
        selectList = ['부서','직급','이름','기기번호','아이디','전화번호']
    }

    const onSearch = (e) =>{
        const currentTextSearch = e.target.value;
        setSearch(currentTextSearch)
    }

    const onChangeSelectSearch = (e)=>{
        const currentSelect = e.target.value;
        setSelectSearch(currentSelect)
        if(currentSelect === ""){
            setChangeSelect("")
        } else if(currentSelect === '이름'){
            setChangeSelect('name')
        }else if(currentSelect === '아이디'){
            setChangeSelect('userId')
        }else if(currentSelect === '전화번호'){
            setChangeSelect('phone')
        }else if(currentSelect === '부서'){
            setChangeSelect('department')
        }else if(currentSelect === '직급'){
            setChangeSelect('position')
        }
        else if(currentSelect === '기기번호'){
            setChangeSelect('mac')
        }
    }

    const onClickSearch = (e) =>{
        e.preventDefault()

        let sendData ={
            admin:isAdmin,
            company:company,
            selectBox:changeSelect,
            search:search
        }
        findAdminData(sendData)
            .then(res=>{
                setData(res.data)
                setIsFindData(true)
            })
            .catch(err=>{

            })

    }

    const form = ()=>{
        window.location.replace(excelUploadForm)
    }



    return(
        <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
            {sizeType === 'mobile' ? (
                <div style={{display:'flex',alignItems:'center',marginLeft:20}}>
                    <form style={{display: 'flex', padding: '10px'}}>
                        <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:2}} variant="standard">
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Select
                                value={selectSearch}
                                onChange={onChangeSelectSearch}
                                labelId="demo-simple-select-label"
                                autoWidth
                                sx={{height: '31px', width: '100px'}}
                            >
                                <MenuItem value="">
                                    <em>선택</em>
                                </MenuItem>
                                {selectList.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            sx={{marginTop: 0, height: '50px', width:150}}
                            value={search}
                            label="Search"
                            name="search"
                            variant="standard"
                            onChange={onSearch}
                        />

                        <IconButton
                            type="submit"
                            sx={{marginTop: 0.5, borderRadius: '0.2rem'}}
                            onClick={onClickSearch}
                        >
                            <SearchIcon sx={{height: '30px', width: '30px'}}/>
                        </IconButton>

                    </form>
                </div>
            ):(

                <div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
                    <form style={{display: 'flex', padding: '1px'}}>

                        <FormControl sx={{marginTop: 0.1, minWidth: 100,paddingRight:2}} variant="standard">
                            <InputLabel id="demo-simple-select-label">Select</InputLabel>
                            <Select
                                value={selectSearch}
                                onChange={onChangeSelectSearch}
                                labelId="demo-simple-select-label"
                                autoWidth
                                sx={{height: '31px', width: '100px'}}
                            >
                                <MenuItem value="">
                                    <em>선택</em>
                                </MenuItem>
                                {selectList.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                        <TextField
                            sx={{marginTop: 0, height: '50px', width:250}}
                            label="Search"
                            variant="standard"
                            onChange={onSearch}
                        />

                        <IconButton
                            type="submit"
                            sx={{marginTop: 0.5, borderRadius: '0.2rem'}}
                            onClick={onClickSearch}
                        >
                            <SearchIcon sx={{height: '30px', width: '30px'}}/>
                        </IconButton>

                    </form><br/>
                </div>
            )}

            <Link href='#' onClick={form} style={{marginTop:8}} >Excel Upload 양식 다운로드</Link>
        </div>
    )
}