import '../../styles/modalStyle.css'
import React from "react";
import FindId from './id'
import FindPw from "./pw";
import ChangePw from "./pw/main"
import ChangePassword from "./changePassword";


export default function Id(props){

    const {open, close, header } = props;

    return(
        <>
            <div className={open ? 'openModal modal' : 'modal'}>
                {open ? (
                    <>

                        <section style={{marginTop:50}}>
                            <header>
                                {header}
                                <button className="close" onClick={close}>
                                    &times;
                                </button>
                            </header>
                            <main style={{alignItems: "center"}}>
                                {header === '아이디 찾기' ? (
                                    <>
                                        <FindId style={{alignItems: "center"}} />
                                    </>
                                ):(
                                    <>
                                        {header === '비밀번호 변경' ? (
                                            <>
                                                <ChangePw close={close} style={{alignItems: "center"} } />
                                            </>
                                        ):(
                                            <>
                                                {header === '고객 비밀번호 변경' ? (
                                                    <>
                                                        <ChangePassword style={{alignItems: "center"} } />
                                                    </>
                                                ):(
                                                    <>
                                                        <FindPw close={close} style={{alignItems: "center"} } />
                                                    </>
                                                )}

                                            </>
                                        )}

                                    </>
                                )}

                            </main>
                            <footer>
                                <button className="close" onClick={close}>
                                    닫기
                                </button>
                            </footer>
                        </section>
                    </>
                ) : null}
            </div>
        </>
    )
}