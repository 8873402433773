import {Box, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import greenLogo from "../../../picture/checkGreen.png";
import redLogo from "../../../picture/checkRed.png";
import yellowLogo from "../../../picture/yellowLogo.png";
import absenceLogo from "../../../picture/absence.png"
import moment from "moment-timezone";
import holidayLogo from "../../../picture/holiday.png";
import overTimeLogo from "../../../picture/overTime.png";
import greenSpinner from "../../../picture/greenSpinner.gif";
import redSpinner from "../../../picture/spinner.gif";
import homeWorkLogo from "../../../picture/homeWork.png";
import State from "../state";
import yearBreakLogo from "../../../picture/yearBreak.png";
import monthBreakLogo from "../../../picture/monthBreak.png";
import familyBreakLogo from "../../../picture/familyBreak.png";
import maternityBreakLogo from "../../../picture/maternityBreak.png";

export default function Week(props){

    const { attendanceData,weekData,setWeekData,weekState,setWeekState,holidayWeek } =props

    const onClick = (e)=>{
        let tzData = moment().tz('Asia/Seoul')
        let now = tzData.format('YYYY-MM-DD')
            let weeksData = []
            let weekCount = 0;
            const filterDay = e.target.name === 'prev' ?
            moment(weekData[0].date).subtract(1,"days").format('YYYY-MM-DD') :
                e.target.name === 'next' ? moment(weekData[weekData.length - 1].date).add(1,"days").format('YYYY-MM-DD') :
                    now



            const day = new Date(filterDay)
            const sunday = day.getTime() - 86400000 * day.getDay();

            day.setTime(sunday);

            const column = ['일요일','월요일','화요일','수요일','목요일','금요일','토요일']
            const weeks = [{column:column[0],date:day.toISOString().slice(0, 10)}];

            for (let i = 1; i < 7; i++) {
                day.setTime(day.getTime() + 86400000);
                weeks.push({column:column[i],date:day.toISOString().slice(0, 10)});
            }

            attendanceData.monthData.map(e=>{
                const findWeeksCheck = weeks.find((el)=>el.date === e.date)
                if(findWeeksCheck !== undefined){
                    if(weeksData.length === 0){
                        let pushData = {
                            _id:e._id,
                            userId:e.userId,
                            date:e.date,
                            time:e.time,
                            state:e.state
                        }
                        weeksData.push(pushData)
                        weekCount = 1
                    }else{
                        if(e.date === weeksData.slice(-1)[0].date){
                            if(weekCount === 1){
                                let pushData = {
                                    _id:e._id,
                                    userId:e.userId,
                                    date:e.date,
                                    time:e.time,
                                    state:'start'
                                }
                                weeksData.push(pushData)
                                weekCount = 2
                            }else{
                                if(weekCount === 2){
                                    let pushData = {
                                        _id:e._id,
                                        userId:e.userId,
                                        date:e.date,
                                        time:e.time,
                                        state:'start'
                                    }
                                    weeksData[weeksData.length -1] = pushData
                                }
                            }

                        }else{
                            if(weeksData.length === 1){
                                weeksData[0].state = 'start'
                            }
                            let pushData = {
                                _id:e._id,
                                userId:e.userId,
                                date:e.date,
                                time:e.time,
                                state:'end'
                            }
                            weeksData.push(pushData)
                            weekCount = 1
                        }
                    }
                }
            })


            weeks.map(e=>{
                weeksData.map(el=>{
                    if(e.date === el.date){
                        let index = weeks.findIndex(obj=>obj.date == el.date)
                        if(el.state === 'start'){
                            if(attendanceData.basicData.start.split(':').join('') >= el.time.split(':').join('')){
                                weeks[index].startState = 'normal'
                            }else{
                                weeks[index].startState = "abNormal"
                            }
                            weeks[index].start = el.time
                        }else{
                            if(attendanceData.basicData.end.split(':').join('') < el.time.split(':').join('')){
                                weeks[index].endState = 'normal'
                            }else{
                                weeks[index].endState = "abNormal"
                            }
                            weeks[index].end = el.time
                        }
                    }
                })
            })

        let holidays = []
        weeks.map(e=>{
            let index = weeks.findIndex(obj=>obj.date == e.date)
            attendanceData.holidays.map(item=>{
                if(e.date === item.date){
                    weeks[index].holiday = item
                    holidays.push(item)
                }
            })
        })


            let normalState = 0
            let abNormalState = 0
            let redNormalState = 0
            let absenceState = 0
            let overTimeState = 0
            let yearBreakState = 0
            let monthBreakState = 0
            let familyBreakState = 0
            let maternityBreakState = 0
            let homeWorkState =0

            weeks.map(e=>{
                let index = weeks.findIndex(obj=>obj.date == e.date)
                if(e.column === '일요일' || e.column === '토요일'){
                    weeks[index].logoInfo = {label:'휴무 : ',logo:holidayLogo}
                }else{
                    if(e.startState === 'normal'){
                        if(e.endState === 'normal'){
                            const dateA = new Date(`${e.date} ${e.end}`);
                            const dateB = new Date(`${e.date} ${attendanceData.basicData.end}`);
                            const diffMSec = dateA.getTime() - dateB.getTime();
                            const diffMin = diffMSec / (60 * 1000);
                            if(diffMin > 30){
                                weeks[index].logoInfo = {label:'연장근무 : ',logo:overTimeLogo}
                                overTimeState +=1
                            }else{
                                weeks[index].logoInfo = {label:'정상 : ',logo:greenLogo}
                                normalState +=1
                            }
                        }else if(e.endState === 'abNormal'){
                            weeks[index].logoInfo = {label:'비정상 : ',logo:yellowLogo}
                            abNormalState +=1
                        }else{
                            weeks[index].logoInfo = {label:'근무 중 : ',logo:greenSpinner}
                        }
                    }else{
                        if(e.endState === 'normal'){
                            weeks[index].logoInfo = {label:'비정상 : ',logo:yellowLogo}
                            abNormalState +=1
                        }else if(e.endState === 'abNormal'){
                            weeks[index].logoInfo = {label:'비정상 : ',logo:redLogo}
                            redNormalState +=1
                        }else{
                            if(e.startState === undefined){
                                let tzData = moment().tz('Asia/Seoul')
                                let now = tzData.format('YYYY-MM-DD')

                                if(holidays.length === 0){
                                    if(e.date.split('-').join('') > now.split('-').join('')){
                                        weeks[index].logoInfo = {label:'future',logo:''}
                                    }else{
                                        weeks[index].logoInfo = {label:'결근 : ',logo:absenceLogo}
                                        absenceState +=1
                                    }
                                }else{
                                    if(e.date.split('-').join('') > now.split('-').join('')){
                                        weeks[index].logoInfo = {label:'future',logo:''}
                                    }else{
                                        weeks[index].logoInfo = {label:'결근 : ',logo:absenceLogo}
                                        absenceState +=1
                                    }
                                }
                            }else{
                                weeks[index].logoInfo = {label:'근무 중 : ',logo:redSpinner}
                            }

                        }

                    }
                }
                    holidays.map(el=>{
                        if(el.date === e.date){
                            if(el.isHoliday === true){
                                if(el.title === '연차'){
                                    yearBreakState += 1
                                    weeks[index].logoInfo = {label:`${el.title} : `,logo:yearBreakLogo}
                                }else if(el.title === '월차'){
                                    monthBreakState +=1
                                    weeks[index].logoInfo = {label:`${el.title} : `,logo:monthBreakLogo}
                                } else if(el.title === '경조사'){
                                    familyBreakState +=1
                                    weeks[index].logoInfo = {label:`${el.title} : `,logo:familyBreakLogo}
                                } else if(el.title === '육아휴직'){
                                    maternityBreakState +=1
                                    weeks[index].logoInfo = {label:`${el.title} : `,logo:maternityBreakLogo}
                                }else{
                                    weeks[index].logoInfo = {label:`${el.title} : `,logo:holidayLogo}
                                }
                            }else{
                                if(el.title === '재택근무'){
                                    weeks[index].logoInfo = {label:`${el.title} : `,logo:homeWorkLogo}
                                    homeWorkState +=1
                                }else{
                                    weeks[index].logoInfo = {label:`${el.title}`,logo:''}
                                }
                            }
                        }
                    })

            })

        setWeekState({
            normal:normalState,
            abNormal:abNormalState,
            redAbNormal:redNormalState,
            absence:absenceState,
            overTime:overTimeState,
            yearBreak:yearBreakState,
            monthBreak:monthBreakState,
            familyBreak:familyBreakState,
            maternityBreak:maternityBreakState,
            homeWork:homeWorkState
        })
            setWeekData(weeks)



    }


    useEffect(()=>{
        console.log(weekData)
    },[])



    return(
        <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <br/>
            <State weekState={weekState}/>
            <br/>

            <div style={{display: 'flex'}}>
                <Button
                    variant="outlined"
                    size="large"
                    name="prev"
                    onClick={onClick}
                    sx={{margin: 2, height: '5vh'}}
                >
                    ⇦ Prev
                </Button>
                <Typography component="h5" variant="h5" color="black" sx={{padding: 3}}>
                    {weekData[0].date} ~ {weekData[weekData.length - 1].date} 주간
                    데이터
                </Typography>
                <Button
                    variant="outlined"
                    size="large"
                    name="next"
                    onClick={onClick}
                    sx={{margin: 2, height: '5vh'}}
                >
                    Next ⇨
                </Button>

                <Button
                    variant="outlined"
                    size="large"
                    name="today"
                    onClick={onClick}
                    sx={{marginLeft: 5,marginTop:2, height: '5vh'}}
                >
                    Today
                </Button>
            </div>

            <div style={{display: 'flex', alignItems: 'center', width: "100%"}}>
                {weekData.map(item => (
                    <div key={item.column}>
                        <Box
                            sx={{
                                backgroundColor: 'floralwhite',
                                width: '10vw',
                                border: 1,
                                marginTop: 2,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                opacity: 1
                            }}>
                            <Typography component="h5" variant="h6"
                                        color={item.column === '일요일' || item.holiday !== undefined && item.holiday.isHoliday === true ? 'red' : item.column === '토요일' ? 'blue' : 'black'}
                            >
                                {item.column}
                            </Typography>
                        </Box>
                    </div>
                ))}
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: "100%"}}>
                {weekData.map(item => (
                    <div style={{display: 'flex', alignItems: 'center', width: "100%"}} key={item.date}>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                width: '10vw',
                                height: '25vh',
                                border: 1,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                opacity: 1
                            }}>
                            {item.holiday === undefined ? (
                                <>
                                    <Typography component="h5" variant="h6"
                                                color={item.column === '일요일' ? 'red' : item.column === '토요일' ? 'blue' : 'black'}
                                                sx={{margin: "2%"}}>
                                        {item.date}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    {item.start === undefined ? (
                                        <>
                                            <Typography component="h5" variant="h6"
                                                        color={item.column === '일요일' ||  item.holiday.isHoliday === true ? 'red' : item.column === '토요일' ? 'blue':'black'}
                                                        sx={{margin: "2%"}}>
                                                {item.date}
                                            </Typography>
                                        </>
                                    ) : (
                                        <>
                                            <Typography component="h5" variant="h6"
                                                        color={item.column === '일요일' ||  item.holiday.isHoliday === true ? 'red' : item.column === '토요일' ? 'blue':'black'}
                                                        sx={{margin: "2%"}}>
                                                {item.date}
                                            </Typography>
                                        </>
                                    )}

                                </>
                            )}

                            <br/>

                            {item.startState === 'normal' ? (
                                <>
                                    <Typography component="h4" variant="h7"
                                                color={item.column === '일요일' ? 'red' : item.column === '토요일' ? 'blue' : 'white'}
                                                sx={{
                                                    backgroundColor: "#2478FF",
                                                    width: '9.5vw',
                                                    paddingLeft: "3%",
                                                    marginBottom: "3%"
                                                }}>
                                        {item.start === undefined ? (
                                            <>

                                            </>
                                        ) : (
                                            <>
                                                Start : {item.start}
                                            </>
                                        )}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography component="h4" variant="h7"
                                                color={item.column === '일요일' ? 'red' : item.column === '토요일' ? 'blue' : 'white'}
                                                sx={{
                                                    backgroundColor: "red",
                                                    width: '9.5vw',
                                                    paddingLeft: "3%",
                                                    marginBottom: "3%"
                                                }}>
                                        {item.start === undefined ? (
                                            <>

                                            </>
                                        ) : (
                                            <>
                                                Start : {item.start}
                                            </>
                                        )}
                                    </Typography>
                                </>
                            )}


                            {item.endState === 'normal' ? (
                                <>
                                    <Typography component="h4" variant="h7"
                                                color={item.column === '일요일' ? 'red' : item.column === '토요일' ? 'blue' : 'white'}
                                                sx={{backgroundColor: '#b1aee5', width: '9.5vw', paddingLeft: "3%"}}>
                                        {item.end === undefined ? (
                                            <>

                                            </>
                                        ) : (
                                            <>
                                                End : {item.end}
                                            </>
                                        )}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography component="h4" variant="h7"
                                                color={item.column === '일요일' ? 'red' : item.column === '토요일' ? 'blue' : 'white'}
                                                sx={{backgroundColor: "red", width: '9.5vw', paddingLeft: "3%"}}>
                                        {item.end === undefined ? (
                                            <>

                                            </>
                                        ) : (
                                            <>
                                                End : {item.end}
                                            </>
                                        )}
                                    </Typography>
                                </>
                            )}

                            <div style={{display: 'flex', alignItems: 'center'}}>

                                {item.logoInfo.label === '비정상 : ' || item.logoInfo.label === '근무 중 : ' ? (
                                    <>
                                        {item.logoInfo.label === '근무 중 : ' ? (
                                            <>
                                                <Typography component="h4" variant="h7"
                                                            sx={{paddingLeft: "16%", paddingTop: "50%"}}>
                                                    {item.logoInfo.label}
                                                </Typography>
                                                <img alt="No Images" src={item.logoInfo.logo}
                                                     style={{width: '25%', paddingTop: "50%", paddingLeft: "5%"}}/>
                                            </>
                                        ) : (
                                            <>
                                                {item.holiday === undefined ? (
                                                    <>
                                                        <Typography component="h4" variant="h7"
                                                                    sx={{paddingLeft: "16%", paddingTop: "40%"}}>
                                                            {item.logoInfo.label}
                                                        </Typography>
                                                        <img alt="No Images" src={item.logoInfo.logo}
                                                             style={{
                                                                 width: '22%',
                                                                 paddingTop: "40%",
                                                                 paddingLeft: "7%"
                                                             }}/>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Typography component="h4" variant="h7" color="red"
                                                                    sx={{paddingLeft: "16%", paddingTop: "16%"}}>
                                                            {item.logoInfo.label}
                                                        </Typography>
                                                        <img alt="No Images" src={item.logoInfo.logo}
                                                             style={{
                                                                 width: '22%',
                                                                 paddingTop: "16%",
                                                                 paddingLeft: "7%"
                                                             }}/>
                                                    </>
                                                )}

                                            </>
                                        )}

                                    </>
                                ) : (
                                    <>
                                        {item.logoInfo.label === '정상 : ' ? (
                                            <>
                                                {item.holiday === undefined ? (
                                                    <>
                                                        <Typography component="h4" variant="h7"
                                                                    sx={{paddingLeft: "22%", paddingTop: "40%"}}>
                                                            {item.logoInfo.label}
                                                        </Typography>
                                                        <img alt="No Images" src={item.logoInfo.logo}
                                                             style={{
                                                                 width: '22%',
                                                                 paddingTop: "40%",
                                                                 paddingLeft: "10%"
                                                             }}/>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Typography component="h4" variant="h7"
                                                                    sx={{paddingLeft: "22%", paddingTop: "16%"}}>
                                                            {item.logoInfo.label}
                                                        </Typography>
                                                        <img alt="No Images" src={item.logoInfo.logo}
                                                             style={{
                                                                 width: '22%',
                                                                 paddingTop: "16%",
                                                                 paddingLeft: "10%"
                                                             }}/>
                                                    </>
                                                )}

                                            </>
                                        ) : (
                                            <>
                                                {item.logoInfo.label === 'future' ? (
                                                    <>
                                                    </>
                                                ) : (
                                                    <>
                                                        {item.holiday === undefined ? (
                                                            <>
                                                                {item.logoInfo.label === '연장근무 : ' ? (
                                                                    <>
                                                                        <Typography component="h4" variant="h7" sx={{
                                                                            paddingLeft: "15%",
                                                                            paddingTop: "40%"
                                                                        }}>
                                                                            {item.logoInfo.label}
                                                                        </Typography>
                                                                        <img alt="No Images" src={item.logoInfo.logo}
                                                                             style={{
                                                                                 paddingLeft: "7%",
                                                                                 width: '30%',
                                                                                 paddingTop: "35%"
                                                                             }}/>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Typography component="h4" variant="h7" sx={{
                                                                            paddingLeft: "18%",
                                                                            paddingTop: "60%"
                                                                        }}>
                                                                            {item.logoInfo.label}
                                                                        </Typography>
                                                                        <img alt="No Images" src={item.logoInfo.logo}
                                                                             style={{
                                                                                 paddingLeft: "7%",
                                                                                 width: '30%',
                                                                                 paddingTop: "59%"
                                                                             }}/>
                                                                    </>
                                                                )}

                                                            </>
                                                        ) : (
                                                            <>
                                                                {item.start === undefined ? (
                                                                    <>
                                                                        {item.holiday.isHoliday === true ? (
                                                                            <>
                                                                                <Typography component="h4" variant="h7"
                                                                                            color="red"
                                                                                            sx={{
                                                                                                paddingLeft: "15%",
                                                                                                paddingTop: "60%"
                                                                                            }}>
                                                                                    {item.logoInfo.label}
                                                                                </Typography>
                                                                                <img alt="No Images" src={item.logoInfo.logo}
                                                                                     style={{
                                                                                         paddingLeft: "5%",
                                                                                         width: '30%',
                                                                                         paddingTop: "55%"
                                                                                     }}/>
                                                                            </>
                                                                        ):(
                                                                            <>
                                                                                {item.logoInfo.label === '재택근무 : ' ? (
                                                                                    <>
                                                                                        <Typography component="h4" variant="h7"
                                                                                                    color="black"
                                                                                                    sx={{
                                                                                                        paddingLeft: "15%",
                                                                                                        paddingTop: "60%"
                                                                                                    }}>
                                                                                            {item.logoInfo.label}
                                                                                        </Typography>
                                                                                        <img alt="No Images" src={item.logoInfo.logo}
                                                                                             style={{
                                                                                                 paddingLeft: "5%",
                                                                                                 width: '30%',
                                                                                                 paddingTop: "55%"
                                                                                             }}/>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                    </>
                                                                                )}

                                                                            </>
                                                                        )}

                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Typography component="h4" variant="h7"
                                                                                    color={item.logoInfo.label === '재택근무 : ' ? 'black' : 'red'}
                                                                                    sx={{
                                                                                        paddingLeft: "10%",
                                                                                        paddingTop: "40%"
                                                                                    }}>
                                                                            {item.logoInfo.label}
                                                                        </Typography>
                                                                        <img alt="No Images" src={item.logoInfo.logo}
                                                                             style={{
                                                                                 paddingLeft: "5%",
                                                                                 width: '30%',
                                                                                 paddingTop: "35%"
                                                                             }}/>
                                                                    </>
                                                                )}


                                                            </>
                                                        )}

                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}

                            </div>

                        </Box>
                    </div>
                ))}

            </div>

            <br/>
            <br/>
        </div>
    )
}