import {Button} from "@mui/material";
import React, {useState} from "react";
import Store from "../../../../../../../../context/store";
import {adminService} from "../../../../../../../../api";
import Modal from "../../../../../modals/admin";


export default function Index(props){

    const {selectedData} =props

    const [userData,setUserData] = useState([])
    const [companyData,setCompanyData] = useState([])

    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }

    const [delPoint,setDelPoint] = useState([])

    const delUser = ()=>{

        if(window.confirm(`${selectedData.map(e=>e.department)} 의 ${selectedData.map(e=>e.position)} 정보를 삭제 하시겠습니까?`)){

            let data = {
                userData:Store().getLoginData(),
                company: Store().getCompanyData().company,
                delPoint : selectedData
            }
            let params = {
                contents:'organizations',
                type:'selectDelete',
            }

            adminService(data,params)
                .then(res=>{
                    if(res.data.userData !== 'None'){
                        alert(res.data.msg)
                        setUserData(res.data.userData)
                        setCompanyData(res.data.companyData)
                        setDelPoint(selectedData)
                        openModal()
                    }else{
                        alert(res.data.msg)
                        Store().setCompanyData(res.data.companyData)
                        window.location.replace('/adminPage')
                    }

                })
                .catch(err=>{
                    alert(JSON.stringify(err.response.data))
                })

        }else {
            return;
        }

    }


    return(
        <>
            <Button
                variant="contained"
                size="large"
                disabled={selectedData.length < 1}
                onClick={delUser}
                sx={{marginRight:2}}
            >
                선택 삭제
            </Button>
            {modalOpen && <>
                <Modal open={modalOpen} close={closeModal} header="유저 소속변경"
                       userData={userData} companyData={companyData} selectedData={delPoint} />
            </>}
        </>
    )
}