import React from 'react'
import { useRoutes } from "react-router-dom"

import Home from './pages'
import Vacation from './component/Vacation'
import MeetingRoom from './component/MeetingRoom'
import AdminPage from './component/Admin/admin'
import WebAdminPage from './component/Web'


//a4:da:22:11:92:e2




export default function App() {
  return useRoutes([
    //Main
    {path:'/',element:<Home />},
    {path:'vacation',element:<Vacation />},
    {path:'meetingRoom',element:<MeetingRoom />},
    {path:'adminPage',element:<AdminPage />},
    {path:'webAdminPage',element:<WebAdminPage />},
      ]);
}
