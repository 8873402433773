import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button, Checkbox,
    FormControl, FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel, MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material";
import Logo from "../../../../../../../picture/Logo.png";
import styled from "styled-components";
import moment from "moment-timezone";
import Store from "../../../../../../../context/store";
import {adminService} from "../../../../../../../api";
import Modals from "../../index";

const FormHelperTextsRED = styled(FormHelperText)`
  width: 100%;
  padding-left: 110px;
  padding-bottom: 10px;
  font-weight: 700 !important;
  color: #d32f2f !important;
  display: flex;
  align-items: center;
`;

const FormHelperTextsBLUE = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #0f27d9 !important;
`;

export default function Index(props){

    const { data,sizeType }=props

    const nextID = useRef(0)
    const [departmentList,setDepartmentList] = useState([])
    const [position,setPosition] =useState('')
    const companyData = Store().getCompanyData()
    const [checkList, setCheckList] = useState([]);
    const [changeData,setChangeData] = useState([{
        id:0,
        department:'',
        basicPosition:'',
        position:'',
        positionList:[],
        mac:[],
        basicMac:[],
        macLabel:'기기 등록'
    }])

    const onChangeDepartment = (e,index)=>{
        if(index > changeData.length) return;

        const formItemsCopy = JSON.parse(JSON.stringify(changeData))

        formItemsCopy[index].department = e.target.value;
        setChangeData(formItemsCopy)

        companyData.organizations.map(item=>{
            if(e.target.value === item.department){
                formItemsCopy[index].positionList = item.position.map(e=>e.name);
            }
        })
    }

    const onChangePosition = (e,index)=>{
        if(index > changeData.length) return;

        const formItemsCopy = JSON.parse(JSON.stringify(changeData))

        formItemsCopy[index].position = e.target.value;
        setChangeData(formItemsCopy)
        companyData.organizations.map(item=>{
            item.position.map(el=>{
                if(e.target.value === el.name){
                    formItemsCopy[index].mac = el.mac
                }
            })

        })

    }

    const [modalOpen, setModalOpen] = useState(false);
    const [modalNum,setModalNum] = useState('')
    const openModal=(e)=>{
        setModalNum(e.target.name)
        setModalOpen(true);
    };
    const closeModal = ()=>{
        setModalOpen(false);
    }

    const onSubmitHandler = (e)=>{
        e.preventDefault()

        let sendData ={
            company:companyData.company,
            loginData:Store().getLoginData(),
            basicData: data,
            changeData : changeData
        }
        let params = {
            contents:'organizations',
            type:'checkUpdate'
        }
        adminService(sendData,params)
            .then(res=>{
                if(res.data.userData === 'None'){
                    alert(res.data.msg)
                    Store().setCompanyData(res.data.companyData)
                    window.location.replace('/adminPage')
                }else{
                    alert(res.data.msg)
                    Store().setLoginData(res.data.userData)
                    Store().setCompanyData(res.data.companyData)
                    window.location.replace('/adminPage')
                }
            })
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
            })
    }

    //
    // const checkAll = (e) => {
    //     e.target.checked
    //         ? setCheckList([...companyData.macAddress])
    //         : setCheckList([])
    // }
    // const isAllChecked = checkList.length > 0;
    // const disabled = !isAllChecked
    //
    // const check = (e) => {
    //     e.target.checked
    //         ? setCheckList([...checkList, e.target.name])
    //         : setCheckList(checkList.filter((choice)=> choice !== e.target.name))
    // }
    //
    // const handleClick = (e)=>{
    //     e.preventDefault()

    //     let params = {
    //         contents:'organizations',
    //         type:'checkUpdate'
    //     }
    //     adminService(sendData,params)
    //         .then(res=>{
    //             if(res.data.userData === 'None'){
    //                 alert(res.data.msg)
    //                 Store().setCompanyData(res.data.companyData)
    //                 window.location.replace('/adminPage')
    //             }else{
    //                 alert(res.data.msg)
    //                 Store().setLoginData(res.data.userData)
    //                 Store().setCompanyData(res.data.companyData)
    //                 window.location.replace('/adminPage')
    //             }
    //
    //         })
    //         .catch(err=>{
    //             alert(err.response.data)
    //         })
    // }

    useEffect(()=>{
        let pushData = []
        console.log(data)
        data.map(e=>{
            companyData.organizations.map(item=>{
                if(e.department === item.department){
                    let filterData = {
                        id:nextID.current,
                        department:e.department,
                        positionList:item.position.map(el=>el.name),
                        position:e.position,
                        basicPosition:e.position,
                        mac:e.mac,
                        basicMac:e.mac,
                        macLabel:'기기 등록'
                    }

                    pushData.push(filterData)
                    nextID.current += 1;
                }
            })
        })
        setDepartmentList(companyData.organizations.map(el=>el.department))
        setChangeData(pushData)
    },[])

    return(
        <>
            <div style={{alignItems:'center',flexDirection:'column',display:'flex',marginLeft:50}}>
                <div style={{width:"100%"}}>
                    {changeData.map((item,index)=>(
                        <div key={index} style={{marginBottom:15}}>
                            <FormControl sx={{width:'30%'}}>
                                <InputLabel>부서 명</InputLabel>
                                <Select
                                    disabled
                                    value={item.department || ''}
                                    name="department"
                                    onChange={e=>onChangeDepartment(e,index)}
                                    input={<OutlinedInput label="name" />}
                                >
                                    {departmentList.map((items) => (
                                        <MenuItem
                                            key={items}
                                            value={items}
                                        >
                                            {items}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                required
                                value={item.position}
                                onChange={e=>onChangePosition(e,index)}
                                sx={{width:"20%",ml:2}}
                                id="name"
                                name="name"
                                label='직급'
                            />


                            {index >= 0 && changeData.length < 10 && (
                                <>
                                    <Button
                                        variant={changeData[index].macLabel === '기기 재등록' ? "contained":"outlined"}
                                        disabled={item.position.length === 0}
                                        name={index}
                                        sx={{
                                            ml:2,
                                            width:"25%",
                                            height:55,
                                            "&.MuiButton-root:hover":{
                                                color:'#008DDC',
                                                backgroundColor:'#c7ebff',
                                                borderColor:'#008DDC'
                                            }}}
                                        onClick={openModal}
                                    >
                                        {item.macLabel}
                                    </Button>
                                    {modalOpen && <>
                                        {sizeType === 'mobile' ? (
                                            <>
                                                <Modals open={modalOpen} close={closeModal} header="기기 등록"
                                                        sizeType="mobile" position={changeData} updateCheck="select"
                                                        item={item} setPosition={setChangeData} modalNum={modalNum}
                                                        changeCompanyData={companyData}/>
                                            </>
                                        ):(
                                            <>
                                                <Modals open={modalOpen} close={closeModal}
                                                        position={changeData} updateCheck="select"
                                                        item={item} setPosition={setChangeData}
                                                        modalNum={modalNum} header="기기 등록"
                                                        changeCompanyData={companyData} />
                                            </>
                                        )}
                                    </>}

                                </>

                            )}

                        </div>
                    ))}

                    <Button
                        onClick={onSubmitHandler}
                        variant="contained"
                        type="submit"
                        size="medium"
                        fullWidth
                        sx={{
                            marginTop: 2,
                            width:"90%",
                            height: '45px',
                            border: 3,
                            "&.MuiButton-root:hover": {
                                color: '#008DDC',
                                backgroundColor: '#c7ebff',
                                borderColor: '#008DDC'
                            }
                        }}

                    >
                        부서 수정
                    </Button>

                </div>

            </div>
        </>
    )
}