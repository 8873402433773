import {Button} from "@mui/material";
import React, {useEffect} from "react";
import Store from "../../../../../../../context/store";
import {adminService, delUser, logout} from "../../../../../../../api";


export default function Delete(props){

    const {selectedData} =props

    const loginData = Store().getLoginData()

    const onDelService = (e)=>{
        console.log(selectedData)
        if(window.confirm(`${selectedData.map(e=>e.name)} 의 계정을 삭제 하시겠습니까?`)){
            let sendData = [];
            let adminCheck = false

            selectedData.map(el=>{
                if(el.admin === 'O'){
                    adminCheck = true
                }
                let pushData = {
                    userId:el.id,
                    phone:el.phone,
                    name:el.name
                }
                sendData.push(pushData)
            })
            if(adminCheck === true){
                alert('어드민 계정 삭제는 사이트 관리자에게 연락바랍니다.')
            }else{
                delUser(sendData)
                    .then(res=>{
                        alert(res.data)
                        window.location.replace('/adminPage')
                    })
                    .catch(err=>{
                        alert(err.response.data)
                    })
            }


        }else {
            return;
        }
    }


    return(
        <>
            <Button
                variant="contained"
                size="large"
                onClick={onDelService}
                sx={{marginRight:2}}
                disabled={selectedData.length < 1}
            >
                삭제(다중)
            </Button>
        </>
    )
}