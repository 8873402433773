
export default function Store(){

    return {
        setUserVacation(data){
            localStorage.setItem('userVacation',JSON.stringify(data))
        },
        getUserVacation(){
            return JSON.parse(localStorage.getItem('userVacation'))
        },
        setVacationState(data){
            localStorage.setItem('vacationState',JSON.stringify(data))
        },
        getVacationState(){
            return JSON.parse(localStorage.getItem('vacationState'))
        },
        setAttendanceState(data){
            localStorage.setItem('attendanceState',JSON.stringify(data))
        },
        getAttendanceState(){
            return JSON.parse(localStorage.getItem('attendanceState'))
        },
        setAttendanceData(data){
          localStorage.setItem('attendanceData',JSON.stringify(data))
        },
        getAttendanceData(){
          return JSON.parse(localStorage.getItem('attendanceData'))
        },
        setCompanyLogin(boolean){
            localStorage.setItem('companyLogin',boolean)
        },
        getCompanyLogin(){
            return localStorage.getItem('companyLogin') === 'true'
        },
        setCompanyData(data){
            localStorage.setItem('companyData',JSON.stringify(data))
        },
        getCompanyData(){
          return JSON.parse(localStorage.getItem('companyData'))
        },
        setInfoData(data){
            localStorage.setItem('infoData',JSON.stringify(data))
        },
        getInfoData(){
            return JSON.parse(localStorage.getItem('infoData'))
        },
        setFindData(data){
          localStorage.setItem('findData',JSON.stringify(data))
        },
        getFindData(){
          return JSON.parse(localStorage.getItem('findData'))
        },
        setLogin(boolean){
            localStorage.setItem('login',boolean)
        },
        getLogin(){
            return localStorage.getItem('login') === 'true'
        },
        setLoginData(data){
            localStorage.setItem('loginData',JSON.stringify(data))
        },
        getLoginData(){
            return JSON.parse(localStorage.getItem('loginData'))
        },
        getAgree(){
            return localStorage.getItem('Agree') === 'true'
        },
        setAgree(boolean){
            localStorage.setItem('Agree',boolean)
        },
        delItems(key){
            if(key === 'All'){
                localStorage.clear()
            }else{
                localStorage.removeItem(key)
            }
        }
    }
}