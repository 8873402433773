import React, {useState} from "react";
import {Button} from "@mui/material";
import Modal from "../../../../../modals/admin";


export default function DepartmentName(props){
    const {sizeType} = props

    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }


    return(
        <>
            <Button
                variant="contained"
                size="large"
                onClick={openModal}
                sx={{marginRight:2}}
            >
                부서명 변경
            </Button>
            {modalOpen && <>
                {sizeType === 'mobile' ? (
                    <>
                        <Modal open={modalOpen} close={closeModal} header="부서명 변경"
                               sizeType="mobile"  />
                    </>
                ):(
                    <>
                        <Modal open={modalOpen} close={closeModal} header="부서명 변경"  />
                    </>
                )}
            </>}
        </>
    )

}