import React, {useContext, useEffect, useState} from "react";
import Store from "../../context/store";
import moment from "moment-timezone";
import {addLoginTime, addLoginTimes, companyLogout, getAttendance, logout} from "../../api";
import {MainContext} from "../../context";
import {Box, Typography} from "@mui/material";
import './button.css'
import greenLogo from "../../picture/checkGreen.png";
import redLogo from "../../picture/checkRed.png";
import yellowLogo from "../../picture/yellowLogo.png";
import greenSpinner from "../../picture/greenSpinner.gif"
import redSpinner from "../../picture/spinner.gif"
import holidayLogo from "../../picture/holiday.png";
import absenceLogo from "../../picture/absence.png"



export default function CountNav(){

    const {clock,setClock,nowClock,setNowClock,nowDate,setNowDate,storeLogin } = useContext(MainContext)

    let loginData

    if(storeLogin === true){
        loginData = Store().getLoginData()
    }

    const [logo,setLogo] = useState('')
    const [label,setLabel] = useState('')




    const addLoginTime = (e)=>{
        e.preventDefault()
        let data ={
            company:loginData.company,
            userId:loginData.info.userId,
            name:loginData.info.name,
            phone:loginData.info.phone,
            admin:loginData.form.admin
        }
        addLoginTimes(data)
            .then(res=>{
                Store().setLoginData(res.data)
                let sendData = {
                    company:Store().getCompanyData().company,
                    userData:res.data
                }
                getAttendance(sendData)
                    .then(res1=>{
                        Store().setAttendanceData(res1.data)
                        let time = moment().tz('Asia/Seoul')
                        let wy = time.format('YYYY:MM:DD:dddd')
                        if(res1.data.state.end){

                        }
                    })
                    .catch(err=>{

                    })
                alert(`로그인 시간이 연장 되었습니다. (최대 1시간)`)
                window.location.reload()
            })

    }

    const logoutButton = (e)=>{
        e.preventDefault()
        logout()
            .then(res=>{
                alert('로그아웃 되었습니다.')
                Store().delItems('All')
                window.location.replace('/')
            })
            .catch(err=>{
                alert(JSON.stringify(err.response.data))
            })

    }

    useEffect(()=>{
        if(storeLogin === true){
            let data = {
                company:Store().getCompanyData().company,
                userData:Store().getLoginData()
            }

            let time = moment().tz('Asia/Seoul')
            let now = time.format('YYYY-MM-DD')
            let nowDays = time.format('dddd')

            getAttendance(data)
                .then(res=>{
                    Store().setAttendanceData(res.data)

                    if(now !== res.data.latestData.start.date){
                        if(nowDays === 'Saturday' || nowDays === 'Sunday'){
                            setLogo(holidayLogo)
                            setLabel('휴무')
                        }else{
                            setLabel('결근')
                            setLogo(absenceLogo)
                        }
                    }else{
                        if(res.data.state.start === 'normal'){
                            if (res.data.state.end === 'normal') {
                                setLogo(greenLogo)
                                setLabel('정상')
                            }
                            if (res.data.state.end === 'abNormal') {
                                setLogo(yellowLogo)
                                setLabel('비정상')
                            }
                            if (res.data.state.end === 'onDuty') {
                                setLogo(greenSpinner)
                                setLabel('출근 중...')
                            }
                        }else{
                            if (res.data.state.end === 'normal') {
                                setLogo(yellowLogo)
                                setLabel('비정상')
                            }
                            if (res.data.state.end === 'abNormal') {
                                setLogo(redLogo)
                                setLabel('비정상')
                            }
                            if (res.data.state.end === 'onDuty') {
                                setLogo(redSpinner)
                                setLabel('출근 중...')
                            }
                        }
                    }


                })
                .catch(err=>{

                })

            const timer = setInterval(()=>{
                getAttendance(data)
                    .then(res=>{
                        const basicData = Store().getAttendanceData()

                        if(basicData.log[0]._id !== res.data.log[0]._id){
                            if(res.data.state.start === 'normal'){
                                if (res.data.state.end === 'normal') {
                                    setLogo(greenLogo)
                                    setLabel('정상')
                                }
                                if (res.data.state.end === 'abNormal') {
                                    setLogo(yellowLogo)
                                    setLabel('비정상')
                                }
                                if (res.data.state.end === 'onDuty') {
                                    setLogo(greenSpinner)
                                    setLabel('출근 중...')
                                }
                            }else{
                                if (res.data.state.end === 'normal') {
                                    setLogo(yellowLogo)
                                    setLabel('비정상')
                                }
                                if (res.data.state.end === 'abNormal') {
                                    setLogo(redLogo)
                                    setLabel('비정상')
                                }
                                if (res.data.state.end === 'onDuty') {
                                    setLogo(redSpinner)
                                    setLabel('출근 중...')
                                }
                            }
                            Store().setAttendanceData(res.data)
                            window.location.reload()
                        }
                    })
                    .catch(err=>{

                    })
            },1000)
        }
    },[])


    useEffect(()=>{
       const timer = setInterval(()=>{

           const state = Store().getAttendanceState()
           if(state !== null){
               let times = moment().tz('Asia/Seoul')
               let now = times.format('YYYY-MM-DD')
               state.map(e=>{
                   if(e.date === now ){
                       setLabel(e.logoInfo.label.split(':')[0])
                       setLogo(e.logoInfo.logo)
                   }
               })
           }

       },1000)
    },[])


    useEffect(()=>{

        if(storeLogin === true){
            let dd = moment(loginData.expiresDate)
            const timer = setInterval(()=>{
                let now = new Date()
                let time = moment().tz('Asia/Seoul')
                let wy = time.format('YYYY:MM:DD:dddd')
                let ws = time.format('dddd')
                let days = ''


                switch (wy.split(':')[3]) {
                    case 'Sunday':
                        days = '(일) '
                        break;
                    case 'Monday':
                        days = '(월) '
                        break;
                    case 'Tuesday':
                        days = '(화) '
                        break;
                    case 'Wednesday':
                        days = '(수) '
                        break;
                    case 'Thursday':
                        days = '(목) '
                        break;
                    case 'Friday':
                        days = '(금) '
                        break;
                    case 'Saturday':
                        days = '(토) '
                        break;
                }

                const diff = dd - now

                const diffDay = Math.floor(diff / (1000 * 60 * 60 * 24))
                const diffHour = Math.floor(diff / (1000 * 60 * 60));
                const diffMin = Math.floor(diff / (1000 * 60));
                const diffSec = Math.floor(diff / 1000);

                const d = diffDay;
                const h = String(diffHour - diffDay * 24);
                const m = String(diffMin - diffHour * 60).padStart(2, "0");
                const s = String(diffSec - diffMin * 60 + 1).padStart(2, "0");

                let ch
                let cm
                let cs

                let nowHours = now.getHours()
                let nowMin = now.getMinutes()
                let nowSec = now.getSeconds()

                if (nowHours >= 13) {
                    ch = '오후 ' + String(nowHours - 12)
                } else {
                    ch = '오전 ' + String(nowHours)
                }

                if (now.getMinutes() <= 9) {
                    cm = '0' + nowMin
                } else {
                    cm = nowMin
                }

                if (now.getSeconds() <= 9) {
                    cs = '0' + nowSec
                } else {
                    cs = nowSec
                }

                setClock(m + "분" + s + "초")
                setNowDate(wy.split(':')[0] + '년 ' + wy.split(':')[1] + '월 ' + wy.split(':')[2] + '일 '+ days )
                setNowClock(ch + ':' + cm + ':' + cs)


                let nowTime = moment(time.format('YYYY-MM-DD kk:mm:ss'))
                let durationTime = moment.duration(dd.diff((nowTime))).asSeconds()
                if (durationTime <= 1) {
                    if (m === '00' && s === '01') {
                        logout()
                            .then(res => {
                                Store().setLogin(false)
                                Store().delItems('All')
                                window.location.replace('/')
                            })
                            .catch(err => {

                            })
                    }else{
                        logout()
                            .then(res => {
                                Store().setLogin(false)
                                Store().delItems('All')
                                window.location.replace('/')
                            })
                            .catch(err => {

                            })
                    }

                }
            })
        }



    },[])



    return(
        <>
            {storeLogin === false ? (
                <>
                </>
            ):(
                <>
                    { loginData.form.manager === true ? (
                        <>
                            웹매니져
                        </>
                    ):(
                        <>
                            { loginData.form.admin === true ? (
                                <>
                                    <div
                                        style={{
                                            backgroundColor:'whitesmoke',
                                            width:'100%',
                                        }}>
                                        <div style={{alignItems:'center',display:'flex'}}>
                                            <Typography component="h5" variant="h7" sx={{paddingLeft:"2%"}}>{nowDate} {nowClock}</Typography>
                                            {/*<Typography component="h5" variant="h7" sx={{paddingLeft:2, width:200}}></Typography>*/}
                                            <Typography component="h4" variant="h7" sx={{paddingLeft:"3%"}}>{loginData.company}</Typography>
                                            <Typography component="h5" variant="h7" sx={{paddingLeft:"1%"}}>{loginData.info.name} 님</Typography>
                                            <Typography component="h4" variant="h7" sx={{paddingLeft:"8%"}}>상태 : {label}</Typography>
                                            <img alt="No Images" src={logo} style={{paddingLeft:10,marginTop:1,width:'2%'}}/>
                                            <Typography component="h5" variant="h7" sx={{paddingLeft:"7%"}} >로그인 남은 시간:</Typography>
                                            <Typography component="h5" variant="h7" sx={{paddingLeft:2,width:80}}>{clock}</Typography>

                                            <button
                                                className="custom-btn btn-1"
                                                type="submit"
                                                onClick={addLoginTime}
                                                style={
                                                    {
                                                        marginLeft: "7%",
                                                        marginRight: "1%",
                                                        width: "12%",
                                                        height: '2%',
                                                        border: 3,

                                                    }
                                                }
                                            >
                                                <Typography variant='body2' color="white">
                                                    로그인 연장</Typography>
                                            </button>
                                            <button
                                                className="custom-btn btn-1"
                                                type="submit"
                                                name="company"
                                                onClick={logoutButton}
                                                style={
                                                    {
                                                        margin: "0.2%",
                                                        width: "12%",
                                                        height: '2%',
                                                        border: 3,
                                                    }
                                                }
                                            >
                                                <Typography variant='body2' color="white">
                                                    로그아웃</Typography>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ):(
                                <>
                                    {loginData === null ? (
                                        <>
                                        </>
                                    ):(
                                        <>
                                            <div
                                                style={{
                                                    backgroundColor:'whitesmoke',
                                                    width:'100%',

                                                }}>
                                                <div style={{alignItems:'center',display:'flex'}}>
                                                    <Typography component="h5" variant="h7" sx={{paddingLeft:"2%"}}>{nowDate}</Typography>
                                                    <Typography component="h5" variant="h7" sx={{paddingLeft:"1%"}}>{nowClock}</Typography>
                                                    <Typography component="h4" variant="h7" sx={{paddingLeft:"18%"}}>{loginData.company}</Typography>
                                                    <Typography component="h5" variant="h7" sx={{paddingLeft:"1%"}}>{loginData.info.name} 님</Typography>
                                                    <Typography component="h5" variant="h7" sx={{paddingLeft:"15%"}} >로그인 남은 시간:</Typography>
                                                    <Typography component="h5" variant="h7" sx={{paddingLeft:"0.5%"}}>{clock}</Typography>
                                                        <button
                                                            class="custom-btn btn-1"
                                                            type="submit"
                                                            onClick={addLoginTime}
                                                            style={
                                                                {
                                                                    marginLeft:"7%",
                                                                    marginRight:"1%",
                                                                    width: "9%",
                                                                    height:'2%',
                                                                    border:3,

                                                                }
                                                            }
                                                        >
                                                            <Typography variant='body2' color="white">
                                                                로그인 연장</Typography>
                                                        </button>
                                                        <button
                                                            class="custom-btn btn-1"
                                                            type="submit"
                                                            name="company"
                                                            onClick={logoutButton}
                                                            style={
                                                                {
                                                                    margin:"0.2%",
                                                                    width: "9%",
                                                                    height:'2%',
                                                                    border:3,
                                                                }
                                                            }
                                                        >
                                                            <Typography variant='body2' color="white">
                                                                로그아웃</Typography>
                                                        </button>

                                                </div>
                                            </div>
                                        </>
                                    )}

                                </>
                            )}
                        </>
                    )}
                </>
            )}

        </>
    )
}