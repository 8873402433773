
import {createContext, useState} from "react"
import Store from "./store";

export const MainContext = createContext({})



export const MainProvider = ({children}) =>{

    const storeLogin = Store().getLogin()
    const companyLogin = Store().getCompanyLogin()
    let [errorCount,setErrorCount] = useState(5)

    const [toggleMenu, setToggleMenu] = useState(false)
    const [toggleBar, setToggleBar] = useState(true)

    const [clock, setClock] = useState("00분00초");
    const [nowClock, setNowClock ] = useState('')
    const [nowDate,setNowDate] = useState('')


    const toggleChange = () => {
        setToggleMenu(!toggleMenu)
        setToggleBar(!toggleBar)
    }
    const toggleClose = (e)=>{
        e.stopPropagation()
        if(toggleMenu === true){
            setToggleMenu(false)
            setToggleBar(true)
        }
    }



    return(
        <MainContext.Provider value={{ companyLogin, storeLogin,errorCount,setErrorCount,
            toggleMenu,toggleBar,toggleChange,toggleClose,clock,setClock,nowClock,setNowClock,
            nowDate,setNowDate}}>
            {children}
        </ MainContext.Provider>
    )
}