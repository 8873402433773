import React, {useState} from "react";
import {Button} from "@mui/material";
import Modal from "../../../../modals/user";


export default function Update(props){

    const {selectedData, sizeType}=props

    const [modalOpen, setModalOpen] = useState(false);

    const openModal=()=>{
        setModalOpen(true);
    };
    const closeModal=()=>{
        setModalOpen(false);
    }


    return(
        <>
            <Button
                variant="contained"
                size="large"
                onClick={openModal}
                sx={{marginRight:2}}
                disabled={selectedData.length < 1 || selectedData.length >= 2}
            >
                정보수정(단일)
            </Button>
            {modalOpen &&
                <>
                    {sizeType === 'mobile' ? (
                        <>
                            <Modal open={modalOpen} close={closeModal} header="단일 정보수정" data={selectedData}
                                   sizeType="mobile" />
                        </>
                    ):(
                        <>
                            <Modal open={modalOpen} close={closeModal} header="단일 정보수정" data={selectedData} />
                        </>
                    )}
                </>
            }
        </>
    )
}